import { Grid, Paper } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/styles';
import { requestPostAPI } from 'common/services/AntsRequester';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(theme => ({
  label: {
    padding: theme.spacing(1),
    textAlign: 'center',
    backgroundColor: '#bdbdbd',
    color: '#ffffff',
    whiteSpace: 'nowrap',
    height: '32px',
    lineHeight: 1.8,
  },
  combo: {
    backgroundColor: '#fafafa',
    color: '#1f1f1f',
    whiteSpace: 'nowrap',
    height: '32px',
  },
}));

const AddrComboInput = props => {
  const classes = useStyles();
  const {
    labelClass,
    label,
    onChange,
    labelView,
    returnName,
    single,
    widthBreakpoints,
    addrValue,
    addrDtlValue,
  } = props;

  const isSingle = single === undefined ? false : single;

  const [provinceList, setProvinceList] = useState([]); // 지역 대분류 리스트
  const [provinceValue, setProvinceValue] = useState('-'); // 지역 대분류 선택한 값
  const [provinceName, setProvinceName] = useState(''); // 지역 대분류 선택한 이름

  const [districtList, setDistrictList] = useState([]); // 지역 중분류 리스트 - 대분류에 포함된 리스트
  const [districtValue, setDistrictValue] = useState('-'); // 지역 중분류 선택한 값
  // const [districtName, setDistrictName] = useState(''); // 지역 중분류 선택한 이름

  const [districtAllList, setDistrictAllList] = useState([]); // 지역 중분류 전체 리스트

  const isShowLabel = labelView !== undefined && labelView === 'hide' ? false : true;
  const isReturnName = returnName !== undefined ? returnName : false;

  const labelBp =
    widthBreakpoints && widthBreakpoints.split(',').length > 1 ? Number(widthBreakpoints.split(',')[0]) : 6;
  const selectBp =
    widthBreakpoints && widthBreakpoints.split(',').length > 1 ? Number(widthBreakpoints.split(',')[1]) : 6;

  useEffect(() => {
    if (isSingle) {
      initProvinceItems();
    } else {
      initProvinceAndDistrictItems();
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isSingle) {
      // change 중분류
      if (districtAllList !== undefined && districtAllList.length > 0) {
        const temp = districtAllList.filter(n => n.parentLocationCode === provinceValue);

        setDistrictList(temp);
        setDistrictValue('-');

        if (isReturnName) {
          if (isSingle) {
            onChange(provinceValue, provinceName);
          } else {
            onChange(provinceValue, provinceName, '-', '전체');
          }
        } else {
          if (isSingle) {
            onChange(provinceValue);
          } else {
            onChange(provinceValue, '-');
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [provinceValue]);

  const initProvinceAndDistrictItems = () => {
    requestPostAPI('getLocationItemList', { isSingle: 'N' })
      .then(response => {
        if (response && response.data) {
          const reData = response.data;
          if (reData.status && reData.status.result === 'success') {
            setProvinceList(reData.data[0].provinceList);
            setDistrictAllList(reData.data[0].districtList);

            /**
             * 수정한 부분 2020-07-12 yoo
             */

            // 데이터를 바로 적용해야 하는 경우
            if (addrValue !== null && addrValue !== undefined && addrDtlValue !== null && addrDtlValue !== undefined) {
              const tmpDistrictList = reData.data[0].districtList;
              // 대분류
              setProvinceValue(addrValue);

              if (tmpDistrictList !== undefined && tmpDistrictList.length > 0) {
                // 대분류에 따른 중분류 필터 처리
                const temp = reData.data[0].districtList.filter(n => n.parentLocationCode === addrValue);

                // 필터된 중분류 목록
                setDistrictList(temp);

                // 중분류
                setDistrictValue(addrDtlValue);
              }
            }
          } else {
            alert(reData.status.message);
          }
        }
      })
      .catch(error => {
        console.log('1 error : ', error);
      });
  };

  const initProvinceItems = () => {
    requestPostAPI('getLocationItemList', { isSingle: 'Y' })
      .then(response => {
        if (response && response.data) {
          const reData = response.data;
          if (reData.status && reData.status.result === 'success') {
            setProvinceList(reData.data[0].provinceList);
          } else {
            alert(reData.status.message);
          }
        }
      })
      .catch(error => {
        console.log('1 error : ', error);
      });
  };

  const handleChangeProvince = (e, childObj) => {
    setProvinceValue(childObj.props.value);
    setProvinceName(childObj.props.name);
  };

  const handleChangeDistrict = (e, childObj) => {
    setDistrictValue(childObj.props.value);
    // setDistrictName(childObj.props.name);
    if (isReturnName) {
      onChange(provinceValue, provinceName, childObj.props.value, childObj.props.name);
    } else {
      onChange(provinceValue, childObj.props.value);
    }
  };

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
      {isShowLabel && (
        <Grid item xs={6} sm={labelBp} lg={4}>
          <Paper className={labelClass ? labelClass : classes.label}>{label !== undefined ? label : '지역구분'}</Paper>
        </Grid>
      )}
      <Grid item xs={6} sm={selectBp} lg={8}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <Grid item xs={isSingle ? 12 : 6}>
            <Select
              fullWidth
              variant="outlined"
              className={classes.combo}
              value={provinceValue}
              onChange={handleChangeProvince}
            >
              <MenuItem key='-' value='-'>
                전체
              </MenuItem>
              {provinceList &&
                provinceList.map(item => {
                  return (
                    <MenuItem key={item.locationCode} value={item.locationCode} name={item.locationName}>
                      {item.locationName}
                    </MenuItem>
                  );
                })}
            </Select>
          </Grid>
          {!isSingle && (
            <Grid item xs={6}>
              <Select
                fullWidth
                variant="outlined"
                className={classes.combo}
                value={districtValue}
                onChange={handleChangeDistrict}
              >
                <MenuItem key='-' value='-'>
                  전체
                </MenuItem>
                {districtList &&
                  districtList.map(item => {
                    return (
                      <MenuItem key={item.locationCode} value={item.locationCode} name={item.locationName}>
                        {item.locationName}
                      </MenuItem>
                    );
                  })}
              </Select>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

AddrComboInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  returnName: PropTypes.bool,
};

export default AddrComboInput;
