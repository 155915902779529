import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Input, FormLabel } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  label: {
    fontSize: 'small',
    height: 'fit-content',
    textAlign: 'center',
    border: '1px solid #b7b371',
    paddingTop: 4,
    backgroundColor: '#eae595'
  },
  input: {
    borderBottom: '1px solid #b7b371',
    padding: 0,
    flexGrow: 1,
  }
}));

const LabelInput = props => {

  const classes = useStyles();
  const { labelStyle, id, onChange, label, value, disabled } = props;

  return (
    <div style={{display: 'flex', flexDirection: 'row', margin: 4}}>
      <FormLabel className={classes.label} component="label" style={labelStyle}>{label}</FormLabel>
      <Input
        id={id}
        className={classes.input}
        disabled={disabled}
        value={value}
        disableUnderline
        onChange={onChange}
        inputProps={{style: {
          padding: '1px 10px'
        },}}
      />
    </div>
  );
};

LabelInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object
};

export default LabelInput;
