import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';

import { logout } from 'common/services/AuthService';
import UserAuthContext from 'common/context/UserAuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOut, faBuilding, faUniversity, faHashtag, faUserSecret } from '@fortawesome/free-solid-svg-icons'

import { Grid, Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  flexGrow: {
    flexGrow: 1
  },
  companyInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    color: '#2c324d',
    fontSize: 14,
  },
  adminDesc: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    color: '#2c324d',
    fontSize: 18,
    fontWeight: 800,
    marginTop: 4
  },
  topBtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    color: '#2c324d',
    fontSize: 14,
  },
  topBarBtn: {
    marginRight: 20,
    textTransform: 'none',
    fontSize: 14,
    color: '#747474'
  },
  backImage: {
    backgroundColor: '#efefef',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
  }
}));

const Topbar = props => {

  const classes = useStyles();

  const { userAuthInfo } = useContext(UserAuthContext);

  const signOut = () => {
    logout();
    window.location.replace(process.env.REACT_APP_WEBCONTEXT_BASEURL);
  }

  return (
    <div className={classes.backImage}>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Grid item xs={6}>
          {userAuthInfo.agency !== null && userAuthInfo.role === 'ROLE_AGENCY' &&
            <div className={classes.companyInfo}>
              <FontAwesomeIcon icon={faUniversity} style={{ fontSize: 28, marginRight: 20, marginTop: 6 }} />
              <div style={{ fontWeight: 'bolder', fontSize: 18, lineHeight: '2.3em', marginRight: 26 }}>{userAuthInfo.agency.agencyNm}</div>
              {/* <FontAwesomeIcon icon={faHashtag} style={{ fontSize: 28, marginRight: 20, marginTop: 6 }} />
              <div style={{ fontWeight: 'bolder', fontSize: 18, lineHeight: '2.3em', marginRight: 12 }}>{userAuthInfo.agency.agencyCd}</div> */}
            </div>
          }
          {userAuthInfo.company !== null && userAuthInfo.role === 'ROLE_MANAGER' &&
            <div className={classes.companyInfo}>
              <FontAwesomeIcon icon={faBuilding} style={{ fontSize: 28, marginRight: 20, marginTop: 6 }} />
              <div style={{ fontWeight: 'bolder', fontSize: 18, lineHeight: '2.3em', marginRight: 26 }}>{userAuthInfo.company.companyNm}</div>
              {/* <FontAwesomeIcon icon={faHashtag} style={{ fontSize: 28, marginRight: 20, marginTop: 6 }} />
              <div style={{ fontWeight: 'bolder', fontSize: 18, lineHeight: '2.3em', marginRight: 12 }}>{userAuthInfo.company.companyCd}</div> */}
            </div>
          }
          {userAuthInfo.role === 'ROLE_ADMIN' &&
            <div className={classes.adminDesc}>
              <FontAwesomeIcon icon={faUserSecret} style={{ fontSize: 28, marginRight: 20, marginTop: 2 }} />
              <div style={{ fontWeight: 'bolder', fontSize: 18, lineHeight: '2.3em', marginRight: 26 }}>페이썸 서비스 관리 시스템</div>
            </div>
          }
        </Grid>
        <Grid item xs={6}>
          <div className={classes.topBtn}>
            <Button onClick={signOut} className={classes.topBarBtn} endIcon={<FontAwesomeIcon icon={faSignOut} style={{ fontSize: 26 }} />}>
              Log Out
            </Button>
          </div>
        </Grid>
      </Grid>
      <hr style={{ marginTop: 17, marginBottom: 4, borderTop: '1px solid #747474' }}></hr>
    </div>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarToggle: PropTypes.func
};

export default Topbar;
