import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';

import Input from '@material-ui/core/Input';

import useInform from 'common/hooks/useInform';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/Delete';

// Style Class
const useStyles = makeStyles(theme => ({
  cellNo: {
    backgroundColor: '#efefef',
    border: '1px solid #e3e3e3',
    textAlign: 'center',
    padding: '2px'
  },
  cellData: {
    padding: '2px',
    textAlign: 'center',
    border: '1px solid #e3e3e3'
  },
  editCellData: {
    padding: '2px',
    border: '1px solid #e3e3e3',
    backgroundColor: '#f5de6f'
  },
  cellValue: {
    border: 0,
    disableUnderline: 'false',
    textAlign: 'center',
    width: '100%'
  },
  editCellValue: {
    border: 0,
    disableUnderline: 'false',
    backgroundColor: '#f5de6f'
  },
  cusBtn: {
    backgroundColor: '#3c63a8',
    color: '#ffffff',
    padding: 2,
    fontWeight: 600
  }
}));

const GlobalVarsEditForm = (props) => {
  const { onIsEditing, isNew, globalVars, onGVarsChange } = props;
  const classes = useStyles();

  const [editScObj, setEditScObj] = useState({});

  const { showInform } = useInform();
  const [scGVars, setScGVars] = useState([]);

  useEffect(() => {

    setScGVars(globalVars);

  }, [globalVars]);

  const handleClickAddNewScGVar = () => {
    const newScGVars = [...scGVars];
    newScGVars.push({varNm:'', defaultVal:'', memo:''});
    setScGVars(newScGVars);
  }

  const handleClickDeleteScGVar = (index) => {
    const msg = [`${(index + 1)} 번째 변수를 삭제하시겠습니까?`];
    showInform('변수 삭제', msg, 'CONFIRM', result => {
      if (result === 'Y') {
        // 삭제
        const newScGVars = [...scGVars];
        newScGVars.splice(index, 1);
        setScGVars(newScGVars);
      } else {
        return;
      }
    });
  }

  const handleChangeScGVar = (e, i, no) => {
    const newScGVars = [...scGVars];
    switch (no) {
      case 0:
        newScGVars[i].varNm = e.target.value;
        break;
      case 1:
        newScGVars[i].memo = e.target.value;
        break;
      case 2:
        newScGVars[i].defaultVal = e.target.value;
        break;
      default:
        break;
    }
    setScGVars(newScGVars);
    onGVarsChange(newScGVars);
  }

  // console.log('DRAW SCOBJ :: ', editScContent);
  // console.log('DRAW editScObj :: ', editScObj);
  // console.log('editScContent :: ', editScContent);
  // console.log('scGVars :: ', scGVars);

  return (
    <div style={{ width: '100%', padding: 10 }}>
      <div style={{ display: 'flex', flexDirection: 'row-reverse', marginBottom: 10 }}>
        <button className={classes.cusBtn} onClick={handleClickAddNewScGVar} >신규 등록</button>
      </div>
      <div style={{ width: '100%', maxHeight: 130, overflow: 'scroll' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center', fontSzie: 10, width: '100px' }}>변수명</TableCell>
              <TableCell style={{ textAlign: 'center', fontSzie: 10, width: '100px' }}>디폴트값</TableCell>
              <TableCell style={{ textAlign: 'center', fontSzie: 10, width: '100px' }}>변수설명</TableCell>
              <TableCell style={{ textAlign: 'center', fontSzie: 10, width: '100px' }}>삭제</TableCell>
            </TableRow>
          </TableHead>
          {(scGVars && scGVars.length > 0) && (
            <TableBody>
              {/* 테이블 행그리는 loop */}
              {scGVars.map((row, index) => (
                <TableRow hover key={index} className={classes.normalRow} >
                  <TableCell align={"center"}>
                    <Input value={row.varNm} className={classes.cellValue}
                      onChange={(e) => { handleChangeScGVar(e, index, 0); }} />
                  </TableCell>
                  <TableCell align={"center"}>
                    <Input value={row.defaultVal} className={classes.cellValue}
                      onChange={(e) => { handleChangeScGVar(e, index, 2); }} />
                  </TableCell>
                  <TableCell align={"center"}>
                    <Input value={row.memo} className={classes.cellValue}
                      onChange={(e) => { handleChangeScGVar(e, index, 1); }} />
                  </TableCell>
                  <TableCell align={"center"}>
                    <Tooltip title="삭제">
                      <IconButton style={{ padding: 1, marginLeft: 20 }} onClick={() => { handleClickDeleteScGVar(index) }}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </div>
    </div>
  );
};

export default GlobalVarsEditForm;
