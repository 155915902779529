export function authHeader(extend) {
  const user = JSON.parse(sessionStorage.getItem('user'));
  if (user && user.accessToken) {
    if(extend !== undefined && typeof extend === 'object') {
      extend['Authorization'] = 'Bearer ' + user.accessToken;

    }
  }
  return extend;
}

export function authTempHeader(extend) {
  const user = JSON.parse(sessionStorage.getItem('temp_user'));
  if (user && user.accessToken) {
    if(extend !== undefined && typeof extend === 'object') {
      extend['Authorization'] = 'Bearer ' + user.accessToken;

    }
  }
  return extend;
}
