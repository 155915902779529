import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { SearchInput } from 'components/input';
import AntRoundButton from 'components/button/AntRoundButton';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 10px 0px 10px',
    minWidth: 935,
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const CompanyToolbar = props => {

  const { className, grades, depts, onClickButton, onChangeParam, ...rest } = props;
  const classes = useStyles();

  const handleChangeKeyword = (e) => {
    onChangeParam({'name': 'keyword', 'value': e.target.value});
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid container spacing={2}>
        <Grid item md={4} style={{ display: 'flex' }}>
        <SearchInput className={classes.searchInput}
            style={{ maxWidth: 280 }}
            placeholder="Search"
            onChange={handleChangeKeyword}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                onChangeParam({name: 'keywordEnter', value: event.target.value});
              }
            }}
          />
          <AntRoundButton id="company-toolbar-search" variant="contained" onClick={onClickButton} style={{ fontWeight: 'bold', height: 30 }}>
            <SearchIcon style={{ width: 20, height: 20, marginRight: 5 }} />
            검색
          </AntRoundButton>
        </Grid>
        <Grid item md={4} style={{ textAlign: 'right' }}>
          <AntRoundButton id="company-toolbar-regist" variant="contained" onClick={onClickButton} style={{ fontWeight: 'bold', height: 30 }}>
            등록
          </AntRoundButton>
        </Grid>
      </Grid>
    </div>
  );
};

CompanyToolbar.propTypes = {
  className: PropTypes.string
};

export default CompanyToolbar;
