import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { MenuHeader } from 'layouts/main/blocks/menu';
import { EmpToolbar, EmpList, EmpDetail } from '.';
import { EmpInputForm } from '.';

import { AntDialog } from 'components/dialog';
import { requestPostWithJsonAPI } from 'common/services/AntsRequester';
import { createParamObject } from 'common/utils/DataUtils';
import UserAuthContext from 'common/context/UserAuthContext';

import useAlert from 'common/hooks/useAlert';
import { setEmpGradesWithFunc, setDeptsWithFunc, setCompanyBankWithFunc } from 'views/employee/EmpUtils';

// Style Class
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#efefef',
  },
}));
// Row Count
const rowCount = 15;
const empVo = {
  companyCd: '',
  empId: '',
  empNo: '',
  empNm: '',
  gradeCd: '',
  deptCd: '',
  email: '',
  addrStr: '',
  enterDt: (new Date().toISOString().split('T')[0]).toString(),
  retireDt: (new Date().toISOString().split('T')[0]).toString(),
  retireYn: 'N',
  phone: '',
  residentNo: '',
  yeonkumNo: '',
  gungangNo: '',
  goyongNo: '',
  sanjaeNo: '',
  bankCd: 'B020',
  accountNo: '',
};

const EmpMain = props => {
  const classes = useStyles();
  const { showAlert } = useAlert();
  const { userAuthInfo } = useContext(UserAuthContext);

  const [tableData, setTableData] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [totalCnt, setTotalCnt] = useState(0);

  // option
  const [keyword, setKeyword] = useState('');
  const [selectedGradeCd, setSelectedGradeCd] = useState('-');
  const [selectedDeptCd, setSelectedDeptCd] = useState('-');
  // option datas
  const [grades, setGrades] = useState([]);
  const [depts, setDepts] = useState([]);
  const [banks, setBanks] = useState([]);
  // regist dialog
  const [empRegistOpen, setEmpRegistOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  // detail
  const [empDetailData, setEmpDetailData] = useState(undefined);
  const handleClickListRow = (data) => {
    setEmpDetailData(data);
  };
  // regist/modify input
  const [empInputData, setEmpInputData] = useState({
  });

  // select list
  const handleClickSearch = (pageNo, grade, dept) => {
    setEmpDetailData(undefined);
    requestPostWithJsonAPI('getEmpList', {
      page: pageNo,
      keyword: keyword,
      grade_cd: (grade) ? grade : selectedGradeCd,
      dept_cd: (dept) ? dept : selectedDeptCd,
      page_row_num: rowCount,
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          setCurPage(pageNo);
          setTableData({ data: response.data.listData });
          setTotalCnt(Number(response.data.listTotal));
        }
      }
    });
  };

  useEffect(() => {
    setEmpGradesWithFunc(setGrades, {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
    });
    setDeptsWithFunc(setDepts, {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
    });
    setCompanyBankWithFunc(setBanks, {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
    });

    handleClickSearch(1);
  }, [userAuthInfo]);

  // 직원 생성
  const handleClickCreateEmp = () => {
    Object.assign(empInputData, {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
    });
    // console.log('empInputData :::: ', empInputData);
    requestPostWithJsonAPI('setEmpCreate', createParamObject(empInputData)).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setEmpRegistOpen(false);
          showAlert('empdatacreate', `'${empInputData.empNm}' 직원가 생성되었습니다.`, 'success', 3000);
        } else {
          // 에러 처리 - 필요시
          // if (response.data.status.resultDept === 'duplicate') {
          //   showAlert('codeduplicate', `코드값이 중복되었습니다.`, 'error', 5000);
          // }
        }
      }
    });
  }

  // 직원 삭제 - API 호출
  const handleClickDeleteEmp = (param) => {
    requestPostWithJsonAPI('setEmpDelete', {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
      emp_id: empDetailData.empId,
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          showAlert('empdelete', `'${empDetailData.empNm}' 직원정보가 삭제되었습니다.`, 'success', 3000);
        } else {
          showAlert('empdeleteerror', '직원정보 삭제 작업이 실패 하였습니다.', 'error', 5000);
        }
      }
    });
  }

  // 직원 수정 - API 호출
  const handleClickEditEmp = (param) => {
    Object.assign(empInputData, {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
      retireDt: (empInputData.retireYn === 'N') ? '' : empInputData.retireDt,
    });
    // console.log('edit empInputData ::: ', empInputData);
    requestPostWithJsonAPI('setEmpEdit', createParamObject(empInputData)).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setEmpRegistOpen(false);
          showAlert('empdataedit', `'${empInputData.empNm}' 직원정보가 수정되었습니다.`, 'success', 3000);
        } else {
          showAlert('empediterror', '직원정보 변경 작업이 실패 하였습니다.', 'error', 5000);
        }
      }
    });
  }

  // 툴바에서 파라미터 변경 이벤트 처리
  const handleChangeToolbarOption = (data) => {
    if (data.name === 'keyword') {
      setKeyword(data.value);
      if (data.key === 'Enter') {
        handleClickSearch(1);
      }
    } else if (data.name === 'selectEmpGrade') {
      setSelectedGradeCd(data.value);
      handleClickSearch(1, data.value, undefined);
    } else if (data.name === 'selectEmpDept') {
      setSelectedDeptCd(data.value);
      handleClickSearch(1, undefined, data.value);
    }
  }
  const handleClickToolbarButton = (e) => {
    if (e.currentTarget.id === 'emp-toolbar-search') {
      handleClickSearch(1);
    } else if (e.currentTarget.id === 'emp-toolbar-regist') {
      setIsNew(true);
      setEmpInputData(empVo);
      setEmpRegistOpen(true);
    }
  }

  // 수정/등록에서의 입력 컴포넌트 이벤트 처리
  const handleFormChange = (data) => {
    setEmpInputData(data);
  }

  return (
    <div className={classes.root}>
      <MenuHeader />

      <EmpToolbar grades={grades} depts={depts}
        onChangeParam={handleChangeToolbarOption}
        onClickButton={handleClickToolbarButton} />

      <EmpList
        tableData={tableData}
        totalCnt={totalCnt}
        curPage={curPage}
        rowCount={rowCount}
        onChangePage={handleClickSearch}
        onClickListRow={handleClickListRow}
        selectedRow={empDetailData} />

      <EmpDetail empData={empDetailData}
        onClickModifyEmp={() => {
          setIsNew(false);
          const cloneEmpDetailData = JSON.parse(JSON.stringify(empDetailData));
          if(cloneEmpDetailData.retireYn === 'N') {
            Object.assign(cloneEmpDetailData, {
              retireDt: (new Date().toISOString().split('T')[0]).toString(),
            });
          }
          setEmpInputData(cloneEmpDetailData);
          setEmpRegistOpen(true);
        }}
        onClickDeleteEmp={handleClickDeleteEmp} />

      <AntDialog open={empRegistOpen}
        title={isNew ? '직원정보 등록' : '직원정보 수정'}
        comment={isNew ? '새로운 직원정보를 등록합니다.' : '직원정보를 수정합니다.'}
        onClose={() => { setEmpRegistOpen(false); }}
        draggable={true}
        actions={[
          { key: 1, label: '취소', callback: () => { setEmpRegistOpen(false); } },
          { key: 2, label: '저장', callback: isNew ? handleClickCreateEmp : handleClickEditEmp }
        ]}
        content={<EmpInputForm onFormChange={handleFormChange}
          grades={grades} depts={depts} banks={banks} inputData={empInputData} isNew={isNew}
        />}
      />
    </div>
  );
};

export default EmpMain;
