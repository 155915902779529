import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Grid, TextField } from '@material-ui/core';

import { LabelCard } from 'components/card';
import { InputCombo } from 'components/input';

const useStyles = makeStyles(theme => ({
  content: {
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    borderRadius: 0,
    padding: 10,
  }
}));

const CodeInputForm = (props) => {
  const { onFormChange, groupOptionData, codeData } = props;
  const classes = useStyles();

  // 프로젝트 개발 타입
  const [selectedCodeGroup, setSelectedCodeGroup] = React.useState('');
  return (
    <div className={classes.content} >
      <Grid container spacing={6}>
        <Grid item xs={6} style={{ paddingBottom: 0 }}>
          {!codeData &&
            <InputCombo
              label="코드그룹"
              items={groupOptionData.slice(1).map((e) => ({
                value: e.code, name: e.name
              }))}
              value={selectedCodeGroup}
              onChange={(e) => {
                setSelectedCodeGroup(e.target.value);
                onFormChange({ 'name': 'grpCd', 'value': e.target.value });
              }}
            />}
          {codeData &&
            <LabelCard label="그룹아이디" labelWidth={120} value={codeData.grpCd} />
          }
        </Grid>
        <Grid item xs={6} style={{ paddingBottom: 0 }}>{codeData &&
          <LabelCard label="그룹이름" labelWidth={120} value={codeData.grpNm} />
        }</Grid>
        <Grid item xs={6}>
          {!codeData &&
            <TextField label="코드아이디" fullWidth
              InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
              onChange={(e) => {
                onFormChange({ 'name': 'dtlCd', 'value': e.target.value });
              }}
            />
          }
          {codeData &&
            <LabelCard label="코드아이디" labelWidth={120} value={codeData.dtlCd} />
          }
        </Grid>
        {codeData && <Grid item xs={6}></Grid>}
        <Grid item xs={6}>
          {!codeData &&
            <TextField label="코드이름" fullWidth
              InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
              onChange={(e) => {
                onFormChange({ 'name': 'dtlNm', 'value': e.target.value });
              }}
            />
          }
          {codeData &&
            <TextField label="코드이름" fullWidth
              value={codeData.dtlNm}
              InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
              onChange={(e) => {
                onFormChange({ 'name': 'dtlNm', 'value': e.target.value });
              }}
            />
          }
        </Grid>
        <Grid item xs={6}>
          {!codeData &&
            <TextField label="정렬순서" fullWidth
              InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
              onChange={(e) => {
                onFormChange({ 'name': 'cdOrd', 'value': e.target.value });
              }}
            />
          }
          {codeData &&
            <TextField label="정렬순서" fullWidth
              value={codeData.cdOrd}
              InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
              onChange={(e) => {
                onFormChange({ 'name': 'cdOrd', 'value': e.target.value });
              }}
            />
          }
        </Grid>
      </Grid>
    </div>
  );
};

export default CodeInputForm;
