import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { SearchInput, ToolbarCombo } from 'components/input';
import AntRoundButton from 'components/button/AntRoundButton';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const CodeToolbar = props => {

  const { className, onClickButton, onChangeParam, groupOptionData, ...rest } = props;
  const classes = useStyles();

  const [selectedGroupCode, setSelectedGroupCode] = useState('-');

  const handleChangeKeyword = (e) => {
    onChangeParam({ 'name': 'keyword', 'value': e.target.value, 'key': e.key });
  }
  const handleChangeGroup = (e) => {
    setSelectedGroupCode(e.target.value);
    onChangeParam({ 'name': 'selectGroupCode', 'value': e.target.value });
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <SearchInput id='keyword-input'
            className={classes.searchInput}
            style={{ maxWidth: 280 }}
            placeholder="Search"
            onChange={handleChangeKeyword}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                handleChangeKeyword(event);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ToolbarCombo style={{ maxWidth: 280 }}
            id='projet-search-param1'
            label="코드그룹"
            value={selectedGroupCode}
            items={groupOptionData.map((e) => ({
              value: e.code, name: e.name
            }))}
            onChange={handleChangeGroup}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AntRoundButton id="code-toolbar-search" variant="contained" onClick={onClickButton} style={{ fontWeight: 'bold', height: 30 }}>
            <SearchIcon style={{ width: 20, height: 20, marginRight: 5 }} />
            검색
          </AntRoundButton>
          <AntRoundButton id="code-toolbar-regist" variant="contained" onClick={onClickButton} style={{ fontWeight: 'bold', height: 30 }}>
            등록
          </AntRoundButton>
        </Grid>
        <Grid item xs={12} sm={6} md={3} >
          <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: 10 }} >
            <AntRoundButton id="code-toolbar-groupmng" variant="contained" onClick={onClickButton} style={{ fontWeight: 'bold', height: 30 }}>
              코드그룹 관리
            </AntRoundButton>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

CodeToolbar.propTypes = {
  className: PropTypes.string,
  onClickButton: PropTypes.func,
  onChangeParam: PropTypes.func,
  groupOptionData: PropTypes.array,
};

export default CodeToolbar;
