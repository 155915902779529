import React from 'react';

import { Grid, TextField } from '@material-ui/core';

const ClientUserInputForm = (props) => {
  const { onFormChange, inputData } = props;
  const isEdit = inputData.isEdit; // (inputData && inputData.adminList && inputData.adminList.length > 0 && inputData.adminList[0].adminId !== '') ? true : false;
  // console.log('inputData --> ', inputData);
  // console.log('isEdit --> ', isEdit);
  return (
    <div style={{ width: '100%', paddingTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 30, }}>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <TextField label="아이디" fullWidth
            disabled={isEdit}
            value={inputData.adminList[0].adminId ? inputData.adminList[0].adminId : ''}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              const temp = { ...inputData.adminList[0], adminId: e.target.value };
              onFormChange({ ...inputData, 'adminList': [temp] });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField label="비밀번호" fullWidth
            value={inputData.adminList[0].password ? inputData.adminList[0].password : ''}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              const temp = { ...inputData.adminList[0], password: e.target.value };
              onFormChange({ ...inputData, 'adminList': [temp] });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField label="이름" fullWidth
            value={inputData.adminList[0].adminNm ? inputData.adminList[0].adminNm : ''}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              const temp = { ...inputData.adminList[0], adminNm: e.target.value };
              onFormChange({ ...inputData, 'adminList': [temp] });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField label="전화번호" fullWidth
            value={inputData.adminList[0].telNo ? inputData.adminList[0].telNo : ''}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              const temp = { ...inputData.adminList[0], telNo: e.target.value };
              onFormChange({ ...inputData, 'adminList': [temp] });
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField label="이메일" fullWidth
            value={inputData.adminList[0].email ? inputData.adminList[0].email : ''}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              const temp = { ...inputData.adminList[0], email: e.target.value };
              onFormChange({ ...inputData, 'adminList': [temp] });
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ClientUserInputForm;
