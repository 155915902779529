import React, { useContext } from 'react';
import axios from 'axios';
import { Switch, BrowserRouter, Redirect } from 'react-router-dom';

import { RouteWithLayout, RoutePrivateWithLayout } from './layouts';
import { Main as MainLayout, Minimal as MinimalLayout, Full as FullLayout } from './layouts';

import NotFound from './views/common/NotFound';

import AdminDashboard from './views/dashboard/AdminDashboard';
import AgencyDashboard from './views/dashboard/AgencyDashboard';
import ManagerDashboard from './views/dashboard/ManagerDashboard';

// -Employee------------------------------------------------------------------
import EmpMain from './views/employee/person/EmpMain';

// -Outsource------------------------------------------------------------------
import OutsMain from './views/outsource/person/OutsMain';
import DispMain from './views/outsource/dispatch/DispMain';

// -Pay------------------------------------------------------------------
import PayrollMain from './views/pay/payroll/PayrollMain';
import PayTaxMain from './views/pay/paytax/PayTaxMain';

// -Agency------------------------------------------------------------------
import AgencyMain from './views/agency/AgencyMain';
import ClientMain from './views/client/ClientMain';

import AdminMain from './views/user/admin/AdminMain';
import CodeMain from './views/system/code/CodeMain';
import CompanyMain from './views/company/CompanyMain';

import DeptMain from './views/system/dept/DeptMain';
import GradeMain from './views/system/grade/GradeMain';

import CrawMain from './views/system/agent/AgentMain';

import { SignIn as SignInView } from 'common/login';
import { InitPassword as InitPasswordView } from 'common/login';
import UserAuthContext from 'common/context/UserAuthContext';

import { logout } from 'common/services/AuthService';

import ConstructMain from './views/system/construct/ConstructMain';

const Routes = props => {
  const { userAuthInfo, setUserAuthInfo } = useContext(UserAuthContext);
  axios.interceptors.response.use(null, function (err) {
    if (err && err.response && err.response.status === 401) {
      logout();
      window.location.replace(process.env.REACT_APP_WEBCONTEXT_BASEURL);
    } else if (err && err.response && err.response.status === 403) {
      // 403은 혹시 다르게 표현이 필요할지 모름.
      logout();
      window.location.replace(process.env.REACT_APP_WEBCONTEXT_BASEURL);
    } else {

    }
    return Promise.reject(err);
  });

  let rootPath = '/login';
  if (userAuthInfo && userAuthInfo.role === 'ROLE_ADMIN') {
    rootPath = '/admindashboard';
  } else if (userAuthInfo && userAuthInfo.role === 'ROLE_AGENCY') {
    rootPath = '/agencydashboard';
  } else if (userAuthInfo && userAuthInfo.role === 'ROLE_MANAGER') {
    rootPath = '/managerdashboard';
  } else if (sessionStorage.getItem('user') !== null) {
    const data = JSON.parse(sessionStorage.getItem('user'));
    const role = (data.roles && data.roles.length > 0) ? data.roles[0] : '';
    if (role === 'ROLE_ADMIN') {
      rootPath = '/admindashboard';
    } else if (role === 'ROLE_AGENCY') {
      rootPath = '/agencydashboard';
    } else if (role === 'ROLE_MANAGER') {
      rootPath = '/managerdashboard';
    }
  }

  return (
    <BrowserRouter basename={process.env.REACT_APP_WEBCONTEXT_BASEURL}>
      <Switch>
        <Redirect exact from="/" to={rootPath} />

        {/* Common */}
        <RouteWithLayout component={SignInView} exact layout={FullLayout} path="/login" />

        <RoutePrivateWithLayout component={AdminDashboard} exact layout={MainLayout} path="/admindashboard" />
        <RoutePrivateWithLayout component={AgencyDashboard} exact layout={MainLayout} path="/agencydashboard" />
        <RoutePrivateWithLayout component={ManagerDashboard} exact layout={MainLayout} path="/managerdashboard" />

        <RouteWithLayout component={InitPasswordView} exact layout={FullLayout} path="/changepassword" />
        <RouteWithLayout component={NotFound} exact layout={MinimalLayout} path="/not-found" />

        {/* 직원 관리 */}
        <RoutePrivateWithLayout component={EmpMain} exact layout={MainLayout} path="/employeemain" />
        {/* 급여 관리 */}
        <RoutePrivateWithLayout component={PayrollMain} exact layout={MainLayout} path="/payrollmain" />
        <RoutePrivateWithLayout component={PayTaxMain} exact layout={MainLayout} path="/paytaxmain" />

        {/* 외주인력 관리 */}
        <RoutePrivateWithLayout component={OutsMain} exact layout={MainLayout} path="/outsourcemain" />
        <RoutePrivateWithLayout component={DispMain} exact layout={MainLayout} path="/dispatchmain" />

        {/* 세무사 관리 */}
        <RoutePrivateWithLayout component={AgencyMain} exact layout={MainLayout} path="/agencymng" />
        {/* 세무사-고객 관리 */}
        <RoutePrivateWithLayout component={ClientMain} exact layout={MainLayout} path="/agencyclient" />

        {/* 시스템 관리 - 슈퍼관리자 */}
        <RoutePrivateWithLayout component={AdminMain} exact layout={MainLayout} path="/adminmng" />
        <RoutePrivateWithLayout component={CodeMain} exact layout={MainLayout} path="/codemng" />
        <RoutePrivateWithLayout component={CompanyMain} exact layout={MainLayout} path="/companymng" />

        {/* 부서 관리 - 회사관리자 */}
        <RoutePrivateWithLayout component={DeptMain} exact layout={MainLayout} path="/deptconfig" />
        {/* 직급 관리 - 회사관리자 */}
        <RoutePrivateWithLayout component={GradeMain} exact layout={MainLayout} path="/gradeconfig" />

        {/* 크롤링 관리 */}
        <RoutePrivateWithLayout component={CrawMain} exact layout={MainLayout} path="/agentmng" />

        {/* 제작중 */}
        <RoutePrivateWithLayout component={ConstructMain} exact layout={MainLayout} path="/construct1" />
        <RoutePrivateWithLayout component={ConstructMain} exact layout={MainLayout} path="/construct2" />

        <Redirect to="/not-found" />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
