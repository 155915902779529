import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {

  antTable: {
    selectedRowBack: '#f2f2f2'
  },
  
  ain1color: {
    contrastText: white,
    dark: '#234168',
    main: '#3D5A80',
    light: '#7089AA',
  },
  ain2color: {
    contrastText: white,
    dark: '#6097B4',
    main: '#98C2D9',
    light: '#D8EFFC',
  },
  ain3color: {
    contrastText: white,
    dark: '#9BD1D3',
    main: '#E0FBFC',
    light: '#FFFFFF',
  },
  ian1color: {
    contrastText: white,
    dark: '#ECC30B',
    main: '#CEB235',
    light: '#DDCD87',
  },
  ian2color: {
    contrastText: white,
    dark: '#F37748',
    main: '#F37748',
    light: '#F37748',
  },
  ian3color: {
    contrastText: white,
    dark: '#D56062',
    main: '#D56062',
    light: '#D56062',
  },
  ian4color: {
    contrastText: white,
    dark: '#E39774',
    main: '#E39774',
    light: '#E39774',
  },

  black,
  white,
  primary: {
    contrastText: white,
    dark: colors.indigo[900],
    main: colors.indigo[500],
    light: colors.indigo[100]
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue['A400'],
    light: colors.blue['A400']
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  background: {
    default: '#ffffff',
    paper: white
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[300]
};
