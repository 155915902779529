import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { Chart } from "react-google-charts";
import ContentPaneTitleBar from 'components/container/ContentPaneTitleBar';

import { Typography, Grid } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import StopIcon from '@material-ui/icons/Stop';

const useStyles = makeStyles(theme => ({
    root: {
        padding: 0,
        boxShadow: 'none',
        border: '1px solid #d0d0d0',
        borderRadius: 0,
    },
    content: {
        boxShadow: 'none',
        backgroundColor: '#ffffff',
        borderRadius: 0,
        padding: 2,
        textAlign: '-webkit-center',
    },
    list: {
        padding: 0,
    },
    listItem: {
        borderTop: '1px solid #d2d2d2',
        padding: '0px 16px',

    }
}));

const AntPieChart = (props) => {
    const { className, title, chartSize, chartData, ...rest } = props;
    const classes = useStyles();
    const totalValue = (chartData !== undefined) ? chartData.slice(1).map((e) => (e[1])).reduce((a, b) => a + b, 0) : 0;

    // Flex 방식을 사용한 경우 - 향후에 필요할지 몰라 코드 남겨둠
    // const createListItemForFlex = (e) => {
    //     if (e !== undefined && e.length > 2) {
    //         return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    //             <StopIcon style={{ width: 32, color: e[2] }} />
    //             <Typography>{e[0]}</Typography>
    //             <Typography>{e[1]}</Typography>
    //             <Typography>{(totalValue > 0) ? ((e[1] / totalValue) * 100).toFixed(1) : '?'} %</Typography>
    //         </div>;
    //     } else {
    //         return <Typography>-</Typography>;
    //     }
    // }

    const createListItem = (e) => {
        if (e !== undefined && e.length > 2) {
            return <Grid container spacing={2} style={{ height: 22 }}>
                <Grid item xs={3} style={{ padding: 0 }}><StopIcon style={{ fontSize: 22, color: e[2] }} /></Grid>
                <Grid item xs={3} style={{ padding: 0 }}>{e[0]}</Grid>
                <Grid item xs={3} style={{ padding: 0 }}>{e[1]}</Grid>
                <Grid item xs={3} style={{ padding: 0 }}>{(totalValue > 0) ? ((e[1] / totalValue) * 100).toFixed(1) : '?'} %</Grid>
            </Grid>;
        } else {
            return <Typography>-</Typography>;
        }
    }

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
            style={{ minWidth: chartSize }}
        >
            <ContentPaneTitleBar title={title}
                buttons={[
                    {
                        name: '', action: 'refresh',
                        func: undefined,
                    },
                ]}
                style={{minWidth: 30}}
            />
            <div className={classes.content} >
                <Chart
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={chartData}
                    options={{
                        width: chartSize,
                        height: chartSize,
                        pieHole: 0.3,
                        backgroundColor: '#ffffff',
                        colors: ['#fe5d26', '#f2c078', '#faedca', '#c1dbb3', '#7ebc89', '#c94277', '#628b48'],
                        pieSliceText: 'label',
                        sliceVisibilityThreshold: 0,
                        pieSliceTextStyle: { color: '#000000' },
                        chartArea: { width: '90%', height: '90%' },
                        legend: 'none',
                        // legend: {
                        //   position: 'labeled'
                        // },
                    }}
                />
                <List className={classes.list}>
                    {(chartData !== undefined) ? chartData.slice(1).map((e) => {
                        return <ListItem key={e[0]} className={classes.listItem}>
                            <ListItemText primary={createListItem(e)} />
                        </ListItem>;
                    }) : undefined}
                </List>

            </div>
        </div>
    );
};

/* Array */
// ["fe5d26","f2c078","faedca","c1dbb3","7ebc89","c94277","628b48"]


export default AntPieChart;