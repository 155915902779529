import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { Grid, Divider } from '@material-ui/core';
import ContentPaneTitleBar from 'components/container/ContentPaneTitleBar';
import { LabelCard } from 'components/card';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    minWidth: 780,
  },
  main: {
    boxShadow: 'none',
    border: '1px solid #d0d0d0',
    borderRadius: 0,
  },
  content: {
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    borderRadius: 0,
    padding: 10,
  },
}));


const OutsDetail = (props) => {
  const { className, outsData, onClickModifyOuts, onClickDeleteOuts, ...rest } = props;
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {(outsData !== undefined) &&
        <div className={classes.main}>
          <ContentPaneTitleBar title='외주인력 상세정보'
            style={{ backgroundColor: '#777777' }}
            buttons={[{
              name: '수정', action: 'edit',
              func: onClickModifyOuts,
            }, {
              name: '삭제', action: 'delete',
              confirm: { title: '외주인력 삭제', content: '정말 삭제하시겠습니까?', func: onClickDeleteOuts },
            },]}
          />
          <div className={classes.content} >
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="외주인력 번호" labelWidth={120} value={outsData.outsNo} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="외주인력 이름" labelWidth={120} value={outsData.outsNm} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="직급" labelWidth={120} value={outsData.gradeStr} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="주민등록번호" labelWidth={120} value={outsData.residentNo} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="전화번호" labelWidth={120} value={outsData.phone} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="이메일" labelWidth={120} value={outsData.email} />
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: 0 }}>
                <LabelCard label="주소" labelWidth={120} value={outsData.addrStr} />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="등록일(작업자)" labelWidth={120} value={(outsData.regUserId && outsData.regUserId !== '') ? outsData.regDt + ' (' + outsData.regUserId + ')' : '-'} />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="수정일(작업자)" labelWidth={120} value={(outsData.modUserId && outsData.modUserId !== '') ? outsData.modDt + ' (' + outsData.modUserId + ')' : '-'} />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
            <Divider style={{ marginTop: 20, marginBottom: 20  }}></Divider>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="고용보험 원부번호" labelWidth={120} value={outsData.goyongNo} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="산재보험 번호" labelWidth={120} value={outsData.sanjaeNo} />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="급여통장은행" labelWidth={120} value={outsData.bankNm} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="급여통장 계좌번호" labelWidth={120} value={outsData.accountNo} />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </div>
        </div>
      }
    </div>
  );
};

export default OutsDetail;
