import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: '#3c63a8',
    borderRadius: '20px',
    border: 0,
    color: '#ffffff',
    height: 32,
    padding: '0px 15px',
    fontSize: '14px',
    boxShadow: '0 0px 0px 0px rgba(172, 172, 172, .62)',
    '&:hover': {
      backgroundColor: '#e2e2e2',
      boxShadow: 'none',
      padding: '0px 15px',
      color: '#282828'
    },
    '&:active': {
      boxShadow: '0 3px 5px 2px rgba(172, 172, 172, .62)',
    },
    '&$disabled': {
      color: '#ffffff',
      boxShadow: '0 3px 5px 2px rgba(172, 172, 172, .62)',
      backgroundColor: '#d7d8da'
    },
    '@media (max-width:767px)': {
      fontSize: '0.5rem',
    },
  }
}));

const AntRoundButton = props => {
  const classes = useStyles();
  const { className, onClick, style, disabled, children, ...rest } = props;
  return (
    <Button {...rest} className={clsx(classes.button, className)} style={style} onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  );
};

AntRoundButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object
};

export default AntRoundButton;
