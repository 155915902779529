
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { MenuHeader } from 'layouts/main/blocks/menu';
import UserAuthContext from 'common/context/UserAuthContext';

import DeptToolbar from './DeptToolbar';
import DeptList from './DeptList';
import DeptDetail from './DeptDetail';
import DeptInputForm from './DeptInputForm';

import { AntDialog } from 'components/dialog';
import { requestPostWithJsonAPI } from 'common/services/AntsRequester';
import { createParamObject } from 'common/utils/DataUtils';

import useAlert from 'common/hooks/useAlert';

// Style Class
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#efefef',
  },
}));
// Row Count
const rowCount = 15;
const deptVo = {
  companyCd: '',
  deptCd: '',
  deptNm: '',
  deptCmt: '',
  cdDepth: '',
  parentDeptCd: '',
  cdOrd: '',
};

const DeptMain = () => {
  const classes = useStyles();
  const { showAlert } = useAlert();
  const { userAuthInfo } = useContext(UserAuthContext);

  const [tableData, setTableData] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [totalCnt, setTotalCnt] = useState(0);

  // option
  const [keyword, setKeyword] = useState('');

  // regist dialog
  const [deptRegistOpen, setDeptRegistOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);

  // detail
  const [deptDetailData, setDeptDetailData] = useState(undefined);
  const handleClickListRow = (data) => {
    setDeptDetailData(data);
  };
  // regist/modify input
  const [deptInputData, setDeptInputData] = useState({
  });

  // select list
  const handleClickSearch = (pageNo) => {
    setDeptDetailData(undefined);
    requestPostWithJsonAPI('getDeptList', {
      page: pageNo,
      keyword: keyword,
      page_row_num: rowCount,
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          setCurPage(pageNo);
          setTableData({ data: response.data.listData });
          setTotalCnt(Number(response.data.listTotal));
        }
      }
    });
  };

  useEffect(() => {
    handleClickSearch(1);
  }, [userAuthInfo]);

  // 부서 생성 - API 호출
  const handleClickCreateDept = () => {
    Object.assign(deptInputData, {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
    });
    requestPostWithJsonAPI('setDeptCreate', createParamObject(deptInputData)).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setDeptRegistOpen(false);
          showAlert('deptcreate', `'${deptInputData.deptNm}' 부서정보가 생성되었습니다.`, 'success', 3000);
        } else {
          // 에러 처리 - 필요시
          // if (response.data.status.resultDept === 'duplicate') {
          //   showAlert('codeduplicate', `코드값이 중복되었습니다.`, 'error', 5000);
          // }
        }
      }
    });
  }

  // 부서 삭제 - API 호출
  const handleClickDeleteDept = () => {
    requestPostWithJsonAPI('setDeptDelete', {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
      dept_cd: deptDetailData.deptCd,
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          showAlert('deptdelete', `'${deptDetailData.deptNm}' 부서정보가 삭제되었습니다.`, 'success', 3000);
        } else {
          showAlert('deptdeleteerror', '부서정보 삭제 작업이 실패 하였습니다.', 'error', 5000);
        }
      }
    });
  }

  // 부서 수정 - API 호출
  const handleClickEditDept = () => {
    Object.assign(deptInputData, {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
    });
    requestPostWithJsonAPI('setDeptEdit', createParamObject(deptInputData)).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setDeptRegistOpen(false);
          showAlert('deptedit', '부서정보가 변경되었습니다.', 'success', 3000);
        } else {
          showAlert('deptediterror', '부서정보 변경 작업이 실패 하였습니다.', 'error', 5000);
        }
      }
    });
  }

  // 툴바에서 파라미터 변경 이벤트 처리
  const handleChangeToolbarOption = (data) => {
    if (data.name === 'keyword') {
      setKeyword(data.value);
      if (data.key === 'Enter') {
        handleClickSearch(1, undefined, data.value);
      }
    }
  }
  const handleClickToolbarButton = (e) => {
    if (e.currentTarget.id === 'dept-toolbar-search') {
      handleClickSearch(1);
    } else if (e.currentTarget.id === 'dept-toolbar-regist') {
      setIsNew(true);
      setDeptInputData(deptVo);
      setDeptRegistOpen(true);
    }
  }

  const handleFormChange = (data) => {
    setDeptInputData(data);
  }

  return (
    <div className={classes.root}>
      <MenuHeader />

      <DeptToolbar onChangeParam={handleChangeToolbarOption}
        onClickButton={handleClickToolbarButton} />

      <DeptList
        tableData={tableData}
        totalCnt={totalCnt}
        curPage={curPage}
        rowCount={rowCount}
        onChangePage={handleClickSearch}
        onClickListRow={handleClickListRow}
        selectedRow={deptDetailData} />

      <DeptDetail deptData={deptDetailData}
        onClickModifyDept={() => {
          setIsNew(false);
          setDeptInputData({
            ...deptDetailData,
            password: ''
          });
          setDeptRegistOpen(true);
        }}
        onClickDeleteDept={handleClickDeleteDept} />

      <AntDialog open={deptRegistOpen}
        title={isNew ? '부서정보 등록' : '부서정보 수정'}
        comment={isNew ? '새로운 부서 정보를 등록합니다.' : '부서 정보를 수정합니다.'}
        onClose={() => { setDeptRegistOpen(false); }}
        draggable={true}
        actions={[
          { key: 1, label: '취소', callback: () => { setDeptRegistOpen(false); } },
          { key: 2, label: '저장', callback: isNew ? handleClickCreateDept : handleClickEditDept }
        ]}
        content={<DeptInputForm onFormChange={handleFormChange}
          inputData={deptInputData} isNew={isNew}
        />}
      />
    </div>
  );
};

export default DeptMain;
