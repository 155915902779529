import React, { useState, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';

import validate from 'validate.js';

import theme from './theme';
import './assets/scss/index.scss';
import validators from './common/validate/validators';
import Routes from './Routes';

import InformProvider from './common/providers/inform';
import InformNotification from './components/inform/InformNotification';

import AlertProvider from './common/providers/alert';
import AlertNotification from './components/alert/AlertNotification';

import UserAuthContext from './common/context/UserAuthContext';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import ko from 'date-fns/locale/ko';

const browserHistory = createBrowserHistory();

validate.validators = {
  ...validate.validators,
  ...validators
};

const App = () => {
  const [userAuthInfo, setUserAuthInfo] = useState({});

  useEffect(() => {
    if (sessionStorage.getItem('user') !== null) {
      const data = JSON.parse(sessionStorage.getItem('user'));
      setUserAuthInfo({
        fileNo: data.fileNo,
        accessToken: data.accessToken,
        userId: data.userId,
        role: (data.roles && data.roles.length > 0) ? data.roles[0] : '',
        agency: data.agency,
        company: data.company,
      });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <InformProvider>
        <AlertProvider>
          <UserAuthContext.Provider value={{ userAuthInfo, setUserAuthInfo }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ko}>
              <Router history={browserHistory}>
                <Routes />
              </Router>
              <InformNotification />
              <AlertNotification />
            </MuiPickersUtilsProvider>
          </UserAuthContext.Provider>
        </AlertProvider>
      </InformProvider>
    </ThemeProvider>
  );
};

export default App;
