import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Crop75 from '@material-ui/icons/Crop75';

const useStyles = makeStyles(theme => ({
  menuHeader: {
    borderBottom: "2px solid #797979",
    marginTop: "10px",
    marginBottom: "10px"
  }
}));

const MenuInPage = (props) => {
  const classes = useStyles();
  const [menuName, setMenuName] = useState(props.menuName);
  useEffect(() => {
    setMenuName(props.menuName);
  },[props.menuName]);

  return (
      <Grid className={classes.menuHeader}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
            <Crop75 style={{ color: '#797979', paddingTop: '7px', marginBottom: '-3px', marginRight: '3px' }} />
            { menuName }
          </Typography>
        </Grid>
      </Grid>
  );
};

MenuInPage.propTypes = {
  menuName: PropTypes.string
};

export default MenuInPage;
