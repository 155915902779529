import React, { useState, useCallback } from 'react';

export const InformContext = React.createContext({
  inform: null,
  showInform: () => {},
  removeInform: () => {},
  cbFunc: null
});

export default function InformProvider({ children }) {
  const [inform, setInform] = useState(null);

  const removeInform = () => {
    setInform(null);
  };

  const showInform = (title, content, type, cbFunc) => {
    setInform({ title, content, type, cbFunc });
  };

  const contextValue = {
    inform,
    showInform: useCallback((title, content, type, cbFunc) => {
      showInform(title, content, type, cbFunc)
    }, []),
    removeInform: useCallback(() => {
      removeInform();
    }, [])
  };

  return (
    <InformContext.Provider value={contextValue}>
      {children}
    </InformContext.Provider>
  );
}
