import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 0,
    alignItems: 'center',
    display: 'flex',
  },
  label: {
    border: '1px solid #c7c7c7',
    textAlign: 'center',    
    whiteSpace: 'nowrap',
    height: 20,
    fontWeight: 500,
    lineHeight: 2,
    fontSize: 11
  },
  normalLabel: {
    backgroundColor: '#CECECE',
  },
  refLabel: {
    backgroundColor: theme.palette.ain2color.light,
  },
  value: {
    backgroundColor: '#ffffff',
    color: '#1f1f1f',
    whiteSpace: 'nowrap',
    paddingLeft: 10,
    fontSize: 14
  },
  underbar: {
    borderBottom: '1px solid #c7c7c7',
  }

}));

const LabelImageCard = props => {

  const classes = useStyles();
  const { isRef, label, labelWidth, value, noUnderbar, ...rest } = props;

  return (
    <Paper {...rest} className={(noUnderbar) ? classes.root : clsx(classes.root, classes.underbar)} elevation={0}>
      <div className={(isRef) ? clsx(classes.label, classes.refLabel) : clsx(classes.label, classes.normalLabel)} 
        style={{minWidth: labelWidth }}>{label}</div>
      <div className={(noUnderbar) ? classes.value : clsx(classes.value, classes.underbar)}>
        <img src={`http://localhost:8082/antsome/img?file_no=${value}`} alt="label" style={{border: '1px solid #dedede'}}></img>
      </div>
    </Paper>
  );
};

export default LabelImageCard;
