
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { MenuHeader } from 'layouts/main/blocks/menu';
import UserAuthContext from 'common/context/UserAuthContext';

import AgentToolbar from './AgentToolbar';
import AgentList from './AgentList';
import AgentDetail from './AgentDetail';
import AgentCrawEditForm from './AgentCrawEditForm';
import GlobalVarsEditForm from './GlobalVarsEditForm';

import { AntDialog } from 'components/dialog';
import { requestPostWithJsonAPI } from 'common/services/AntsRequester';
import { createParamObject } from 'common/utils/DataUtils';

import useAlert from 'common/hooks/useAlert';
import useInform from 'common/hooks/useInform';

// Style Class
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#efefef',
  },
}));
// Row Count
const rowCount = 15;
const agentVo = {
  companyCd: '',
  agentNo: '',
  agentNm: '',
  agentCmt: '',
  cdAgenth: '',
  parentAgentCd: '',
  cdOrd: '',
};

const AgentMain = () => {
  const classes = useStyles();
  const { showAlert } = useAlert();
  const { showInform } = useInform();
  const { userAuthInfo } = useContext(UserAuthContext);

  const [tableData, setTableData] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [totalCnt, setTotalCnt] = useState(0);

  // option
  const [keyword, setKeyword] = useState('');

  // regist dialog
  const [agentRegistOpen, setAgentRegistOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);

  // detail
  const [agentDetailData, setAgentDetailData] = useState(undefined);
  const handleClickListRow = (data) => {
    setAgentDetailData(data);
  };
  // regist/modify input
  const [agentInputData, setAgentInputData] = useState({
  });

  // edit script content
  const [editScript, setEditScript] = useState();
  const [editScriptOpen, setEditScriptOpen] = useState(false);

  const [editGVars, setEditGVars] = useState([]);

  const [scObj, setScObj] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  // 공용변수 관리 dialog
  const [globalVarsMngOpen, setGlobalVarsMngOpen] = useState(false);
  const handleGlobalVarsMngDialogClose = () => {
    setGlobalVarsMngOpen(false);
  };
  const handleGlobalVarsMngDialogOpen = () => {
    setGlobalVarsMngOpen(true);
  };

  // select list
  const handleClickSearch = (pageNo) => {
    setAgentDetailData(undefined);
    requestPostWithJsonAPI('getAgentList', {
      page: pageNo,
      keyword: keyword,
      page_row_num: rowCount,
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          setCurPage(pageNo);
          setTableData({ data: response.data.listData });
          setTotalCnt(Number(response.data.listTotal));

          // 개발용
          // console.log('response.data.listData >> ', response.data.listData);
          // if(response.data.listData && response.data.listData.length > 0) {
          //   if(response.data.listData[0].scriptList && response.data.listData[0].scriptList.length > 1) {
          //     setEditScript(response.data.listData[0].scriptList[1]);
          //     setEditScriptOpen(true);
          //   }
          // }
        }
      }
    });
  };

  useEffect(() => {
    handleClickSearch(1);
  }, [userAuthInfo]);

  const handleClickCreateScenario = () => {
    // console.log('SAVE editScript :: ', editScript);
    // console.log('SAVE agentDetailData :: ', agentDetailData);

    try {
      JSON.parse(editScript.content);
    } catch (err) {
      showAlert('agentscriptediterror', '시나리오가 JSON 포맷이 아닙니다.', 'error', 5000);
      return;
    }

    if (editScript) {
      if (editScript.scriptNm === null || editScript.scriptNm.trim() === '') {
        showAlert('agentscriptcreateerror', `시나리오 이름을 입력하세요.`, 'error', 5000);
      } else if (editScript.startUrl === null || editScript.startUrl.trim() === '') {
        showAlert('agentscriptcreateerror', `시작할 URL 정보를 입력하세요,`, 'error', 5000);
      } else {

        const msg = [`'${editScript.scriptNm}' 시나리오를 생성하시겠습니까?`];
        showInform('시나리오 생성', msg, 'CONFIRM', result => {
          if (result === 'Y') {

            requestPostWithJsonAPI('setScenarioCreate', createParamObject({
              'agent_no': agentDetailData.agentNo,
              'script_nm': editScript.scriptNm,
              'script_cmt': editScript.scriptCmt,
              'start_url': editScript.startUrl,
              'script_vars': (editScript && editScript.scriptVars) ? editScript.scriptVars : '',
              'content': editScript.content
            })).then((response) => {
              if (response && response.status && response.status === 200) {
                if (response.data && response.data.status && response.data.status.result === 'success') {
                  handleClickSearch(1);
                  setEditScriptOpen(false);
                  showAlert('agentscriptedit', '시나리오가 생성되었습니다.', 'success', 3000);
                } else {
                  showAlert('agentscriptediterror', '시나리오 생성 작업이 실패 하였습니다.', 'error', 5000);
                }
              }
            });
          } else {
            return;
          }
        });

      }
    } else {
      showAlert('agentscriptcreateerror', `생성할 데이터 정보가 없습니다.(이름, 설명, 시작웹주소 등등)`, 'error', 5000);
    }
  }

  // const handleSaveContent = (obj) => {
  //   setScObj(obj);
  // }

  const handleClickEditScenario = () => {
    // console.log(editScript.content);
    try {
      JSON.parse(editScript.content);
    } catch (err) {
      showAlert('agentscriptediterror', '시나리오가 JSON 포맷이 아닙니다.', 'error', 5000);
      return;
    }
    console.log('handleClickEditScenario - editScript ==== > ', editScript);

    requestPostWithJsonAPI('setScenarioEdit', createParamObject({
      'script_no': editScript.scriptNo,
      'script_nm': editScript.scriptNm,
      'script_cmt': editScript.scriptCmt,
      'start_url': editScript.startUrl,
      'script_vars': editScript.scriptVars,
      'content': editScript.content
    })).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setEditScriptOpen(false);
          showAlert('agentscriptedit', '시나리오가 수정되었습니다.', 'success', 3000);
        } else {
          showAlert('agentscriptediterror', '시나리오 수정 작업이 실패 하였습니다.', 'error', 5000);
        }
      }
    });
  }

  const handleClickDeleteScenario = (obj) => {
    const msg = [`'${obj.scriptNm}' 시나리오를 삭제하시겠습니까?`];
    showInform('시나리오 삭제', msg, 'CONFIRM', result => {
      if (result === 'Y') {
        requestPostWithJsonAPI('setScenarioDelete', createParamObject({
          'agent_no': agentDetailData.agentNo,
          'script_no': obj.scriptNo
        })).then((response) => {
          if (response && response.status && response.status === 200) {
            if (response.data && response.data.status && response.data.status.result === 'success') {
              handleClickSearch(1);
              setEditScriptOpen(false);
              showAlert('agentscriptedit', '시나리오가 삭제되었습니다.', 'success', 3000);
            } else {
              showAlert('agentscriptediterror', '시나리오 삭제 작업이 실패 하였습니다.', 'error', 5000);
            }
          }
        });
      } else {
        return;
      }
    });
  }

  // 툴바에서 파라미터 변경 이벤트 처리
  const handleChangeToolbarOption = (data) => {
    if (data.name === 'keyword') {
      setKeyword(data.value);
      if (data.key === 'Enter') {
        handleClickSearch(1, undefined, data.value);
      }
    }
  }
  const handleClickToolbarButton = (e) => {
    if (e.currentTarget.id === 'agent-toolbar-search') {
      handleClickSearch(1);
    } else if (e.currentTarget.id === 'agent-toolbar-regist') {
      setIsNew(true);
      setAgentInputData(agentVo);
      setAgentRegistOpen(true);
    }
  }

  const handleFormChange = (data) => {
    setAgentInputData(data);
  }

  const handleIsEditing = (b) => {
    setIsEditing(b);
  }

  // 공용변수 정보 생성/수정 다이얼로그 표시
  const handleClickMngGVar = () => {
    // 공용변수 정보 생성/수정 다이얼로그 표시
    setEditGVars((agentDetailData) ? agentDetailData.globalVars : []);

    // 공용변수 정보 생성/수정 다이얼로그 표시
    handleGlobalVarsMngDialogOpen();
  }

  const handleClickSaveGlobalVars = () => {
    requestPostWithJsonAPI('setGlobalVars', createParamObject({
      'agent_no': agentDetailData.agentNo,
      'global_vars': editGVars
    })).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          handleGlobalVarsMngDialogClose();
          showAlert('globalvarsedit', '공용변수가 수정되었습니다.', 'success', 3000);
        } else {
          showAlert('globalvarsediterror', '공용변수 수정 작업이 실패 하였습니다.', 'error', 5000);
        }
      }
    });
  }

  return (
    <div className={classes.root}>
      <MenuHeader />

      <AgentToolbar onChangeParam={handleChangeToolbarOption}
        onClickButton={handleClickToolbarButton} />

      <AgentList
        tableData={tableData}
        totalCnt={totalCnt}
        curPage={curPage}
        rowCount={rowCount}
        onChangePage={handleClickSearch}
        onClickListRow={handleClickListRow}
        selectedRow={agentDetailData} />

      <AgentDetail agentData={agentDetailData}
        onClickModifyAgent={() => {
          setIsNew(false);
          setAgentInputData({
            ...agentDetailData,
            password: ''
          });
          setAgentRegistOpen(true);
        }}
        onClickEditScObj={(obj) => {
          setIsNew(false);
          setEditScript(obj);
          setEditScriptOpen(true)
        }}
        onClickMngGVar={handleClickMngGVar}
        onClickDeleteScObj={(obj) => {
          handleClickDeleteScenario(obj);
        }}
        onClickCreateScObj={() => {
          setIsNew(true);
          setEditScript();
          setEditScriptOpen(true)
        }}
        onClickDeleteAgent={"handleClickDeleteAgent"} />

      <AntDialog open={editScriptOpen} maxWidth="xl"
        title={isNew ? '크롤링 시나리오 등록' : '크롤링 시나리오 수정'}
        comment={isNew ? '크롤링 시나리오를 등록합니다.' : '크롤링 시나리오를 수정합니다.'}
        onClose={() => { setIsEditing(false); setEditScriptOpen(false); }}
        draggable={true}
        actions={[
          { key: 1, label: '취소', callback: () => { setEditScriptOpen(false); } },
          { key: 2, label: '저장', callback: isNew ? handleClickCreateScenario : handleClickEditScenario }
        ]}
        btnDisabled={isEditing}
        content={<AgentCrawEditForm
          // onFormChange={handleFormChange}
          // inputData={agentInputData}
          onIsEditing={handleIsEditing}
          isNew={isNew}
          editScript={editScript}
          globalVars={(agentDetailData) ? agentDetailData.globalVars : []}
          onScriptChange={(obj) => {
            // console.log('MAIN obj -> ', obj);
            setEditScript(obj);
          }}
        />}
      />

      <AntDialog open={globalVarsMngOpen}
        title={'공용변수 등록/수정'}
        comment={'모든 시나리오에서 사용하는 변수를 설정합니다.'}
        onClose={handleGlobalVarsMngDialogClose}
        draggable={true}
        actions={[
          { key: 1, label: '취소', callback: () => { handleGlobalVarsMngDialogClose(false); } },
          { key: 2, label: '저장', callback: handleClickSaveGlobalVars }
        ]}
        btnDisabled={false}
        content={<GlobalVarsEditForm
          globalVars={(agentDetailData) ? agentDetailData.globalVars : []}
          onGVarsChange={(obj) => {
            // console.log('MAIN obj -> ', obj);
            setEditGVars(obj);
          }}
        />}
      />

    </div>
  );
};

export default AgentMain;
