import React, { useEffect, useRef, useState, useContext } from 'react';

import { Button, TextField, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import LockRoundedIcon from '@material-ui/icons/LockRounded';

import UserAuthContext from 'common/context/UserAuthContext';
import { login } from 'common/services/AuthService';

import { sha256 } from 'js-sha256';

import AntsomeLogo from 'assets/images/paysome-logo_black.png';
import AuthBg from 'assets/images/flower-5587380_1920.png';


const useStyles = makeStyles(theme => ({
  loginPane: {
    padding: theme.spacing(2),
    margin: 'auto',
  },
  paper: {
    paddingTop: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    margin: 'auto',
    maxWidth: '400px',
  },
  logoPane: {
    align: 'center',
    margin: '0px 0px',
  },
  logoStyle: {
    width: 220,
    marginBottom: 32,
  },
  msgPane: {
    align: 'center',
    color: 'red',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  btn: {
    marginBottom: 10,
    backgroundColor: '#3c63a8',
  },
  logintext: {
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  background: {
    backgroundColor: theme.palette.neutral,
    backgroundImage: `url(${AuthBg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    textAlign: 'center',
    height: '100%',
    display: 'flex',
  },
  typography: {
    lineHeight: '40px',
    fontSize: '13px',
    fontWeight: 'bold',
    color: 'grey',
  },
}));

export function SignIn(props) {
  const { history, location } = props;
  const classes = useStyles();

  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [isShowMsg, setIsShowMsg] = useState(false);
  const [isSaveUserId, setSaveUserId] = useState(false);
  let textInput = useRef(null);

  const { setUserAuthInfo } = useContext(UserAuthContext);

  useEffect(() => {
    const commonOption = JSON.parse(sessionStorage.getItem('common_option'));
    if (commonOption !== null) {
      if (commonOption.isSaveUserId) {
        setUserId(commonOption.savedUserId);
        setSaveUserId(true);
      }
    }
  }, []);

  const handleClickLoginBtn = e => {
    e.preventDefault();
    login(userId, sha256(password)).then(
      data => {
        if (data === undefined || data === 'ERROR') {
          setIsShowMsg(true);
        } else {
          setUserAuthInfo({
            fileNo: data.fileNo,
            accessToken: data.accessToken,
            userId: data.userId,
            role: (data.roles && data.roles.length > 0) ? data.roles[0] : '',
            agency: data.agency,
            company: data.company,
          });

          if (isSaveUserId) {
            sessionStorage.setItem('common_option', JSON.stringify({ isSaveUserId: true, savedUserId: userId }));
          } else {
            sessionStorage.setItem('common_option', JSON.stringify({ isSaveUserId: false, savedUserId: '' }));
          }

          if (data.isPwCh === 'Y') {
            history.push({ pathname: '/changepassword' });
          } else {
            const { from } = location.state || { from: { pathname: '/' } };
            history.push(from);
            // window.location.reload();
          }
        }
      },
      error => {
        // const resMessage =
        //   (error.response &&
        //     error.response.data &&
        //     error.response.data.message) ||
        //   error.message ||
        //   error.toString();
        // this.setState({
        //   loading: false,
        //   message: resMessage
        // });
      },
    );
  };

  const handleClickCustomLoginBtn = (userId, password) => {
    login(userId, sha256(password)).then(
      data => {
        if (data === undefined || data === 'ERROR') {
          setIsShowMsg(true);
        } else {
          setUserAuthInfo({
            fileNo: data.fileNo,
            accessToken: data.accessToken,
            userId: data.userId,
            role: (data.roles && data.roles.length > 0) ? data.roles[0] : '',
            agency: data.agency,
            company: data.company,
          });

          const { from } = location.state || { from: { pathname: '/' } };
          history.push(from);
        }
      },
      error => {
      },
    );
  };

  return (
    <div className={classes.background}>
      <div className={classes.loginPane}>
        <Paper className={classes.paper}>
          <form noValidate autoComplete="off">
            <div align="center" className={classes.logoPane}>
              <img
                alt="Logo"
                src={AntsomeLogo}
                className={classes.logoStyle}
              />
            </div>
            {isShowMsg && <div className={classes.msgPane}>아이디 또는 비밀번호가 잘못되었습니다.</div>}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <AccountCircleRoundedIcon style={{ color: '#3c63a8' }} fontSize="large" />
                  </Grid>
                  <Grid item xs={10} style={{ paddingRight: '24px' }}>
                    <TextField
                      value={userId}
                      fullWidth
                      autoComplete=""
                      variant="outlined"
                      placeholder="아이디"
                      size="small"
                      onChange={e => {
                        setUserId(e.target.value);
                      }}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          textInput.current.focus();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <LockRoundedIcon style={{ color: '#3c63a8' }} fontSize="large" />
                  </Grid>
                  <Grid item xs={10} style={{ paddingRight: '24px' }}>
                    <TextField
                      value={password}
                      type="password"
                      fullWidth
                      autoComplete=""
                      inputRef={textInput}
                      variant="outlined"
                      placeholder="비밀번호"
                      size="small"
                      onChange={e => {
                        setPassword(e.target.value);
                      }}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          handleClickLoginBtn(e);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={1}>
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={isSaveUserId}
                      style={{ padding: '9px 0px' }}
                      onChange={e => {
                        setSaveUserId(e.target.checked);
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className={classes.typography}>아이디 기억</Typography>
                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                      className={classes.btn}
                      onClick={handleClickLoginBtn}
                    >
                      <Typography className={classes.logintext}>로그인</Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={12}>-- 개발 작업시 사용하는 간단 로그인 버튼 --</Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Button style={{ textTransform: 'none', marginBottom: 10, backgroundColor: '#BD9672' }}
                          variant="contained" onClick={() => { handleClickCustomLoginBtn('admin', '1234'); }}
                        >시스템</Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button style={{ textTransform: 'none', marginBottom: 10, backgroundColor: '#68b168' }}
                          variant="contained" onClick={() => { handleClickCustomLoginBtn('agency', '1234'); }}
                        >세림노무사</Button><br />
                        <Button style={{ textTransform: 'none', marginBottom: 10, height: 60, backgroundColor: '#68b168' }}
                          variant="contained" onClick={() => { handleClickCustomLoginBtn('gaag', '1234'); }}
                        >세무법인<br/>이노택스(역삼)</Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button style={{ textTransform: 'none', marginBottom: 10, backgroundColor: '#6873b1' }}
                          variant="contained" onClick={() => { handleClickCustomLoginBtn('aaaa', '1234'); }}
                        >(주)새벽정밀</Button><br />
                        <Button style={{ textTransform: 'none', marginBottom: 10, backgroundColor: '#6873b1' }}
                          variant="contained" onClick={() => { handleClickCustomLoginBtn('miadmin', '1234'); }}
                        >미광유통
                        </Button><br />
                        <Button style={{ textTransform: 'none', marginBottom: 10, backgroundColor: '#6873b1' }}
                          variant="contained" onClick={() => { handleClickCustomLoginBtn('antmanager', '1234'); }}
                        >(주)앤트썸</Button><br />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Button style={{ textTransform: 'none', marginBottom: 10, backgroundColor: '#81BD72' }}
                      variant="contained" onClick={handleClickSiteLoginBtn}
                    >Site관리자 계정으로 자동 로그인 - mg02/1234</Button>
                  </Grid> */}
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 20 }}>
                  <Grid item xs={12}>@ powered by antsome's endeavor.</Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    </div>
  );
}
