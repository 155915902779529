import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { MenuHeader } from 'layouts/main/blocks/menu';
import { PayTaxToolbar, PayTaxList, PayTaxDetail } from '.';
import { PayTaxInputForm, PayTaxSheetInputForm } from '.';

import { AntDialog } from 'components/dialog';
import { requestPostWithJsonAPI, requestPostDownloadAPI } from 'common/services/AntsRequester';
import { createParamObject } from 'common/utils/DataUtils';
import UserAuthContext from 'common/context/UserAuthContext';

import useAlert from 'common/hooks/useAlert';
import useInform from 'common/hooks/useInform';

import { setClientsWithFunc } from 'views/client/ClientUtils';

// Style Class
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#efefef',
  },
}));
// Row Count
const rowCount = 15;

const PayTaxMain = props => {
  const classes = useStyles();
  const { showAlert } = useAlert();
  const { showInform } = useInform();
  const { userAuthInfo } = useContext(UserAuthContext);

  const [tableData, setTableData] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [totalCnt, setTotalCnt] = useState(0);

  // option
  const [keyword, setKeyword] = useState('');
  const [year, setYear] = useState('2022');
  const [month, setMonth] = useState('1');
  const [selectedClient, setSelectedClient] = useState('-');
  // option datas
  const [clients, setClients] = useState([]);
  // modify dialog
  const [empPayTaxModifyOpen, setPayTaxModifyOpen] = useState(false);
  // modify sheet dialog
  const [empPayTaxModifySheetOpen, setPayTaxModifySheetOpen] = useState(false);
  // detail
  const [empPayTaxDetailData, setPayTaxDetailData] = useState(undefined);
  const handleClickListRow = (data) => {
    setPayTaxDetailData(data);
  };
  // regist/modify input
  const [empPayTaxInputData, setPayTaxInputData] = useState({
    empId: '',
  });

  // select list
  const handleClickSearch = (pageNo, yearParam, monthParam, clientParam) => {
    setPayTaxDetailData(undefined);
    requestPostWithJsonAPI('getPayTaxList', {
      page: pageNo,
      keyword: keyword,
      page_row_num: rowCount,
      year: (yearParam) ? yearParam : year,
      month: (monthParam) ? monthParam : month,
      client_cd: (clientParam) ? clientParam : selectedClient,
      agency_cd: (userAuthInfo.agency) ? userAuthInfo.agency.agencyCd : '',
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          setCurPage(pageNo);
          setTableData({ data: response.data.listData });
          setTotalCnt(Number(response.data.listTotal));
        }
      }
    });
  };

  useEffect(() => {
    setClientsWithFunc(setClients, {
      agency_cd: (userAuthInfo.agency) ? userAuthInfo.agency.agencyCd : '',
    });

    handleClickSearch(1);
  }, [userAuthInfo]);

  // 직원 수정 다이얼로그 표시
  function handleClickModifyPayTax() {
    setPayTaxInputData(empPayTaxDetailData);
    setPayTaxModifyOpen(true);
  }

  // 직원 Sheet 수정 다이얼로그 표시
  const handleClickModifySheetPayTax = () => {
    setPayTaxInputData(empPayTaxDetailData);
    setPayTaxModifySheetOpen(true);
  }

  // 급여정보 엑셀 다운로드 --------------------------------------------------------------------------------------
  const downloadPayTaxExcelWithMulti = (checkedData) => {
    requestPostDownloadAPI('getPayTaxSheetDownload', {
      emp_id_list: JSON.stringify((checkedData.length < 1) ? [] : checkedData.map(e => e.empId)),
      pay_year: year,
      pay_month: month,
      client_cd: (selectedClient && selectedClient !== '-') ? selectedClient : '',
      agency_cd: (userAuthInfo.agency) ? userAuthInfo.agency.agencyCd : '',
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        var FileSaver = require('file-saver');
        FileSaver.saveAs(response.data, `급여정보_Sheet_${year}${('0' + month).slice(-2)}`);
      }
    });
  }

  // 직원 수정
  const handleClickEditPayTax = (param) => {
    empPayTaxInputData['pay_year'] = year;
    empPayTaxInputData['pay_month'] = month;
    requestPostWithJsonAPI('setPayTaxEdit', createParamObject(empPayTaxInputData)).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setPayTaxModifyOpen(false);
          setPayTaxModifySheetOpen(false);
          showAlert('empdataedit', `'${empPayTaxInputData.empNm}' 직원정보가 수정되었습니다.`, 'success', 3000);
        }
      }
    });
  }

  // 툴바에서 파라미터 변경 이벤트 처리
  const handleChangeToolbarOption = (data) => {
    if (data.name === 'keyword') {
      setKeyword(data.value);
    } else if (data.name === 'keywordEnter') {
      handleClickSearch(1);
    } else if (data.name === 'selectYear') {
      setYear(data.value);
      setMonth('1');
      handleClickSearch(1, data.value, '1');
    } else if (data.name === 'selectClient') {
      setSelectedClient(data.value);
      handleClickSearch(1, undefined, '1', data.value);
    }
  }

  const handleClickToolbarButton = (e) => {
    if (e.currentTarget.id === 'pay-toolbar-search') {
      handleClickSearch(1);
    } else {
      // 이전달 정보 복사 - 일부 또는 전체
      let checkedData = tableData.data.filter(e => e['checked'] !== undefined && e['checked'] === true);
      let targetText = `선택된(${checkedData.length}명) 직원의 `;
      if (checkedData.length === 0) {
        targetText = `직원 전체의`;
      }
      if (e.currentTarget.id === 'pay-toolbar-excelall') {
        const msg = [`${targetText} ${year}년 ${('0' + month).slice(-2)}월 급여정보를`, `엑셀형식으로 다운로드 하시겠습니까?`];
        showInform('급여정보 다운로드', msg, 'CONFIRM', result => {
          if (result === 'Y') {
            downloadPayTaxExcelWithMulti(checkedData);
          } else {
            return;
          }
        });
      }
    }
  }

  const handleClickToolbarMonthButton = (paramMonth) => {
    setMonth(paramMonth);
    handleClickSearch(1, year, paramMonth);
  }

  // 수정/등록에서의 입력 컴포넌트 이벤트 처리
  const handleChangeInput = (data) => {
    // deep clone
    const temp = {};
    for (let key of Object.keys(empPayTaxInputData)) {
      temp[key] = empPayTaxInputData[key]
    }
    if (Array.isArray(data)) {
      data.every(e => temp[e.name] = e.value);
    } else {
      if (data.type === 'number') {
        temp[data.name] = data.value.split(',').join('');
      } else {
        temp[data.name] = data.value;
      }
    }
    setPayTaxInputData(temp);
  }

  return (
    <div className={classes.root}>
      <MenuHeader />

      <PayTaxToolbar
        clients={clients}
        selectedYear={year}
        selectedMonth={month}
        onChangeParam={handleChangeToolbarOption}
        onClickButton={handleClickToolbarButton}
        onClickMonthButton={handleClickToolbarMonthButton} />

      <PayTaxList
        tableData={tableData}
        setTableData={setTableData}
        totalCnt={totalCnt}
        curPage={curPage}
        rowCount={rowCount}
        onChangePage={handleClickSearch}
        onClickListRow={handleClickListRow}
        selectedRow={empPayTaxDetailData}
        selectedClient={selectedClient} />

      <PayTaxDetail empPayTaxData={empPayTaxDetailData}
        onClickModifyPayTax={handleClickModifyPayTax}
        onClickModifySheetPayTax={handleClickModifySheetPayTax}
      />

      <AntDialog open={empPayTaxModifyOpen} maxWidth='md'
        title='직원 급여 정보 수정'
        comment='직원 급여 정보를 수정하는 화면'
        onClose={() => setPayTaxModifyOpen(false)}
        draggable={true}
        actions={[
          { key: 1, label: '취소', callback: () => setPayTaxModifyOpen(false) },
          { key: 2, label: '저장', callback: handleClickEditPayTax }
        ]}
        content={<PayTaxInputForm onFormChange={handleChangeInput}
          empPayTaxData={empPayTaxInputData}
        />}
      />

      <AntDialog open={empPayTaxModifySheetOpen} maxWidth='lg'
        title='직원 급여 정보 수정 - Sheet'
        comment='직원 급여 정보를 Sheet 형태로 수정하는 화면3'
        onClose={() => setPayTaxModifySheetOpen(false)}
        draggable={true}
        actions={[
          { key: 1, label: '취소', callback: () => setPayTaxModifySheetOpen(false) },
          { key: 2, label: '저장', callback: handleClickEditPayTax }
        ]}
        content={<PayTaxSheetInputForm onFormChange={handleChangeInput}
          empPayTaxData={empPayTaxInputData}
        />}
      />
    </div>
  );
};

export default PayTaxMain;
