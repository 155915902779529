import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { sha256 } from 'js-sha256';

import { MenuHeader } from 'layouts/main/blocks/menu';
import ClientToolbar from './ClientToolbar';
import ClientList from './ClientList';
import ClientDetail from './ClientDetail';
import ClientInputForm from './ClientInputForm';
import ClientUserInputForm from './ClientUserInputForm';

import { AntDialog } from 'components/dialog';
import { requestPostWithJsonAPI } from 'common/services/AntsRequester';
import { createParamObject } from 'common/utils/DataUtils';
import UserAuthContext from 'common/context/UserAuthContext';

import useAlert from 'common/hooks/useAlert';
import useInform from 'common/hooks/useInform';

// Style Class
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#efefef',
  },
}));
// Row Count
const rowCount = 15;
const clientVo = {
  clientCd: '',
  clientNm: '',
  clientAddr: '',
  adminId: '',
  adminNm: '',
  password: '',
  ceoNm: '',
  businessNo: '',
  telNo: '',
  email: '',
};

const ClientMain = props => {
  const classes = useStyles();
  const { showAlert } = useAlert();
  const { showInform } = useInform();
  const { userAuthInfo } = useContext(UserAuthContext);

  const [tableData, setTableData] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [totalCnt, setTotalCnt] = useState(0);

  // option
  const [keyword, setKeyword] = useState('');
  // option datas

  // client regist dialog
  const [clientRegistOpen, setClientRegistOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);

  // detail
  const [clientDetailData, setClientDetailData] = useState(undefined);
  const handleClickListRow = (data) => {
    setClientDetailData(data);
  };
  // regist/modify input
  const [clientInputData, setClientInputData] = useState(clientVo);
  // client-user regist dialog
  const [clientUserRegistOpen, setClientUserRegistOpen] = useState(false);

  // select list
  const handleClickSearch = (pageNo) => {
    setClientDetailData(undefined);
    requestPostWithJsonAPI('getClientList', {
      agencyCd: (userAuthInfo && userAuthInfo.agency) ? userAuthInfo.agency.agencyCd : '',
      page: pageNo,
      keyword: keyword,
      page_row_num: rowCount
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          setCurPage(pageNo);
          setTableData({ data: response.data.listData });
          setTotalCnt(Number(response.data.listTotal));
        }
      }
    });
  };

  useEffect(() => {
    handleClickSearch(1);
  }, [userAuthInfo]);

  // 회사 생성 - API 호출
  const handleClickCreateClient = () => {
    // console.log('clientInputData :::: ', clientInputData);
    requestPostWithJsonAPI('setClientCreate', createParamObject(clientInputData)).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setClientRegistOpen(false);
          showAlert('clientdatacreate', `'${clientInputData.clientNm}' 회사정보가 생성되었습니다.`, 'success', 3000);
        }
      }
    });
  }

  // 회사 삭제 - API 호출
  const handleClickDeleteClient = (param) => {
    requestPostWithJsonAPI('setClientDelete', {
      client_cd: clientDetailData.clientCd,
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          showAlert('clientdatadelete', `'${clientDetailData.clientNm}' 회사정보가 삭제되었습니다.`, 'success', 3000);
        }
      }
    });
  }

  // 회사 수정 - API 호출
  const handleClickEditClient = (param) => {
    // console.log('edit clientInputData ::: ', clientInputData);
    requestPostWithJsonAPI('setClientEdit', createParamObject(clientInputData)).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setClientRegistOpen(false);
          showAlert('clientdataedit', `'${clientInputData.clientNm}' 회사정보가 수정되었습니다.`, 'success', 3000);
        }
      }
    });
  }

  // 세무사 관리자 정보 생성/수정 다이얼로그 표시
  const handleClickRegistClientAdminUser = () => {
    // 세무사 관리자 정보 생성/수정 다이얼로그 표시
    const newAdminList = (clientDetailData.adminList && clientDetailData.adminList.length > 0) ?
      clientDetailData.adminList : [{ adminId: '', password: '', telNo: '', email: '' }];

    setClientInputData({
      ...clientDetailData,
      clientCd: clientDetailData.clientCd,
      adminList: newAdminList,
      isEdit: (newAdminList[0].adminId !== '') ? true : false,
    });

    // 세무사 관리자 정보 생성/수정 다이얼로그 표시
    setClientUserRegistOpen(true);
  }

  // 회사 관리자 생성 API 호출
  const handleClickCreateClientAdmin = () => {
    requestPostWithJsonAPI('setClientAdminCreate', createParamObject({
      client_cd: clientInputData.clientCd,
      admin_id: clientInputData.adminList[0].adminId,
      password: sha256(clientInputData.adminList[0].password),
      admin_nm: clientInputData.adminList[0].adminNm,
      tel_no: clientInputData.adminList[0].telNo,
      email: clientInputData.adminList[0].email,
    })).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setClientUserRegistOpen(false);
          showAlert('clientuserdatacreate', `'${clientInputData.adminList[0].adminNm}' 관리자가 생성되었습니다.`, 'success', 3000);
        } else {
          showAlert('clientuserdatacreate-error', `${response.data.status.message}`, 'error', 3000);
        }
      }
    });
  }

  // 회사 관리자 수정 API 호출
  const handleClickEditClientAdmin = (param) => {
    requestPostWithJsonAPI('setAdminUserEdit', createParamObject({
      admin_id: clientInputData.adminList[0].adminId,
      password: sha256(clientInputData.adminList[0].password),
      admin_nm: clientInputData.adminList[0].adminNm,
      tel_no: clientInputData.adminList[0].telNo,
      email: clientInputData.adminList[0].email,
    })).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setClientUserRegistOpen(false);
          showAlert('clientuserdataedit', `'${clientInputData.adminList[0].adminNm}' 관리자가 수정되었습니다.`, 'success', 3000);
        } else {
          showAlert('clientuserdataedit-error', `${response.data.status.message}`, 'error', 3000);
        }
      }
    });
  }

  // 회사 관리자 삭제 API 호출
  const handleClickDeleteClientAdmin = (param) => {
    showInform('회사 관리자정보 삭제', `'${clientInputData.adminList[0].adminId}' 관리자 정보를 삭제하시겠습니까?`, 'CONFIRM', result => {
      if (result === 'Y') {
        requestPostWithJsonAPI('setAdminUserDelete', createParamObject({
          admin_id: clientInputData.adminList[0].adminId,
        })).then((response) => {
          if (response && response.status && response.status === 200) {
            if (response.data && response.data.status && response.data.status.result === 'success') {
              handleClickSearch(1);
              setClientUserRegistOpen(false);
              showAlert('clientuserdatadelete', `'${clientInputData.adminList[0].adminNm}' 관리자가 삭제되었습니다.`, 'success', 3000);
            } else {
              showAlert('clientuserdatadelete-error', `${response.data.status.message}`, 'error', 3000);
            }
          }
        });
      } else {
        return;
      }
    });
  }

  // 툴바에서 파라미터 변경 이벤트 처리
  const handleChangeToolbarOption = (data) => {
    if (data.name === 'keyword') {
      setKeyword(data.value);
    } else if (data.name === 'keywordEnter') {
      handleClickSearch(1);
    }
  }
  const handleClickToolbarButton = (e) => {
    if (e.currentTarget.id === 'client-toolbar-search') {
      handleClickSearch(1);
    } else if (e.currentTarget.id === 'client-toolbar-regist') {
      setIsNew(true);
      setClientInputData(clientVo);
      setClientRegistOpen(true);
    }
  }

  // 수정/등록에서의 입력 컴포넌트 이벤트 처리
  const handleFormChange = (data) => {
    setClientInputData(data);
  }

  const hasAdmin = (clientDetailData && clientDetailData.adminList && clientDetailData.adminList.length > 0) ? true : false;

  console.log('userAuthInfo >   >>>>>> ', userAuthInfo);

  return (
    <div className={classes.root}>
      <MenuHeader />

      <ClientToolbar
        onChangeParam={handleChangeToolbarOption}
        onClickButton={handleClickToolbarButton} />

      <ClientList
        tableData={tableData}
        totalCnt={totalCnt}
        curPage={curPage}
        rowCount={rowCount}
        onChangePage={handleClickSearch}
        onClickListRow={handleClickListRow}
        selectedRow={clientDetailData} />

      <ClientDetail clientData={clientDetailData}
        onClickModifyClient={() => {
          setIsNew(false);
          setClientInputData({
            ...clientDetailData,
            password: ''
          });
          setClientRegistOpen(true);
        }}
        onClickRegistClientAdminUser={handleClickRegistClientAdminUser}
        onClickDeleteClient={handleClickDeleteClient} />

      <AntDialog open={clientRegistOpen}
        title={isNew ? '회사정보 등록' : '회사정보 수정'}
        comment={isNew ? '새로운 회사 정보를 등록합니다.' : '회사 정보를 수정합니다.'}
        onClose={() => { setClientRegistOpen(false); }}
        draggable={true}
        actions={[
          { key: 1, label: '취소', callback: () => { setClientRegistOpen(false); } },
          { key: 2, label: '저장', callback: isNew ? handleClickCreateClient : handleClickEditClient }
        ]}
        content={<ClientInputForm onFormChange={handleFormChange}
          inputData={clientInputData} isNew={isNew}
        />}
      />

      <AntDialog open={clientUserRegistOpen} 
        title={hasAdmin ? '회사 관리자 정보 수정' : '회사 관리자 등록'}
        comment={hasAdmin ? '회사 관리자 정보를 수정하는 화면' : '새로운 회사 관리자 정보를 등록하기 위한 정보 입력및 저장'}
        onClose={() => { setClientUserRegistOpen(false); } }
        draggable={true}
        actions={[
          { key: (hasAdmin) ? -1 : 0, label: '삭제', callback: handleClickDeleteClientAdmin },
          { key: 1, label: '취소', callback: () => { setClientUserRegistOpen(false); } },
          { key: 2, label: '저장', callback: hasAdmin ? handleClickEditClientAdmin : handleClickCreateClientAdmin }
        ]}
        content={<ClientUserInputForm onFormChange={handleFormChange}
          inputData={clientInputData}
        />}
      />
    </div>
  );
};

export default ClientMain;
