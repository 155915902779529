import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Avatar, Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    // height: '100%',
    border: '0px solid red',
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  data: {
    fontWeight: 700,
    textAlign: '-webkit-right',
    paddingRight: 12,
    marginTop: 8,
  },
  avatar: {
    backgroundColor: '#f2e418',
    color: '#FFFFFF',
    height: 42,
    width: 42
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  }
}));

const IconTitleDataPane = props => {
  const { data, height } = props;

  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Grid container style={{ height: height }}>
        <Grid item xs={3}
          style={{
            alignSelf: 'center',
            textAlign: '-webkit-center', 
            border: '0px solid green',
          }}
        >
          <Avatar className={classes.avatar}>
            {data.icon}
          </Avatar>
        </Grid>
        <Grid item xs={9}
          style={{ 
            alignSelf: 'center',
            border: '0px solid green',
          }}
        >
          <Typography
            className={classes.title}
            style={{ fontSize: data.titleSize }}
          >
            {data.title}
          </Typography>
          <Typography className={classes.data}
            style={{ fontSize: data.countSize }}
          >
            {data.count}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

IconTitleDataPane.propTypes = {
  className: PropTypes.string
};

export default IconTitleDataPane;
