import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { SearchInput, ToolbarCombo } from 'components/input';
import AntRoundButton from 'components/button/AntRoundButton';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 10px 0px 10px',
    minWidth: 935,
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const EmpToolbar = props => {

  const { className, grades, depts, onClickButton, onChangeParam, ...rest } = props;
  const classes = useStyles();

  const [selectedEmpGrade, setSelectedEmpGrade] = useState('-');
  const [selectedEmpDept, setSelectedEmpDept] = useState('-');

  const handleChangeKeyword = (e) => {
    onChangeParam({'name': 'keyword', 'value': e.target.value});
  }
  const handleChangeEmpGrade = (e) => {
    setSelectedEmpGrade(e.target.value);
    onChangeParam({ 'name': 'selectEmpGrade', 'value': e.target.value });
  }
  const handleChangeEmpDept = (e) => {
    setSelectedEmpDept(e.target.value);
    onChangeParam({ 'name': 'selectEmpDept', 'value': e.target.value });
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid container spacing={2}>
      <Grid item md={2}>
        <ToolbarCombo
            label="부서"
            value={selectedEmpDept}
            items={depts}
            style={{ width: 150, maxWidth: 230 }}
            onChange={handleChangeEmpDept}
          />
        </Grid>
        <Grid item md={2}>
        <ToolbarCombo
            label="직급"
            value={selectedEmpGrade}
            items={grades}
            style={{ width: 150, maxWidth: 230 }}
            onChange={handleChangeEmpGrade}
          />
        </Grid>
        <Grid item md={4} style={{ display: 'flex' }}>
        <SearchInput className={classes.searchInput}
            style={{ maxWidth: 280 }}
            placeholder="Search"
            onChange={handleChangeKeyword}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                handleChangeKeyword(event);
              }
            }}
          />
          <AntRoundButton id="emp-toolbar-search" variant="contained" onClick={onClickButton} style={{ fontWeight: 'bold', height: 30 }}>
            <SearchIcon style={{ width: 20, height: 20, marginRight: 5 }} />
            검색
          </AntRoundButton>
        </Grid>
        <Grid item md={4} style={{ textAlign: 'right' }}>
          <AntRoundButton id="emp-toolbar-regist" variant="contained" onClick={onClickButton} style={{ fontWeight: 'bold', height: 30 }}>
            등록
          </AntRoundButton>
        </Grid>
      </Grid>
    </div>
  );
};

EmpToolbar.propTypes = {
  className: PropTypes.string
};

export default EmpToolbar;
