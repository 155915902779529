/**
 * 파라미터 변환 : camelcase -> snakecase
 */
// camel to snake
const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
export function createParamObject(obj) {
    let returnObj = {};
    for (const n in obj) {
        returnObj[camelToSnakeCase(n)] = obj[n];
    }
    return returnObj;
};
