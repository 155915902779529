import { RC_DOMAIN } from 'common/services/AntsRequester';
import axios from 'axios';

export const login = (username, password) => {
  // const { setUserAuthInfo } = useContext(UserAuthContext);
  return axios({
    method: 'post',
    url: RC_DOMAIN + 'api/auth/signin',
    headers: { 'Content-Type': 'application/json' },
    data: {
      username,
      password
    }
  }).then(response => {
    if (response && response.data) {
      // console.log('AUTH-SIGNIN response.data ::::: ', response.data);
      if(response.data.status === 'fail') {
        return 'ERROR';
      } else {
        // check ROLE
        if(response.data.roles && response.data.roles.length > 0 && response.data.roles[0] === 'ROLE_USER') {
          return 'ERROR';
        } else if (response.data.accessToken) {
          // check is need password change.
          const resData = response.data;
          // console.log('AUTH-SIGNIN resData ::::: ', resData);
  
          let keyName = '';
          if (response.data.isPwCh === 'Y') {
            keyName = 'temp_user';
          } else {
            keyName = 'user';
          }
  
          sessionStorage.setItem(keyName, JSON.stringify({
            fileNo: resData.fileNo,
            accessToken: resData.accessToken,
            userId: resData.userId,
            roles: resData.roles,
            agency: (resData.agency) ? resData.agency : null,
            company: (resData.company) ? resData.company : null,
          }));
  
          sessionStorage.setItem('token', JSON.stringify({
            refreshToken: resData.refreshToken
          }));

          return response.data;
        }
      }
    }
    return 'ERROR';
  }).catch(error => {
    console.log('api/auth/signin error : ', error);
    return 'ERROR';
  });
};

export const changePassword = (result) => {
  if (result && result === 'success') {
    sessionStorage.setItem("user", sessionStorage.getItem("temp_user"));
    sessionStorage.removeItem('temp_user');
    return true;
  }
  return false;
};

export const logout = () => {
  sessionStorage.removeItem("user");
};

export const register = (username, email, password) => {
  return axios({
    method: 'post',
    url: RC_DOMAIN + 'api/auth/signup',
    headers: { 'Content-Type': 'application/json' },
    data: {
      username,
      email,
      password
    }
  }).then(response => {

  }).catch(error => {
    console.log('api/auth/signup error : ', error);
  });
};

export const getCurrentUser = () => {
  return JSON.parse(sessionStorage.getItem('user'));;
};
