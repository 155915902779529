import React, { forwardRef, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Box, List, ListItem, ListItemIcon, colors } from '@material-ui/core';

import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    margin: 0,
    marginBottom: 20,

    color: '#fff'
  },
  groupItem: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    height: 38,
    fontSize: 11,
    color: theme.palette.ian2color.main,
    borderBottomWidth: 1,
    borderBottomStyle: 'dashed',
    borderBottomColor: theme.palette.ain2color.light,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.ain2color.light,
  },
  item: {
    height: 42,
    display: 'flex',
    padding: '10px 20px',
    color: "#aaabae",
    borderBottom: '1px solid #454a5466',
    "& .MuiListItemIcon-root": {
      minWidth: 30,
      color: "#aaabae"
    },
    "&.MuiListItem-root.Mui-selected": {
      "& .MuiListItemIcon-root": {
        fontWeight: 700,
        color: "#ffffff"
      },
      "& .MuiListItemText-primary": {
        fontWeight: 700,
        color: "#ffffff"
      },
    },
    "& .MuiListItemText-primary": {
      color: "#aaabae"
    },
    "&:hover": {
      transitionProperty: 'background-color',
      transitionDuration: '0.5s',
      transitionDelay: '0s',
      backgroundColor: "#33374b",
      "& .MuiListItemIcon-root": {
        transitionProperty: 'color',
        transitionDuration: '0.5s',
        transitionDelay: '0s',
        color: "#ffffff"
      },
      "& .MuiListItemText-primary": {
        transitionProperty: 'color',
        transitionDuration: '0.5s',
        transitionDelay: '0s',
        color: "#ffffff"
      },
    },
    "&:active": {
      backgroundColor: "#8091e2",
      "& .MuiListItemIcon-root": {
        color: "#ffffff"
      },
      "& .MuiListItemText-primary": {
        color: "#ffffff"
      }
    }
  },
  subExtItem: {
    paddingLeft: 50
  },
  button: {
    borderRadius: 0,
    color: theme.palette.ain2color.dark,
    padding: '2px 15px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 14,
    "&.MuiButton-root:hover": {
      color: theme.palette.ian1color.light,
    }
  },
  subButton: {
    borderRadius: 0,
    color: colors.blueGrey[800],
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 27,
    paddingRight: 0,
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 14,
    "&.MuiButton-root:hover": {
      color: theme.palette.ian1color.light,
    }
  },
  icon: {
    color: theme.palette.ain1color.light,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  iconExpand: {
    color: theme.palette.ian2color.main,
  },
  subIcon: {
    color: theme.palette.ain3color.dark,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: 0
  },
  active: {
    backgroundColor: '#96b5d4',
    color: theme.palette.white,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  flexGrow: {
    flexGrow: 1
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const ThinMenuTree = props => {
  const { pages, className, label, ...rest } = props;
  const classes = useStyles();
  const [selectedMenu, setSelectedMenu] = useState('');
  const [mnOpen, setMnOpen] = useState({});

  const handleClickMenuGroup = (mtype, menuId) => {
    if (mtype === 'm') {
      setSelectedMenu(menuId);
    } else if (mtype === 'g') {
      // deep clone
      const result = {};
      for (let key of Object.keys(mnOpen)) {
        result[key] = mnOpen[key]
      }
      if (result[menuId] !== undefined) {
        result[menuId] = !result[menuId]
      } else {
        result[menuId] = true;
      }
      setMnOpen(result);
    }
  };

  return (
    <div style={{ paddingTop: 0 }}>
      {/* <div style={{height: 36, fontSize: 42, fontFamily: 'system-ui', fontWeight:900, lineHeight:'35px', color: '#c1d6d38f', textAlign: 'center', background: 'linear-gradient(0deg, #ffffff 50%, #b7d8d4 94%)'}}>{label}</div> */}
      <List {...rest} className={clsx(classes.root, className)} component="nav">
        {pages.filter(s => s.unvisible === undefined || s.unvisible === false).map(page => {
          if (page.type === 'group') {
            return <ListItem className={classes.groupItem} key={page.menuId}>
              <Box>{page.title}</Box>
            </ListItem>;
          } else {
            return <div key={page.menuId}>
              {page.subPages && page.subPages.length > 0 ? (
                <Tooltip title={page.title} placement="right" arrow>
                  <ListItem button
                    className={classes.item}
                    disableGutters
                    onClick={e => {
                      handleClickMenuGroup('g', page.menuId);
                    }}
                    selected={(selectedMenu === page.menuId)}
                  >
                    <ListItemIcon className={classes.itemIcon}>
                      {page.icon}
                    </ListItemIcon>
                  </ListItem>
                </Tooltip>
              ) : (
                <Tooltip title={page.title} placement="right" arrow>
                  <ListItem
                    component={CustomRouterLink}
                    to={page.href}
                    className={classes.item}
                    disableGutters
                    onClick={e => {
                      handleClickMenuGroup('m', page.menuId);
                    }}
                    selected={(selectedMenu === page.menuId)}
                  >
                    <ListItemIcon className={classes.itemIcon}>
                      {page.icon}
                    </ListItemIcon>
                  </ListItem>
                </Tooltip>
              )}
              {mnOpen[page.menuId] ? (
                <Collapse in={true} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {page.subPages && page.subPages.length > 0
                      ? page.subPages
                        .filter(s => s.unvisible === undefined || s.unvisible === false)
                        .map(s => (
                          <ListItem
                            component={CustomRouterLink}
                            to={s.href}
                            className={clsx(classes.item, classes.subExtItem)}
                            disableGutters
                            onClick={e => {
                              handleClickMenuGroup('m', s.menuId);
                            }}
                            selected={(selectedMenu === s.menuId)}
                            key={s.menuId}>
                          </ListItem>
                        ))
                      : ''}
                  </List>
                </Collapse>
              ) : (
                ''
              )}
            </div>;
          }
        })}
      </List>
    </div>
  );
};

export default ThinMenuTree;
