import React, { useState, useCallback } from 'react';

export const AlertContext = React.createContext({
  alert: null,
  showAlert: () => {},
  removeAlert: () => {}
});

export default function AlertProvider({ children }) {
  const [alert, setAlert] = useState(null);

  const removeAlert = () => {
    setAlert(null);
  };

  const showAlert = (msgKey, msgString, msgStatus, msgHideTime) => {
    setAlert({ msgKey, msgString, msgStatus, msgHideTime });
  };

  const contextValue = {
    alert,
    showAlert: useCallback((msgKey, msgString, msgStatus, msgHideTime) => {
      showAlert(msgKey, msgString, msgStatus, msgHideTime)
    }, []),
    removeAlert: useCallback(() => {
      removeAlert();
    }, [])
  };

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
    </AlertContext.Provider>
  );
}
