import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    minWidth: 780,
  },
  card: {
    boxShadow: 'none',
    border: '1px solid #d0d0d0',
    borderRadius: 0,
  },
  actions: {
    justifyContent: 'flex-end',
  },
  selectedRow: {
    cursor: 'pointer',
    backgroundColor: theme.palette.antTable.selectedRowBack,
  },
  normalRow: {
    cursor: 'pointer',
  }
}));

const cellInfo = [
  ['회사코드', 'clientCd', 'center'],
  ['회사이름', 'clientNm', 'center'],
  ['대표이름', 'ceoNm', 'center'],
  ['연락처', 'telNo', 'center'],
  ['이메일', 'email', 'center'],
  ['등록일', 'regDt', 'center'],
  ['수정일', 'modDt', 'center'],
];

const ClientList = (props) => {
  const { className, tableData, totalCnt, onChangePage, onClickListRow, selectedRow, curPage, rowCount, ...rest } = props;
  const classes = useStyles();
  const [stateTableData, setStateTableData] = useState([]);

  useEffect(() => {
    setStateTableData(tableData);
  }, [tableData]);

  const handlePageChange = (event, page) => {
    onChangePage(page);
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
    <Card className={classes.card}>
      <CardContent style={{padding: 10}}>
          <div style={{fontSize: 12, fontWeight: 500}}>
            {'전체 ' + totalCnt + ' 건'}
          </div>
          <Table>
            <TableHead>
              <TableRow>
                {/* 테이블헤더 그리는 loop */}
                {cellInfo.map((column, index) => {
                  return (
                    <TableCell key={index} style={{ textAlign: 'center', fontSzie: 10 }}>
                      {column[0]}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            {stateTableData && stateTableData.data && (
              <TableBody>
                {/* 테이블 행그리는 loop */}
                {stateTableData.data.map((row, index) => (
                  <TableRow
                    hover
                    key={index}
                    style={{cursor: 'pointer'}}
                    className={(selectedRow && selectedRow['clientCd'] === row['clientCd']) ? classes.selectedRow : classes.normalRow}
                    onClick={() => {
                      onClickListRow(row);
                    }}
                  >
                    {/* 각 행에 cell을 채울 loop */}
                    {cellInfo.map((cell, index) => {
                        return (
                          <TableCell key={index} align={cell[2]}>
                            {row[cell[1]]}
                          </TableCell>
                        );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          {totalCnt < 1 && (
            <div style={{border: '1px solid #daefd1', textAlign: 'center', height:100, paddingTop: 40}}>데이터가 없습니다.</div>
          )}
      </CardContent>
      <CardActions className={classes.actions}>
        <Pagination
          count={Math.ceil(totalCnt / rowCount)}
          page={totalCnt < rowCount ? 1 : curPage}
          onChange={handlePageChange}
          variant="outlined"
        />
      </CardActions>
    </Card>
    </div>
  );
};

export default ClientList;
