
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { sha256 } from 'js-sha256';

import { MenuHeader } from 'layouts/main/blocks/menu';
import { AdminToolbar, AdminList, AdminDetail } from '.';
import { AdminInputForm } from '.';

import { AntDialog } from 'components/dialog';
import { requestPostWithJsonAPI } from 'common/services/AntsRequester';

import useAlert from 'common/hooks/useAlert';
import { createParamObject } from 'common/utils/DataUtils';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#efefef',
  },
}));

const rowCount = 15;

const adminVo = {
  adminId: '',
  adminNm: '',
  password: '',
  telNo: '',
  email: '',
};

const AdminMain = props => {
  const classes = useStyles();
  const { showAlert } = useAlert();

  const [tableData, setTableData] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [totalCnt, setTotalCnt] = useState(0);

  // option
  const [keyword, setKeyword] = useState('');

  // regist dialog
  const [adminRegistOpen, setAdminRegistOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  // detail
  const [adminDetailData, setAdminDetailData] = useState(undefined);
  const handleClickListRow = (data) => {
    setAdminDetailData(data);
  };
  // regist/modify input
  const [adminInputData, setAdminInputData] = useState(adminVo);

  useEffect(() => {
    handleClickSearch(1);
  }, []);

  // select list
  const handleClickSearch = (pageNo) => {
    setAdminDetailData(undefined);
    requestPostWithJsonAPI('getAdminUserList', {
      page: pageNo,
      keyword: keyword,
      page_row_num: rowCount,
      user_tp: 'ADMIN',
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          setCurPage(pageNo);
          setTableData({ data: response.data.listData });
          setTotalCnt(Number(response.data.listTotal));
        }
      }
    });
  };

  const handleFormChange = (data) => {
    setAdminInputData(data);
  }

  // 툴바에서 파라미터 변경 이벤트 처리
  const handleChangeToolbarOption = (data) => {
    if (data.name === 'keyword') {
      setKeyword(data.value);
    } else if (data.name === 'keywordEnter') {
      handleClickSearch(1);
    }
  }
  const handleClickToolbarButton = (e) => {
    if (e.currentTarget.id === 'project-toolbar-search') {
      handleClickSearch(1);
    } else if (e.currentTarget.id === 'project-toolbar-regist') {
      setIsNew(true);
      setAdminInputData(adminVo);
      setAdminRegistOpen(true);
    }
  }

  // 관리자 생성 API 호출
  const handleClickCreateAdmin = () => {
    requestPostWithJsonAPI('setAdminUserCreate', createParamObject({
      ...adminInputData,
      password: sha256(adminInputData.password),
    })).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setAdminRegistOpen(false);
          showAlert('admindatacreate', `'${adminInputData.adminNm}' 관리자 정보가 생성되었습니다.`, 'success', 3000);
        }
      }
    });
  }

  // 관리자 수정 API 호출
  const handleClickEditAdmin = (param) => {
    requestPostWithJsonAPI('setAdminUserEdit', createParamObject({
      ...adminInputData,
      password: sha256(adminInputData.password),
    })).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setAdminRegistOpen(false);
          showAlert('admindataedit', `'${adminInputData.adminNm}' 관리자가 수정되었습니다.`, 'success', 3000);
        } else {
          showAlert('admindataedit-error', `${response.data.status.message}`, 'error', 3000);
        }
      }
    });
  }

  // 관리자 삭제 API 호출
  const handleClickDeleteAdmin = (param) => {
    requestPostWithJsonAPI('setAdminUserDelete', {
      admin_id: adminDetailData.adminId,
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          showAlert('adminuserdelete', `'${adminDetailData.adminNm}' 관리자정보가 삭제되었습니다.`, 'success', 3000);
        }
      }
    });
  }

  return (
    <div className={classes.root}>
      <MenuHeader />

      <AdminToolbar onChangeParam={handleChangeToolbarOption} onClickButton={handleClickToolbarButton} />

      <AdminList
        tableData={tableData}
        totalCnt={totalCnt}
        curPage={curPage}
        rowCount={rowCount}
        onChangePage={handleClickSearch}
        onClickListRow={handleClickListRow} />

      <AdminDetail adminData={adminDetailData}
        onClickModifyAdmin={() => {
          setIsNew(false);
          setAdminInputData({
            ...adminDetailData,
            password: ''
          });
          setAdminRegistOpen(true);
        }}
        onClickDeleteAdmin={handleClickDeleteAdmin}
      />

      <AntDialog open={adminRegistOpen}
        title={isNew ? '관리자 등록' : '관리자 수정'}
        comment={isNew ? '신규 관리자 정보를 등록합니다.' : '관리자 정보를 수정합니다.'}
        onClose={() => { setAdminRegistOpen(false); }}
        draggable={true}
        actions={[
          { "key": "1", "label": "취소", "callback": () => { setAdminRegistOpen(false); } },
          { "key": "2", "label": "저장", "callback": isNew ? handleClickCreateAdmin : handleClickEditAdmin }
        ]}
        content={<AdminInputForm onFormChange={handleFormChange} inputData={adminInputData} isNew={isNew} />}
      />
    </div>
  );
};

export default AdminMain;
