import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { Grid } from '@material-ui/core';
import ContentPaneTitleBar from 'components/container/ContentPaneTitleBar';
import { LabelCard } from 'components/card';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    minWidth: 780,
  },
  main: {
    boxShadow: 'none',
    border: '1px solid #d0d0d0',
    borderRadius: 0,
  },
  content: {
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    borderRadius: 0,
    padding: 10,
  },
}));


const AgencyDetail = (props) => {
  const { className, agencyData, onClickModifyAgency, onClickRegistAgencyAdminUser, onClickDeleteAgency, ...rest } = props;
  const classes = useStyles();

  // console.log('agencyData :::::: ', agencyData);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {(agencyData !== undefined) &&
        <div className={classes.main}>
          <ContentPaneTitleBar title='세무사 상세정보'
            style={{ backgroundColor: '#777777' }}
            buttons={[{
              name: '수정', action: 'edit',
              func: onClickModifyAgency,
            }, {
              name: '삭제', action: 'delete',
              confirm: { title: '세무사정보 삭제', content: '정말 삭제하시겠습니까?', func: onClickDeleteAgency },
            }, {
              name: '관리자 등록/수정', action: 'user',
              func: onClickRegistAgencyAdminUser,
            },]}
          />
          <div className={classes.content} >
            <Grid container spacing={6} style={{marginBottom: 15}}>
              <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="이름" labelWidth={120} value={agencyData.agencyNm} />
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="번호" labelWidth={120} value={agencyData.agencyCd} />
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: 0 }}>
                <LabelCard label="주소" labelWidth={120} value={agencyData.agencyAddr} />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="등록일(작업자)" labelWidth={120} value={(agencyData.regUserId && agencyData.regUserId !== '') ? agencyData.regDt + ' (' + agencyData.regUserId + ')' : '-'} />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="수정일(작업자)" labelWidth={120} value={(agencyData.modUserId && agencyData.modUserId !== '') ? agencyData.modDt + ' (' + agencyData.modUserId + ')' : '-'} />
              </Grid>
            </Grid>
            {(agencyData && agencyData.adminList && agencyData.adminList.length > 0) &&
              <Grid container spacing={6} style={{marginTop: 15}}>
                <Grid item xs={12} style={{paddingBottom: 0}}>
                <LabelCard label="관리자 정보" labelWidth={120} noUnderbar={true} isRef={true} />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                  <LabelCard label="아이디" labelWidth={120} value={agencyData.adminList[0].adminId} />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                  <LabelCard label="이름" labelWidth={120} value={agencyData.adminList[0].adminNm} />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                  <LabelCard label="전화번호" labelWidth={120} value={agencyData.adminList[0].telNo} />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                  <LabelCard label="이메일" labelWidth={120} value={agencyData.adminList[0].email} />
                </Grid>
                <Grid item xs={12}></Grid>
              </Grid>
            }
          </div>
        </div>
      }
    </div>
  );
};

export default AgencyDetail;
