import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { Grid, Typography } from '@material-ui/core';
import ContentPaneTitleBar from 'components/container/ContentPaneTitleBar';
import { LabelCard, GroupLabel } from 'components/card';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    minWidth: 780,
  },
  main: {
    boxShadow: 'none',
    border: '1px solid #d0d0d0',
    borderRadius: 0,
  },
  content: {
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    borderRadius: 0,
    padding: 10,
  },
  pw: {
    color: '#ee0707d6',
    fontWeight: 600
  },
}));

const priceStyle = {
  textAlign: 'right',
  width: '100%',
  paddingRight: '20px',
}

const changePriceString = (val) => {
  if(val && val !== '') {
    return Number(val).toLocaleString();
  } else {
    return '';
  }
}


const PayrollDetail = (props) => {
  const { className, empPayrollData, onClickModifyPayroll, onClickModifySheetPayroll, onClickCopyBeforePayroll, onClickPayrollPdfDownload, onClickPayrollPdfEmail, ...rest } = props;
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {(empPayrollData !== undefined) &&
        <div className={classes.main}>
          <ContentPaneTitleBar title='직원 급여 상세정보'
            style={{ backgroundColor: '#777777' }}
            buttons={[{
              name: '수정', action: 'edit',
              func: onClickModifyPayroll,
            }, {
              name: '수정(Sheet)', action: 'edit',
              func: onClickModifySheetPayroll,
            }, {
              name: '이전정보 복사', action: 'copy',
              func: onClickCopyBeforePayroll,
            }, {
              name: '급여명세서 다운로드', action: 'pdf',
              func: onClickPayrollPdfDownload,
            }, {
              name: '급여명세서 이메일', action: 'email',
              func: onClickPayrollPdfEmail,
            }]}
          />
          <div className={classes.content} >
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="직원 번호" labelWidth={120} value={empPayrollData.empNo} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="직원 이름" labelWidth={120} value={empPayrollData.empNm} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="직급" labelWidth={120} value={empPayrollData.gradeStr} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="전화번호" labelWidth={120} value={empPayrollData.phone} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="부서" labelWidth={120} value={empPayrollData.deptStr} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="이메일" labelWidth={120} value={empPayrollData.email} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="주민등록번호" labelWidth={120} value={empPayrollData.residentNo} />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="수정일(작업자)" labelWidth={120} value={(empPayrollData.modUserId && empPayrollData.modUserId !== '') ? empPayrollData.modDt + ' (' + empPayrollData.modUserId + ')' : '-'} />
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12} style={{ paddingBottom: 0, display: 'flex' }}><GroupLabel label="기 본 급 여 및 제 수 당" labelWidth={140} />{(empPayrollData.base && empPayrollData.base !== '') ? '' : <Typography className={classes.pw}>정보가 없습니다. '기본급여 및 제수당' 정보를 입력하세요.</Typography>}</Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="기본급" labelWidth={120} value={changePriceString(empPayrollData.base)} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="상여" labelWidth={120} value={changePriceString(empPayrollData.bonus)} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="식대보조금" labelWidth={120} value={changePriceString(empPayrollData.mealSubs)} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="자가운전보조금" labelWidth={120} value={changePriceString(empPayrollData.driveSubs)} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="연장근로수당" labelWidth={120} value={changePriceString(empPayrollData.overworkBnf)} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="야간근로수당" labelWidth={120} value={changePriceString(empPayrollData.nightBnf)} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="직책수당" labelWidth={120} value={changePriceString(empPayrollData.gradeBnf)} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="파견수당" labelWidth={120} value={changePriceString(empPayrollData.dispatchBnf)} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12}></Grid>
              {(empPayrollData.base && empPayrollData.base !== '') && <>
              <Grid item xs={12} style={{ paddingBottom: 0 }}><GroupLabel label="공 제 및 차 인 지 급 액" labelWidth={140} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="국민연금" labelWidth={120} value={changePriceString(empPayrollData.nationPention)} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="건강보험" labelWidth={120} value={changePriceString(empPayrollData.healthInsr)} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="산재보험" labelWidth={120} value={changePriceString(empPayrollData.accidInsr)} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="고용보험" labelWidth={120} value={changePriceString(empPayrollData.empInsr)} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="장기요양" labelWidth={120} value={changePriceString(empPayrollData.longRecu)} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="소득세" labelWidth={120} value={changePriceString(empPayrollData.incomeTax)} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="주민세" labelWidth={120} value={changePriceString(empPayrollData.residentTax)} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="건강보험 연말정산" labelWidth={120} value={changePriceString(empPayrollData.yecHealthInsr)} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="장기요양보험 연말정산" labelWidth={120} value={changePriceString(empPayrollData.yecLongRecu)} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="고용보험 연말정산" labelWidth={120} value={changePriceString(empPayrollData.yecEmpInsr)} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="환급이자" labelWidth={120} value={changePriceString(empPayrollData.refundIntr)} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12}></Grid>
              </>}
            </Grid>
          </div>
        </div>
      }
    </div>
  );
};

export default PayrollDetail;
