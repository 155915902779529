import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { Grid, TextField } from '@material-ui/core';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { LabelCard } from 'components/card';

import Input from '@material-ui/core/Input';

import clone from 'clone';
import useInform from 'common/hooks/useInform';
import { InputLabelCard } from 'components/card';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import Typography from 'theme/typography';

// Style Class
const useStyles = makeStyles(theme => ({
  cellNo: {
    backgroundColor: '#efefef',
    border: '1px solid #e3e3e3',
    textAlign: 'center',
    padding: '2px'
  },
  cellData: {
    padding: '2px',
    textAlign: 'center',
    border: '1px solid #e3e3e3'
  },
  editCellData: {
    padding: '2px',
    border: '1px solid #e3e3e3',
    backgroundColor: '#f5de6f'
  },
  cellValue: {
    border: 0,
    disableUnderline: 'false',
    textAlign: 'center',
    width: '100%'
  },
  editCellValue: {
    border: 0,
    disableUnderline: 'false',
    backgroundColor: '#f5de6f'
  },
  cusBtn: {
    backgroundColor: '#3c63a8',
    color: '#ffffff',
    padding: 2,
    fontWeight: 600
  }
}));

const AgentCrawEditForm = (props) => {
  const { onIsEditing, isNew, editScript, onScriptChange, globalVars } = props;
  const classes = useStyles();

  const { showInform } = useInform();
  const [editMode, setEditMode] = useState(true);

  const [editScObj, setEditScObj] = useState();

  const [editScContent, setEditScContent] = useState([]);
  const [changedScContent, setChangedScContent] = useState([]);
  const [contentStr, setContentStr] = useState('');

  const [editNo, setEditNo] = useState(-1);
  // console.log('inputData --> ', inputData);
  // console.log('editScript --> ', editScript);

  const [scVars, setScVars] = useState([]);
  const [scGVars, setScGVars] = useState([]);

  useEffect(() => {

    // console.log("useEffect : editScript ---> ", editScript);
    console.log("useEffect : globalVars ---> ", globalVars);

    if (!isNew) {
      if (editScript.scriptVars) {
        setScVars(JSON.parse(editScript.scriptVars));
        setScGVars((editScript.scriptGVars) ? JSON.parse(editScript.scriptGVars) : []);
      }
      setEditScObj(editScript);
      try {
        setEditScContent(JSON.parse(editScript.content));
        setChangedScContent(JSON.parse(editScript.content));
      } catch (err) {

      }
    } else {
      if (editScript && editScript.content) {
        setScGVars((editScript.scriptGVars) ? JSON.parse(editScript.scriptGVars) : []);
        setEditScObj(editScript);
        try {
          setEditScContent(JSON.parse(editScript.content));
          setChangedScContent(JSON.parse(editScript.content));
        } catch (err) {

        }
      } else {
        setScVars([]);
        setScGVars((editScript.scriptGVars) ? JSON.parse(editScript.scriptGVars) : []);
        setEditScObj({
          "scriptNm": "",
          "scriptCmt": "",
          "startUrl": "",
          "content": "[[\"wait\",\"id\",\"\",\"1000\",\"\",\"click\",\"\"]]"
        });
        setEditScContent(JSON.parse("[[\"wait\",\"id\",\"\",\"1000\",\"\",\"click\",\"\"]]"));
        setChangedScContent(JSON.parse("[[\"wait\",\"id\",\"\",\"1000\",\"\",\"click\",\"\"]]"));
      }
    }
  }, [editScript]);

  // console.log('obj --> ', editScContent);
  const xsColumns = [[20, '순서'], [20, '기능'], [20, '타입'], [20, '선택값'], [20, '대기시간'], [20, '기타'], [20, '액션'], [20, '변수'], [20, '메모'], [20, '작업']];
  const handleClickEdit = (no) => {
    // console.log('edit :: ', no);
    setEditNo(no);
    onIsEditing(true);
  }
  const handleClickEditCancel = (no) => {
    // console.log('handleClickEditCancel :: ', no);
    const newScObj = clone(editScObj);
    // console.log('handleClickEditCancel [newScObj] ->  ', newScObj);
    newScObj.content = JSON.stringify(changedScContent);
    onScriptChange(newScObj);
    setEditNo(-1);
    onIsEditing(false);
  }
  const handleClickSaveRow = (no) => {
    const newScContent = [...changedScContent];
    newScContent[no] = editScContent[no];

    // 5번째가 'input' 또느 'global' 이 아니면 8번째는 공백으로 변경 필요.
    if(newScContent[no][5] != 'input' && newScContent[no][5] != 'global') {
      newScContent[no][8] = '';
    }
    // 더블따옴표 치환
    newScContent[no][2] = newScContent[no][2].replace(/\"/gi, "'");

    setChangedScContent(newScContent);

    const newScObj = clone(editScObj);
    newScObj.content = JSON.stringify(newScContent);
    onScriptChange(newScObj);
    setEditNo(-1);
    onIsEditing(false);
  }
  const handleClickDelete = (no) => {
    // console.log('delete :: ', no);
    const msg = [`${(Number(no) + 1)} 번 항목을 삭제하시겠습니까?`];
    showInform('시나리오 항목 삭제', msg, 'CONFIRM', result => {
      if (result === 'Y') {
        // 삭제
        editScContent.splice(no, 1);
        const newScObj = clone(editScObj);
        newScObj.content = JSON.stringify(editScContent);
        onScriptChange(newScObj);
      } else {
        return;
      }
    });
  }
  const handleClickAdd = (no) => {
    const msg = [`${(Number(no) + 1)} 번 항목 아래에`, `새로운 항목을 추가하시겠습니까?`];
    showInform('시나리오 항목 추가', msg, 'CONFIRM', result => {
      if (result === 'Y') {
        // 추가
        editScContent.splice((Number(no) + 1), 0, ['wait', 'xpath', "", '1000', '', '', '']);
        const newScObj = clone(editScObj);
        newScObj.content = JSON.stringify(editScContent);
        onScriptChange(newScObj);
      } else {
        return;
      }
    });
  }

  const handleClickMoveUp = (no) => {
    // 위로 이동
    // 1. 빼고
    const selectItem = editScContent.splice(no, 1);
    // 2. 넣기
    editScContent.splice((Number(no) - 1), 0, selectItem[0]);
    const newScObj = clone(editScObj);
    newScObj.content = JSON.stringify(editScContent);
    onScriptChange(newScObj);
  }

  const handleClickMoveDown = (no) => {
    // 아래로 이동
    // 1. 빼고
    const selectItem = editScContent.splice(no, 1);
    // 2. 넣기
    editScContent.splice((Number(no) + 1), 0, selectItem[0]);
    const newScObj = clone(editScObj);
    newScObj.content = JSON.stringify(editScContent);
    onScriptChange(newScObj);
  }

  const handleChangeContentInput = (e, rowNo, colNo) => {
    const newScObj = [...editScContent];
    // if(newScObj[rowNo].length > colNo) {
    //   newScObj[rowNo][colNo] = e.target.value;
    // } else {
    while (newScObj[rowNo].length <= colNo) {
      newScObj[rowNo].push('');
    }
    newScObj[rowNo][colNo] = e.target.value;
    // }
    setEditScContent(newScObj);
  }

  const handleChangeNameInput = (e, rowNo, colNo) => {
    const newScObj = clone(editScObj);
    newScObj.scriptNm = e.target.value;
    setEditScObj(newScObj);
    onScriptChange(newScObj);
  }

  const handleChangeCommentInput = (e, rowNo, colNo) => {
    const newScObj = clone(editScObj);
    newScObj.scriptCmt = e.target.value;
    setEditScObj(newScObj);
    onScriptChange(newScObj);
  }

  const handleChangeUrlInput = (e) => {
    const newScObj = clone(editScObj);
    newScObj.startUrl = e.target.value;
    setEditScObj(newScObj);
    onScriptChange(newScObj);
  }

  const handleChangeContentStr = (e) => {
    setContentStr(e.target.value);

    const newScObj = clone(editScObj);
    newScObj.content = e.target.value.replace(/\n/g, "");
    onScriptChange(newScObj);
  }

  const changeEditMode = () => {
    if (editMode) {
      let tstr = '[\n';
      for (let i = 0; i < changedScContent.length; i++) {
        tstr += '[';
        for (let k = 0; k < changedScContent[i].length; k++) {
          tstr += '"' + changedScContent[i][k] + '"';
          if (k + 1 < changedScContent[i].length) {
            tstr += ', ';
          }
        }
        tstr += ']';
        if (i + 1 < changedScContent.length) {
          tstr += ',';
        }
        tstr += '\n';
      }
      tstr += ']';
      setContentStr(tstr);
    } else {

      try {
        setChangedScContent(JSON.parse(contentStr));
        setEditScContent(JSON.parse(contentStr));
      } catch (err) {

      }

    }

    setEditMode(!editMode);
  }

  const handleClickAddNewScVar = () => {
    const newScVars = [...scVars];
    newScVars.push(['', '', '']);

    setScVars(newScVars);

    console.log('handleClickAddNewScVar [newScVars] ->  ', newScVars);

    const newScObj = clone(editScObj);
    newScObj.scriptVars = JSON.stringify(newScVars);
    console.log('handleClickAddNewScVar [newScObj] ->  ', newScObj);
    onScriptChange(newScObj);
  }

  const handleClickDeleteScVar = (index) => {
    const msg = [`${(index + 1)} 번째 변수를 삭제하시겠습니까?`];
    showInform('변수 삭제', msg, 'CONFIRM', result => {
      if (result === 'Y') {
        // 삭제
        const newScVars = [...scVars];
        newScVars.splice(index, 1);
        setScVars(newScVars);

        const newScObj = clone(editScObj);
        newScObj.scriptVars = JSON.stringify(newScVars);
        onScriptChange(newScObj);

        // 이벤트 to parent
      } else {
        return;
      }
    });

  }

  const handleChangeScVar = (e, i, no) => {
    const newScVars = [...scVars];
    newScVars[i][no] = e.target.value;

    setScVars(newScVars);
    // console.log('handleChangeScVar [newScVars] ->  ', newScVars);

    const newScObj = clone(editScObj);
    newScObj.scriptVars = JSON.stringify(newScVars);
    onScriptChange(newScObj);
  }

  // console.log('DRAW SCOBJ :: ', editScContent);
  // console.log('DRAW editScObj :: ', editScObj);
  // console.log('editScContent :: ', editScContent);
  console.log('scVars :: ', scVars);

  return (
    <div style={{ width: '100%', padding: 10 }}>
      <Grid container spacing={6}>
        <Grid item xs={4}>
          <TextField label="시나리오 이름" fullWidth
            value={(editScObj) ? editScObj.scriptNm : ''}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={handleChangeNameInput}
          /><br /><br />
          <TextField label="설명" fullWidth
            value={(editScObj) ? editScObj.scriptCmt : ''}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={handleChangeCommentInput}
          /><br /><br />
          <TextField label="시작 웹 주소" fullWidth
            value={(editScObj) ? editScObj.startUrl : ''}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={handleChangeUrlInput}
          />
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={6} style={{ marginTop: 0 }}>
            <Grid item xs={12} style={{ paddingBottom: 0 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <LabelCard label="공용 변수 정보" labelWidth={120} noUnderbar={true} isRef={true} />
              </div>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <div style={{ width: '100%', maxHeight: 130, overflow: 'scroll' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ textAlign: 'center', fontSzie: 10, width: '100px' }}>변수명</TableCell>
                      <TableCell style={{ textAlign: 'center', fontSzie: 10, width: '100px' }}>디폴트값</TableCell>
                      <TableCell style={{ textAlign: 'center', fontSzie: 10, width: '100px' }}>변수설명</TableCell>
                    </TableRow>
                  </TableHead>
                  {(globalVars && globalVars.length > 0) && (
                    <TableBody>
                      {/* 테이블 행그리는 loop */}
                      {globalVars.map((g, index) => (
                        <TableRow hover key={index} className={classes.normalRow} >
                          <TableCell align={"center"}>{g.varNm}</TableCell>
                          <TableCell align={"center"}>{g.defaultVal}</TableCell>
                          <TableCell align={"center"}>{g.memo}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={6} style={{ marginTop: 0 }}>
            <Grid item xs={12} style={{ paddingBottom: 0 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <LabelCard label="시나리오 변수 정보" labelWidth={120} noUnderbar={true} isRef={true} />
                <button className={classes.cusBtn} onClick={handleClickAddNewScVar} >신규 등록</button>
              </div>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <div style={{ width: '100%', maxHeight: 130, overflow: 'scroll' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ textAlign: 'center', fontSzie: 10, width: '100px' }}>변수명</TableCell>
                      <TableCell style={{ textAlign: 'center', fontSzie: 10, width: '100px' }}>디폴트값</TableCell>
                      <TableCell style={{ textAlign: 'center', fontSzie: 10, width: '100px' }}>변수설명</TableCell>
                      <TableCell style={{ textAlign: 'center', fontSzie: 10, width: '100px' }}>삭제</TableCell>
                    </TableRow>
                  </TableHead>
                  {(scVars && scVars.length > 0) && (
                    <TableBody>
                      {/* 테이블 행그리는 loop */}
                      {scVars.map((row, index) => (
                        <TableRow hover key={index} className={classes.normalRow} >
                          <TableCell align={"center"}>
                            <Input value={row[0]} className={classes.cellValue}
                              onChange={(e) => { handleChangeScVar(e, index, 0); }} />
                          </TableCell>
                          <TableCell align={"center"}>
                            <Input value={row[2]} className={classes.cellValue}
                              onChange={(e) => { handleChangeScVar(e, index, 2); }} />
                          </TableCell>
                          <TableCell align={"center"}>
                            <Input value={row[1]} className={classes.cellValue}
                              onChange={(e) => { handleChangeScVar(e, index, 1); }} />
                          </TableCell>
                          <TableCell align={"center"}>
                            <Tooltip title="삭제">
                              <IconButton style={{ padding: 1, marginLeft: 20 }} onClick={() => { handleClickDeleteScVar(index) }}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ marginTop: 20, marginBottom: 10 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {(editMode) &&
            <InputLabelCard label="크롤링 시나리오 정보" labelWidth={120} noUnderbar={true} />
          }
          {(!editMode) &&
            <InputLabelCard label="크롤링 시나리오 정보" value="[ JSON 문법을 위해 '선택값' 데이터 내부에는 더블따옴표를 사용할 수 없습니다. 싱글따옴표를 사용하세요. ]" valueStyle={{ color: '#f12323', fontWeight: 'bold' }} labelWidth={120} noUnderbar={true} />
          }
          <button className={classes.cusBtn} onClick={() => changeEditMode()} >{editMode ? '텍스트형식으로' : '테이블형식으로'} 편집</button>
        </div>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            {xsColumns && xsColumns.length > 0 && xsColumns.map((t, k) => (
              <TableCell key={k} style={{ textAlign: 'center', fontSzie: 10, minWidth: t[0] }}>{t[1]}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        {editScContent && editScContent.length > 0 &&
          <TableBody>
            {editScContent.map((s, i) => (
              <TableRow hover key={i} className={classes.normalRow} >
                <TableCell align={"center"} >{"#" + (i + 1)}</TableCell>
                <TableCell align={"center"} className={(editNo == i) ? classes.editCellData : classes.cellData}>
                  {(editNo == i) &&
                    <Select labelId="sel-1" id="sel-1" variant="outlined" inputProps={{ style: { padding: 2 }, }}
                      value={s[0]} onChange={(e) => { handleChangeContentInput(e, i, 0); }}
                    >
                      <MenuItem value={'wait'}>wait</MenuItem>
                      <MenuItem value={'frame'}>frame</MenuItem>
                    </Select>
                  }
                  {(editNo != i) && <>{s[0]}</>}
                </TableCell>
                <TableCell align={"center"} className={(editNo == i) ? classes.editCellData : classes.cellData}>
                  {(editNo == i) &&
                    <Select labelId="sel-2" id="sel-2" variant="outlined" inputProps={{ style: { padding: 2 }, }}
                      value={s[1]} onChange={(e) => { handleChangeContentInput(e, i, 1); }}
                    >
                      <MenuItem value={'xpath'}>xpath</MenuItem>
                      <MenuItem value={'class'}>class</MenuItem>
                      <MenuItem value={'id'}>id</MenuItem>
                      <MenuItem value={'implicit'}>implicit</MenuItem>
                    </Select>
                  }
                  {(editNo != i) && <>{s[1]}</>}
                </TableCell>
                <TableCell className={(editNo == i) ? classes.editCellData : classes.cellData}>
                  {(editNo == i) &&
                    <Input value={s[2]} className={classes.cellValue}
                      onChange={(e) => { handleChangeContentInput(e, i, 2); }}
                      disableUnderline={(editNo == i) ? false : true}
                      readOnly={(editNo == i) ? false : true} />
                  }
                  {(editNo != i) &&
                    <p style={{ textAlign: 'left' }}>{s[2]}</p>
                  }
                </TableCell>
                <TableCell className={(editNo == i) ? classes.editCellData : classes.cellData}>
                  {(editNo == i) &&
                    <Input value={s[3]} className={classes.cellValue}
                      onChange={(e) => { handleChangeContentInput(e, i, 3); }}
                      disableUnderline={(editNo == i) ? false : true}
                      readOnly={(editNo == i) ? false : true} />
                  }
                  {(editNo != i) &&
                    <p style={{ textAlign: 'left' }}>{s[3]}</p>
                  }
                </TableCell>
                <TableCell align={"center"} className={(editNo == i) ? classes.editCellData : classes.cellData}>
                  {(editNo == i) &&
                    <Select labelId="sel-4" id="sel-4" variant="outlined" inputProps={{ style: { padding: 2 }, }}
                      value={s[4]} onChange={(e) => { handleChangeContentInput(e, i, 4); }}
                    >
                      <MenuItem value={''}>공백</MenuItem>
                      <MenuItem value={'RETURN'}>RETURN</MenuItem>
                    </Select>
                  }
                  {(editNo != i) && <>{s[4]}</>}
                </TableCell>
                <TableCell align={"center"} className={(editNo == i) ? classes.editCellData : classes.cellData}>
                  {(editNo == i) &&
                    <Select labelId="sel-5" id="sel-5" variant="outlined" inputProps={{ style: { padding: 2 }, }}
                      value={s[5]} onChange={(e) => { handleChangeContentInput(e, i, 5); }}
                    >
                      <MenuItem value={''}>공백</MenuItem>
                      <MenuItem value={'click'}>click</MenuItem>
                      <MenuItem value={'switch'}>switch</MenuItem>
                      <MenuItem value={'input'}>input</MenuItem>
                      <MenuItem value={'global'}>global</MenuItem>
                    </Select>
                  }
                  {(editNo != i) && <>{s[5]}</>}
                </TableCell>
                <TableCell>
                  {(editNo == i) &&
                    <Select labelId="sel-6" id="sel-6" variant="outlined" inputProps={{ style: { padding: 2 }, }}
                      value={(s.length > 8) ? s[8] : ''} onChange={(e) => { handleChangeContentInput(e, i, 8); }}
                    >
                        <MenuItem key={i} value=''></MenuItem>
                      {s[5] == 'input' && editScObj && editScObj.scriptVars && JSON.parse(editScript.scriptVars).map((e, i) => (
                        <MenuItem key={i} value={e[0]}>{e[0]}</MenuItem>
                      ))}
                      {s[5] == 'global' && globalVars && globalVars.length > 0 && globalVars.map((e, i) => (
                        <MenuItem key={i} value={e.varNm}>{e.varNm}</MenuItem>
                      ))}
                    </Select>
                  }
                  {(editNo != i) && <>{(s.length > 8) ? s[8] : ''}</>}
                </TableCell>
                <TableCell className={(editNo == i) ? classes.editCellData : classes.cellData}>
                  {(editNo == i) &&
                    <Input value={s[6]} className={classes.cellValue}
                      onChange={(e) => { handleChangeContentInput(e, i, 6); }}
                      disableUnderline={(editNo == i) ? false : true}
                      readOnly={(editNo == i) ? false : true} />
                  }
                  {(editNo != i) &&
                    <p style={{ textAlign: 'left' }}>{s[6]}</p>
                  }
                </TableCell>
                <TableCell>
                  {(editNo == i) &&
                    <span>
                      <Tooltip title="완료">
                        <IconButton style={{ padding: 1 }} onClick={() => { handleClickSaveRow(`${i}`); }}>
                          <CheckCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="취소">
                        <IconButton style={{ padding: 1 }} onClick={() => { handleClickEditCancel(`${i}`); }}>
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  }
                  {(editNo != i) &&
                    <Tooltip title="수정">
                      <IconButton style={{ padding: 1 }} onClick={() => { handleClickEdit(`${i}`); }} disabled={(editNo > -1) ? true : false}>
                        <CreateIcon />
                      </IconButton>
                    </Tooltip>
                  }
                  {(editScContent.length > 1) &&
                    <Tooltip title="삭제">
                      <IconButton style={{ padding: 1 }} onClick={() => { handleClickDelete(`${i}`); }} disabled={(editNo > -1) ? true : false}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  }
                  <Tooltip title="아래 추가하기">
                    <IconButton style={{ padding: 1 }} onClick={() => { handleClickAdd(`${i}`); }} disabled={(editNo > -1) ? true : false}>
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Tooltip>
                  {i > 0 &&
                    <Tooltip title="위로이동">
                      <IconButton style={{ padding: 1 }} onClick={() => { handleClickMoveUp(`${i}`); }} disabled={(editNo > -1) ? true : false}>
                        <ArrowUpwardIcon />
                      </IconButton>
                    </Tooltip>
                  }
                  {(i + 1) < editScContent.length &&
                    <Tooltip title="아래로이동">
                      <IconButton style={{ padding: 1 }} onClick={() => { handleClickMoveDown(`${i}`); }} disabled={(editNo > -1) ? true : false}>
                        <ArrowDownwardIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        }
      </Table>

      {!editMode &&
        <div>
          <textarea style={{ width: '100%' }} rows="20" value={contentStr} onChange={handleChangeContentStr}></textarea>
        </div>
      }

    </div>
  );
};

export default AgentCrawEditForm;
