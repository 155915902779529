import React from 'react';

import useAlert from 'common/hooks/useAlert';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const AlertNotification = props => {
  const { alert, removeAlert } = useAlert();

  const handleCloseMessage = () => {
    removeAlert();
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={!!alert}
      onClose={handleCloseMessage}
      key={(alert && alert.msgKey) ? alert.msgKey : ''}
      autoHideDuration={(alert && alert.msgHideTime) ? alert.msgHideTime : 6000}
    >
      <MuiAlert elevation={6} variant="filled" onClose={handleCloseMessage} 
        severity={(alert && alert.msgStatus) ? alert.msgStatus : 'error'}>
        {(alert && alert.msgString) ? alert.msgString : ''}
      </MuiAlert>
    </Snackbar>
  );
};

export default AlertNotification;