import React, { useContext, useState, useEffect } from 'react';

import { useLocation, Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles';
import { Typography, Breadcrumbs, Hidden } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import CropSquare from '@material-ui/icons/CropSquare';

import { getMenus } from 'common/menu/MenuData';
import UserAuthContext from 'common/context/UserAuthContext';

const useStyles = makeStyles(theme => ({
  menuHeader: {
    borderBottom: "1px dashed #B3AFAF",
    paddingLeft: 10,
    paddingRight: 30,
    height: 38,
  }
}));

const MenuHeader = (props) => {
  const classes = useStyles();
  const currentPath = useLocation().pathname;

  const { userAuthInfo } = useContext(UserAuthContext);

  const [menuName, setMenuName] = useState('');
  const [breadcrumbList, setBreadcrumbList] = useState([]);

  useEffect((pages = getMenus(userAuthInfo.role)) => {
    // console.log(userAuthInfo.role);
    let tempBreadcrumbList = [];
    pages.every(e => {
      tempBreadcrumbList[0] = e;
      if (e.href === currentPath) {
        setMenuName(e.title);
        return false;
      } else {
        if (e.subPages !== undefined && e.subPages.length > 0) {
          e.subPages.every(n => {
            if (n.href === currentPath) {
              tempBreadcrumbList[1] = n;
              setMenuName(n.title);
              return false;
            } else {
              if (n.subPages !== undefined && n.subPages.length > 0) {
                const cMenu = n.subPages.find(b => b.href === currentPath);
                if (cMenu !== undefined) {
                  tempBreadcrumbList[1] = n;
                  tempBreadcrumbList[2] = cMenu;
                  setMenuName(cMenu.title);
                  return false;
                }
              }
            }
            if (tempBreadcrumbList[1] !== undefined) {
              return false;
            }
            return true;
          });
        }
      }

      if (tempBreadcrumbList[1] !== undefined) {
        return false;
      }
      return true;
    });
    setBreadcrumbList(tempBreadcrumbList);
    // eslint-disable-next-line  
  }, [currentPath, userAuthInfo]);

  return (
    <Grid className={classes.menuHeader}
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
          <CropSquare style={{ color: '#797979', paddingTop: '7px', marginBottom: '-3px', marginRight: '3px' }} />
          {menuName} {(props.exMenuName ? props.exMenuName : '')}
        </Typography>
      </Grid>
      <Hidden smDown>
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb">
            <Link style={{ color: '#99a73b' }} to="/">
              Home
            </Link>
            {breadcrumbList.map((n, index) => {
              if (index + 1 < breadcrumbList.length) {
                // return (n.href) ? <Link color="inherit" to={n.href} key={n.menuId}>{n.title}</Link> : <Button color="inherit" key={n.menuId}>{n.title}</Button>;
                return <Typography color="textPrimary" key={n.menuId}>{n.title}</Typography>
              } else {
                return <Typography color="textPrimary" key={n.menuId}>{n.title}</Typography>
              }
            })}
          </Breadcrumbs>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default MenuHeader;
