import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { SearchInput, ToolbarCombo } from 'components/input';
import { AntRoundButton, AntRectButton } from 'components/button';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 10px 0px 10px',
    minWidth: 935,
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const monthBtnStyle = {
  fontWeight: 'bold', height: 30, minWidth: 40, margin: 5, borderRadius: 5,
};
const monthSelectedBtnStyle = {
  fontWeight: 'bold', height: 30, minWidth: 40, margin: 5, borderRadius: 5, backgroundColor: '#b6b90c'
};

const getBeforeMonth = (y, m) => {
  let d = new Date(m + " 1 " + y);
  d.setMonth(d.getMonth() - 1);
  // return d.getFullYear().toString().slice(2, 4) + "년" + (d.getMonth() + 1) + "월";
  return d.getFullYear().toString() + "년" + (d.getMonth() + 1) + "월";
};

const PayTaxToolbar = props => {

  const { className, clients, onClickButton, onClickMonthButton, onChangeParam, selectedYear, selectedMonth, ...rest } = props;
  const classes = useStyles();

  const [selectedClient, setSelectedClient] = useState('-');

  const handleChangeKeyword = (e) => {
    onChangeParam({ 'name': 'keyword', 'value': e.target.value });
  }
  const handleChangeYear = (e) => {
    onChangeParam({ 'name': 'selectYear', 'value': e.target.value });
  }
  const handleChangeClient = (e) => {
    setSelectedClient(e.target.value);
    onChangeParam({ 'name': 'selectClient', 'value': e.target.value });
  }

  const beforeMonth = getBeforeMonth(selectedYear, selectedMonth);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid container spacing={2}>
        <Grid item md={2}>
          <ToolbarCombo
            label="년도"
            value={selectedYear}
            items={[{ name: '2021', value: '2021' }, { name: '2022', value: '2022' }, { name: '2023', value: '2023' }, { name: '2024', value: '2024' }]}
            style={{ width: 150, maxWidth: 230 }}
            onChange={handleChangeYear}
          />
        </Grid>
        <Grid item md={4}>
          <ToolbarCombo
            label="고객사"
            value={selectedClient}
            items={clients}
            style={{ width: 250, maxWidth: 260 }}
            onChange={handleChangeClient}
          />
        </Grid>
        <Grid item md={3} style={{ display: 'flex' }}>
          <SearchInput className={classes.searchInput}
            style={{ maxWidth: 130 }}
            placeholder="Search"
            onChange={handleChangeKeyword}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                onChangeParam({ name: 'keywordEnter', value: event.target.value });
              }
            }}
          />
          <AntRoundButton id="pay-toolbar-search" variant="contained" onClick={onClickButton} style={{ fontWeight: 'bold', height: 30 }}>
            <SearchIcon style={{ width: 20, height: 20, marginRight: 5 }} />
            검색
          </AntRoundButton>
        </Grid>
        <Grid item md={3} style={{ textAlign: 'right' }}>
          <AntRoundButton id="pay-toolbar-excelall" variant="contained" onClick={onClickButton} style={{ fontWeight: 'bold', height: 30 }}>
            엑셀
          </AntRoundButton>
        </Grid>
        <Grid item xs={12}><hr color="#cad4f5" /></Grid>
        <Grid item xs={12} style={{ textAlign: 'center', paddingTop: 0 }}>
          <AntRectButton onClick={() => { onClickMonthButton('1'); }} style={(selectedMonth === '1') ? monthSelectedBtnStyle : monthBtnStyle}>1월</AntRectButton>
          <AntRectButton onClick={() => { onClickMonthButton('2'); }} style={(selectedMonth === '2') ? monthSelectedBtnStyle : monthBtnStyle}>2월</AntRectButton>
          <AntRectButton onClick={() => { onClickMonthButton('3'); }} style={(selectedMonth === '3') ? monthSelectedBtnStyle : monthBtnStyle}>3월</AntRectButton>
          <AntRectButton onClick={() => { onClickMonthButton('4'); }} style={(selectedMonth === '4') ? monthSelectedBtnStyle : monthBtnStyle}>4월</AntRectButton>
          <AntRectButton onClick={() => { onClickMonthButton('5'); }} style={(selectedMonth === '5') ? monthSelectedBtnStyle : monthBtnStyle}>5월</AntRectButton>
          <AntRectButton onClick={() => { onClickMonthButton('6'); }} style={(selectedMonth === '6') ? monthSelectedBtnStyle : monthBtnStyle}>6월</AntRectButton>
          <AntRectButton onClick={() => { onClickMonthButton('7'); }} style={(selectedMonth === '7') ? monthSelectedBtnStyle : monthBtnStyle}>7월</AntRectButton>
          <AntRectButton onClick={() => { onClickMonthButton('8'); }} style={(selectedMonth === '8') ? monthSelectedBtnStyle : monthBtnStyle}>8월</AntRectButton>
          <AntRectButton onClick={() => { onClickMonthButton('9'); }} style={(selectedMonth === '9') ? monthSelectedBtnStyle : monthBtnStyle}>9월</AntRectButton>
          <AntRectButton onClick={() => { onClickMonthButton('10'); }} style={(selectedMonth === '10') ? monthSelectedBtnStyle : monthBtnStyle}>10월</AntRectButton>
          <AntRectButton onClick={() => { onClickMonthButton('11'); }} style={(selectedMonth === '11') ? monthSelectedBtnStyle : monthBtnStyle}>11월</AntRectButton>
          <AntRectButton onClick={() => { onClickMonthButton('12'); }} style={(selectedMonth === '12') ? monthSelectedBtnStyle : monthBtnStyle}>12월</AntRectButton>
        </Grid>
      </Grid>
    </div>
  );
};

PayTaxToolbar.propTypes = {
  className: PropTypes.string
};

export default PayTaxToolbar;
