import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { sha256 } from 'js-sha256';

import { MenuHeader } from 'layouts/main/blocks/menu';
import { AgencyToolbar, AgencyList, AgencyDetail } from '.';
import { AgencyInputForm, AgencyUserInputForm } from '.';

import { AntDialog } from 'components/dialog';
import { requestPostWithJsonAPI } from 'common/services/AntsRequester';
import { createParamObject } from 'common/utils/DataUtils';

import useAlert from 'common/hooks/useAlert';
import useInform from 'common/hooks/useInform';

// Style Class
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#efefef',
  },
}));
// Row Count
const rowCount = 15;

const AgencyMain = props => {
  const classes = useStyles();
  const { showAlert } = useAlert();
  const { showInform } = useInform();

  const [tableData, setTableData] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [totalCnt, setTotalCnt] = useState(0);

  // option
  const [keyword, setKeyword] = useState('');

  // option datas

  // agency regist dialog
  const [agencyRegistOpen, setAgencyRegistOpen] = useState(false);
  const handleRegistDialogClose = () => {
    setAgencyRegistOpen(false);
  };
  const handleRegistDialogOpen = () => {
    setAgencyRegistOpen(true);
  };
  // agency modify dialog
  const [agencyModifyOpen, setAgencyModifyOpen] = useState(false);
  const handleModifyDialogClose = () => {
    setAgencyModifyOpen(false);
  };
  const handleModifyDialogOpen = () => {
    setAgencyModifyOpen(true);
  };

  // detail
  const [agencyDetailData, setAgencyDetailData] = useState(undefined);

  const handleClickListRow = (data) => {
    setAgencyDetailData(data);
    // setAgencyUserDetailData(data);
  };

  // regist/modify input
  const [agencyInputData, setAgencyInputData] = useState({
    agencyCd: '',
    agencyNm: '',
    agencyAddr: '',
    adminId: '',
    adminNm: '',
    password: '',
    telNo: '',
    email: '',
  });

  // agency-user regist dialog
  const [agencyUserRegistOpen, setAgencyUserRegistOpen] = useState(false);
  const handleUserRegistDialogClose = () => {
    setAgencyUserRegistOpen(false);
  };
  const handleUserRegistDialogOpen = () => {
    setAgencyUserRegistOpen(true);
  };

  // select list
  const handleClickSearch = (pageNo) => {
    setAgencyDetailData(undefined);
    requestPostWithJsonAPI('getAgencyList', {
      page: pageNo,
      keyword: keyword,
      page_row_num: rowCount
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          setCurPage(pageNo);
          setTableData({ data: response.data.listData });
          setTotalCnt(Number(response.data.listTotal));
        }
      }
    });
  };

  useEffect(() => {
    handleClickSearch(1);
  }, []);

  // 세무사 생성
  const handleClickCreateAgency = () => {
    // console.log('agencyInputData :::: ', agencyInputData);
    requestPostWithJsonAPI('setAgencyCreate', createParamObject(agencyInputData)).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          handleRegistDialogClose();
          showAlert('agencydatacreate', `'${agencyInputData.agencyNm}' 세무사정보가 생성되었습니다.`, 'success', 3000);
        }
      }
    });
  }

  // 세무사 삭제
  const handleClickDeleteAgency = (param) => {
    requestPostWithJsonAPI('setAgencyDelete', {
      agency_cd: agencyDetailData.agencyCd,
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          showAlert('agencydatadelete', `'${agencyDetailData.agencyNm}' 세무사정보가 삭제되었습니다.`, 'success', 3000);
        }
      }
    });
  }

  // 세무사 수정 다이얼로그 표시
  const handleClickModifyAgency = () => {
    // console.log('agencyDetailData :::::::::::: ', agencyDetailData);
    setAgencyInputData(agencyDetailData);
    handleModifyDialogOpen();
  }

  // 세무사 수정
  const handleClickEditAgency = (param) => {
    // console.log('edit agencyInputData ::: ', agencyInputData);
    requestPostWithJsonAPI('setAgencyEdit', createParamObject(agencyInputData)).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          handleModifyDialogClose();
          showAlert('agencydataedit', `'${agencyInputData.agencyNm}' 세무사정보가 수정되었습니다.`, 'success', 3000);
        }
      }
    });
  }

  // 세무사 관리자 정보 생성/수정 다이얼로그 표시
  const handleClickRegistAgencyAdminUser = () => {
    // 세무사 관리자 정보 생성/수정 다이얼로그 표시
    const newAdminList = (agencyDetailData.adminList && agencyDetailData.adminList.length > 0) ?
      agencyDetailData.adminList : [{ adminId: '', password: '', telNo: '', email: '' }];

    setAgencyInputData({
      ...agencyDetailData,
      agencyCd: agencyDetailData.agencyCd,
      adminList: newAdminList,
      isEdit: (newAdminList[0].adminId !== '') ? true : false,
    });
    
    // 세무사 관리자 정보 생성/수정 다이얼로그 표시
    handleUserRegistDialogOpen();
  }

  // 세무사 관리자 생성 API 호출
  const handleClickCreateAgencyUser = () => {
    requestPostWithJsonAPI('setAgencyAdminCreate', createParamObject({
      agency_cd: agencyInputData.agencyCd,
      admin_id: agencyInputData.adminList[0].adminId,
      password: sha256(agencyInputData.adminList[0].password),
      admin_nm: agencyInputData.adminList[0].adminNm,
      tel_no: agencyInputData.adminList[0].telNo,
      email: agencyInputData.adminList[0].email,
    })).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          handleUserRegistDialogClose();
          showAlert('agencyuserdatacreate', `'${agencyInputData.adminList[0].adminNm}' 관리자가 생성되었습니다.`, 'success', 3000);
        } else {
          showAlert('agencyuserdatacreate-error', `${response.data.status.message}`, 'error', 3000);
        }
      }
    });
  }

  // 세무사 관리자 수정 API 호출
  const handleClickEditAgencyUser = (param) => {
    requestPostWithJsonAPI('setAdminUserEdit', createParamObject({
      admin_id: agencyInputData.adminList[0].adminId,
      admin_nm: agencyInputData.adminList[0].adminNm,
      tel_no: agencyInputData.adminList[0].telNo,
      email: agencyInputData.adminList[0].email,
    })).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          handleUserRegistDialogClose();
          showAlert('agencyuserdataedit', `'${agencyInputData.adminList[0].adminNm}' 관리자가 수정되었습니다.`, 'success', 3000);
        } else {
          showAlert('agencyuserdataedit-error', `${response.data.status.message}`, 'error', 3000);
        }
      }
    });
  }

  // 세무사 관리자 삭제 API 호출
  const handleClickDeleteAgencyUser = (param) => {
    showInform('세무사 관리자정보 삭제', `'${agencyInputData.adminList[0].adminId}' 관리자 정보를 삭제하시겠습니까?`, 'CONFIRM', result => {
      if (result === 'Y') {
        requestPostWithJsonAPI('setAdminUserDelete', createParamObject({
          admin_id: agencyInputData.adminList[0].adminId,
        })).then((response) => {
          if (response && response.status && response.status === 200) {
            if (response.data && response.data.status && response.data.status.result === 'success') {
              handleClickSearch(1);
              handleUserRegistDialogClose();
              showAlert('agencyuserdatadelete', `'${agencyInputData.adminList[0].adminNm}' 관리자가 삭제되었습니다.`, 'success', 3000);
            } else {
              showAlert('agencyuserdatadelete-error', `${response.data.status.message}`, 'error', 3000);
            }
          }
        });
      } else {
        return;
      }
    });
  }

  // 툴바에서 파라미터 변경 이벤트 처리
  const handleChangeToolbarOption = (data) => {
    if (data.name === 'keyword') {
      setKeyword(data.value);
    } else if (data.name === 'keywordEnter') {
      handleClickSearch(1);
    }
  }
  const handleClickToolbarButton = (e) => {
    if (e.currentTarget.id === 'agency-toolbar-search') {
      handleClickSearch(1);
    } else if (e.currentTarget.id === 'agency-toolbar-regist') {
      // 세무사 정보 생성 다이얼로그 표시 - input data 초기화 필요.
      setAgencyInputData({
        agencyCd: '',
        agencyNm: '',
        agencyAddr: '',
      })
      handleRegistDialogOpen();
    }
  }

  // 수정/등록에서의 입력 컴포넌트 이벤트 처리
  const handleChangeInput = (data) => {
    setAgencyInputData(data);
  }

  const hasAdmin = (agencyDetailData && agencyDetailData.adminList && agencyDetailData.adminList.length > 0) ? true : false;

  return (
    <div className={classes.root}>
      <MenuHeader />

      <AgencyToolbar
        onChangeParam={handleChangeToolbarOption}
        onClickButton={handleClickToolbarButton} />

      <AgencyList
        tableData={tableData}
        totalCnt={totalCnt}
        curPage={curPage}
        rowCount={rowCount}
        onChangePage={handleClickSearch}
        onClickListRow={handleClickListRow}
        selectedRow={agencyDetailData} />

      <AgencyDetail agencyData={agencyDetailData}
        onClickModifyAgency={handleClickModifyAgency}
        onClickRegistAgencyAdminUser={handleClickRegistAgencyAdminUser}
        onClickDeleteAgency={handleClickDeleteAgency} />

      <AntDialog open={agencyRegistOpen}
        title='세무사 등록'
        comment='새로운 세무사 정보를 등록하기 위한 정보 입력및 저장'
        onClose={handleRegistDialogClose}
        draggable={true}
        actions={[
          { key: 1, label: '취소', callback: handleRegistDialogClose },
          { key: 2, label: '저장', callback: handleClickCreateAgency }
        ]}
        content={<AgencyInputForm onFormChange={handleChangeInput}
          inputData={agencyInputData}
        />}
      />

      <AntDialog open={agencyModifyOpen}
        title='세무사 정보 수정'
        comment='세무사 정보를 수정하는 화면'
        onClose={handleModifyDialogClose}
        draggable={true}
        actions={[
          { key: 1, label: '취소', callback: handleModifyDialogClose },
          { key: 2, label: '저장', callback: handleClickEditAgency }
        ]}
        content={<AgencyInputForm onFormChange={handleChangeInput}
          inputData={agencyInputData}
        />}
      />

      <AntDialog open={agencyUserRegistOpen}
        title={hasAdmin ? '세무사 관리자 정보 수정' : '세무사 관리자 등록'}
        comment={hasAdmin ? '세무사 관리자 정보를 수정하는 화면' : '새로운 세무사 관리자 정보를 등록하기 위한 정보 입력및 저장'}
        onClose={handleUserRegistDialogClose}
        draggable={true}
        actions={[
          { key: (hasAdmin) ? -1 : 0, label: '삭제', callback: handleClickDeleteAgencyUser },
          { key: 1, label: '취소', callback: handleUserRegistDialogClose },
          { key: 2, label: '저장', callback: hasAdmin ? handleClickEditAgencyUser : handleClickCreateAgencyUser }
        ]}
        content={<AgencyUserInputForm onFormChange={handleChangeInput}
          inputData={agencyInputData}
        />}
      />
    </div>
  );
};

export default AgencyMain;
