import { requestPostWithJsonAPI } from 'common/services/AntsRequester';

export const setEmpGradesWithFunc = async (f, param) => {
    let listData = [{ name: '전체', value: '-' }];
    f(await requestPostWithJsonAPI('getGradeCodeList', param).then((response) => {
        if (response && response.status && response.status === 200) {
            if (response.data && response.data.status && response.data.status.result === 'success') {
                if (response.data.listData !== null) {
                    var data = response.data.listData.map(e => ({ name: e.name, value: e.value  }));
                    return (listData.concat(data));
                }
            }
        }
        return listData;
    }));
};

export const setDeptsWithFunc = async (f, param) => {
    let listData = [{ name: '전체', value: '-' }];
    f(await requestPostWithJsonAPI('getDeptCodeList', param).then((response) => {
        if (response && response.status && response.status === 200) {
            if (response.data && response.data.status && response.data.status.result === 'success') {
                if (response.data.listData !== null) {
                    var data = response.data.listData.map(e => ({ name: e.name, value: e.value }));
                    return (listData.concat(data));
                }
            }
        }
        return listData;
    }));
};

export const setCompanyBankWithFunc = async (f, param) => {
    let listData = [{ name: '전체', value: '-' }];
    f(await requestPostWithJsonAPI('getCompanyBankCodeList', param).then((response) => {
        if (response && response.status && response.status === 200) {
            if (response.data && response.data.status && response.data.status.result === 'success') {
                if (response.data.listData !== null) {
                    var data = response.data.listData.map(e => ({ name: e.name, value: e.value }));
                    return (listData.concat(data));
                }
            }
        }
        return listData;
    }));
};

