import axios from 'axios';
import qs from 'qs';
import { authHeader, authTempHeader } from 'common/services/AuthHeader';

export const RC_DOMAIN = process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_WEBCONTEXT_BASEURL}/` : `http://localhost:3030${process.env.REACT_APP_WEBCONTEXT_BASEURL}/`;
// export const RC_DOMAIN = process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_WEBCONTEXT_BASEURL}/` : `http://localhost:8082${process.env.REACT_APP_WEBCONTEXT_BASEURL}/`;

export function rcRequestPromise(url, param) {
  return new Promise((resolve, reject) => {
    axios({
      method: param.method ? param.method : 'post',
      url: RC_DOMAIN + url,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      transformRequest: [
        function(data, headers) {
          return qs.stringify(data, { arrayFormat: 'brackets' });
        },
      ],
      data: param,
      withCredentials: false,
    })
      .then(function(response) {
        if (response.data) {
          if (
            response.data.status &&
            response.data.status.result === 'success' &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            resolve(response.data);
          } else {
            resolve(response.data);
          }
        } else {
          reject(response);
        }
      })
      .catch(function(error) {});
  });
}

export function requestGetAPI(url, param, header) {
  return axios({
    method: 'get',
    url: RC_DOMAIN + url,
    responseType: 'blob',
  });
}

export function requestPostAPI(url, param, temp) {
  const header =
    temp !== undefined && temp === 'TEMPLOGIN'
      ? authTempHeader({
          'Content-Type': 'application/x-www-form-urlencoded',
        })
      : authHeader({
          'Content-Type': 'application/x-www-form-urlencoded',
        });

  return axios({
    method: 'post',
    url: RC_DOMAIN + url,
    headers: header,
    transformRequest: [
      function(data) {
        return qs.stringify(data, { arrayFormat: 'brackets' });
      },
    ],
    data: param,
    withCredentials: false,
  });
}

//  주 사용 함수.
export function requestPostWithJsonAPI(url, param) {
  return axios({
    method: 'post',
    url: RC_DOMAIN + url,
    headers: authHeader({
      'Content-Type': 'application/json',
    }),
    dataType: 'json',
    data: param,
  });
}

// multipartform
// export function requestMultipartFormAPI1(url, param, headers) {
//   return axios({
//     method: 'post',
//     url: RC_DOMAIN + url,
//     headers: { 'Content-Type': 'multipart/form-data' },
//     transformRequest: [
//       function(data, headers) {
//         // const formData = new FormData();
//         // formData.append('wallpaperFile','file');
//         // formData.append('wallpaperNm', 'FILENAME_777');

//         let formData = new FormData();
//         for (let key in data) {
//           formData.append(key, data[key]);
//         }

//         return formData;
//       }
//     ],
//     data: param,
//     withCredentials: false
//   });
// }

// multipartform
export function requestMultipartFormAPI(url, param) {
  return axios({
    method: 'post',
    url: RC_DOMAIN + url,
    headers: authHeader({ 'Content-Type': 'multipart/form-data' }),
    transformRequest: [
      function(data) {
        return data;
      },
    ],
    data: param,
    withCredentials: false,
  });
}

export function requestPostDownloadAPI(url, param) {
  return axios({
    method: 'post',
    url: RC_DOMAIN + url,
    responseType: 'blob',
    headers: authHeader({
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
    transformRequest: [
      function(data) {
        return qs.stringify(data, { arrayFormat: 'brackets' });
      },
    ],
    traditional : true,
    data: param,
    withCredentials: false,
  });
}

export function requestFileDownload(fileNo, fileName) {
  axios({
    method: 'post',
    url: RC_DOMAIN + 'fileDownload',
    responseType: 'blob',
    headers: authHeader({
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
    transformRequest: [
      function(data) {
        return qs.stringify(data, { arrayFormat: 'brackets' });
      },
    ],
    data: { fileNo: fileNo },
    withCredentials: false,
  })
    .then(response => {
      if (response && response.data) {
        var FileSaver = require('file-saver');
        FileSaver.saveAs(response.data, fileName);
      }
    })
    .catch(error => {
      console.log('1 error : ', error);
    });
}
