import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { MenuHeader } from 'layouts/main/blocks/menu';
import { OutsToolbar, OutsList, OutsDetail } from '..';
import { OutsInputForm } from '..';

import { AntDialog } from 'components/dialog';
import { requestPostWithJsonAPI } from 'common/services/AntsRequester';
import { createParamObject } from 'common/utils/DataUtils';
import UserAuthContext from 'common/context/UserAuthContext';

import useAlert from 'common/hooks/useAlert';
import { setEmpGradesWithFunc, setCompanyBankWithFunc } from 'views/employee/EmpUtils';

// Style Class
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#efefef',
  },
}));
// Row Count
const rowCount = 15;
const outsVo = {
  companyCd: '',
  outsId: '',
  outsNo: '',
  outsNm: '',
  gradeCd: '',
  email: '',
  addrStr: '',
  phone: '',
  residentNo: '',
  goyongNo: '',
  sanjaeNo: '',
  bankCd: 'B020',
  accountNo: '',
};

const OutsMain = props => {
  const classes = useStyles();
  const { showAlert } = useAlert();
  const { userAuthInfo } = useContext(UserAuthContext);

  const [tableData, setTableData] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [totalCnt, setTotalCnt] = useState(0);

  // option
  const [keyword, setKeyword] = useState('');
  const [selectedGradeCd, setSelectedGradeCd] = useState('-');
  // option datas
  const [grades, setGrades] = useState([]);
  const [banks, setBanks] = useState([]);
  // regist dialog
  const [outsRegistOpen, setOutsRegistOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  // detail
  const [outsDetailData, setOutsDetailData] = useState(undefined);
  const handleClickListRow = (data) => {
    setOutsDetailData(data);
  };
  // regist/modify input
  const [outsInputData, setOutsInputData] = useState({
  });

  // select list
  const handleClickSearch = (pageNo, grade, dept) => {
    setOutsDetailData(undefined);
    requestPostWithJsonAPI('getOutsourceList', {
      page: pageNo,
      keyword: keyword,
      grade_cd: (grade) ? grade : selectedGradeCd,
      page_row_num: rowCount,
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          setCurPage(pageNo);
          setTableData({ data: response.data.listData });
          setTotalCnt(Number(response.data.listTotal));
        }
      }
    });
  };

  useEffect(() => {
    setEmpGradesWithFunc(setGrades, {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
    });
    setCompanyBankWithFunc(setBanks, {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
    });

    handleClickSearch(1);
  }, [userAuthInfo]);

  // 직원 생성
  const handleClickCreateOuts = () => {
    Object.assign(outsInputData, {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
    });
    // console.log('outsInputData :::: ', outsInputData);
    requestPostWithJsonAPI('setOutsourceCreate', createParamObject(outsInputData)).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setOutsRegistOpen(false);
          showAlert('outsdatacreate', `'${outsInputData.outsNm}' 직원가 생성되었습니다.`, 'success', 3000);
        } else {
          // 에러 처리 - 필요시
          // if (response.data.status.resultDept === 'duplicate') {
          //   showAlert('codeduplicate', `코드값이 중복되었습니다.`, 'error', 5000);
          // }
        }
      }
    });
  }

  // 직원 삭제 - API 호출
  const handleClickDeleteOuts = (param) => {
    requestPostWithJsonAPI('setOutsourceDelete', {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
      outs_id: outsDetailData.outsId,
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          showAlert('outsdelete', `'${outsDetailData.outsNm}' 직원정보가 삭제되었습니다.`, 'success', 3000);
        } else {
          showAlert('outsdeleteerror', '직원정보 삭제 작업이 실패 하였습니다.', 'error', 5000);
        }
      }
    });
  }

  // 직원 수정 - API 호출
  const handleClickEditOuts = (param) => {
    Object.assign(outsInputData, {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : ''
    });
    // console.log('edit outsInputData ::: ', outsInputData);
    requestPostWithJsonAPI('setOutsourceEdit', createParamObject(outsInputData)).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setOutsRegistOpen(false);
          showAlert('outsdataedit', `'${outsInputData.outsNm}' 직원정보가 수정되었습니다.`, 'success', 3000);
        } else {
          showAlert('outsediterror', '직원정보 변경 작업이 실패 하였습니다.', 'error', 5000);
        }
      }
    });
  }

  // 툴바에서 파라미터 변경 이벤트 처리
  const handleChangeToolbarOption = (data) => {
    if (data.name === 'keyword') {
      setKeyword(data.value);
      if (data.key === 'Enter') {
        handleClickSearch(1);
      }
    } else if (data.name === 'selectEmpGrade') {
      setSelectedGradeCd(data.value);
      handleClickSearch(1, data.value, undefined);
    }
  }
  const handleClickToolbarButton = (e) => {
    if (e.currentTarget.id === 'outs-toolbar-search') {
      handleClickSearch(1);
    } else if (e.currentTarget.id === 'outs-toolbar-regist') {
      setIsNew(true);
      setOutsInputData(outsVo);
      setOutsRegistOpen(true);
    }
  }

  // 수정/등록에서의 입력 컴포넌트 이벤트 처리
  const handleFormChange = (data) => {
    setOutsInputData(data);
  }

  return (
    <div className={classes.root}>
      <MenuHeader />

      <OutsToolbar grades={grades}
        onChangeParam={handleChangeToolbarOption}
        onClickButton={handleClickToolbarButton} />

      <OutsList
        tableData={tableData}
        totalCnt={totalCnt}
        curPage={curPage}
        rowCount={rowCount}
        onChangePage={handleClickSearch}
        onClickListRow={handleClickListRow}
        selectedRow={outsDetailData} />

      <OutsDetail outsData={outsDetailData}
        onClickModifyOuts={() => {
          setIsNew(false);
          const cloneOutsDetailData = JSON.parse(JSON.stringify(outsDetailData));
          if(cloneOutsDetailData.retireYn === 'N') {
            Object.assign(cloneOutsDetailData, {
              retireDt: (new Date().toISOString().split('T')[0]).toString(),
            });
          }
          setOutsInputData(cloneOutsDetailData);
          setOutsRegistOpen(true);
        }}
        onClickDeleteOuts={handleClickDeleteOuts} />

      <AntDialog open={outsRegistOpen}
        title={isNew ? '외주인력 등록' : '외주인력 정보 수정'}
        comment={isNew ? '새로운 외주인력 정보를 등록합니다.' : '외주인력 정보를 수정합니다.'}
        onClose={() => { setOutsRegistOpen(false); }}
        draggable={true}
        actions={[
          { key: 1, label: '취소', callback: () => { setOutsRegistOpen(false); } },
          { key: 2, label: '저장', callback: isNew ? handleClickCreateOuts : handleClickEditOuts }
        ]}
        content={<OutsInputForm onFormChange={handleFormChange}
          grades={grades} banks={banks} inputData={outsInputData} isNew={isNew}
        />}
      />
    </div>
  );
};

export default OutsMain;
