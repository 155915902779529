import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { Chart } from "react-google-charts";
import ContentPaneTitleBar from 'components/container/ContentPaneTitleBar';

const useStyles = makeStyles(theme => ({
    root: {
        padding: 0,
        boxShadow: 'none',
        border: '1px solid #d0d0d0',
        borderRadius: 0,
    },
    content: {
        boxShadow: 'none',
        backgroundColor: '#ffffff',
        borderRadius: 0,
        padding: 2,
        textAlign: '-webkit-center',
    },
}));

const AntBarChart = (props) => {
    const { className, title, chartSize, chartData, ...rest } = props;
    const classes = useStyles();

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
            style={{ minWidth: chartSize }}
        >
            <ContentPaneTitleBar title={title}
                buttons={[
                    {
                        name: '', action: 'refresh',
                        func: undefined,
                    },
                ]}
                style={{minWidth: 30}}
            />
            <div className={classes.content} >
                <Chart
                    chartType="Bar"
                    loader={<div>Loading Chart</div>}
                    data={chartData}
                    options={{
                        // Material design options
                        chart: {
                            subtitle: 'Sales, Expenses, and Profit: 2014-2017',
                        },
                        width: '95%',
                        height: chartSize,
                        backgroundColor: '#ffffff',
                        colors: ["#485696","#f24c00","#f9c784","#fc7a1e","#524632","#49392c"],
                        chartArea: { width: '90%', height: '90%' },
                    }}
                />
            </div>
        </div>
    );
};

/* Array */
// ["fe5d26","f2c078","faedca","c1dbb3","7ebc89","c94277","628b48"]


export default AntBarChart;