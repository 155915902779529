import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Avatar, Paper } from '@material-ui/core';
import { faAlignJustify, faCalculator } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles(theme => ({
  root: {
    width: 180,
    height: 200,
    padding: 10,
    margin: 8,
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  data: {
    fontWeight: 700,
    textAlign: '-webkit-right',
    paddingRight: 12,
    marginTop: 8,
  },
  avatar: {
    backgroundColor: '#f2e418',
    color: '#FFFFFF',
    height: 42,
    width: 42
  },
}));

const CompanyStatusPane = props => {
  const { data, height } = props;

  const classes = useStyles();

  return (
    <Paper className={classes.root} elevation={3} style={(data.isWa === '1') ? { borderTop: '18px solid #485796' } : { borderTop: '18px solid #fe5e25' }}>
      <div style={{ height: 60 }}>
        <Typography style={{ fontSize: 20, fontWeight: 800 }}>{data.name}</Typography>
      </div>
      <hr />
      <div style={{ marginTop: 4, display: 'flex', justifyContent: 'space-between' }}>
        <Typography style={{ fontSize: 18, fontWeight: 800, marginBottom: 4, color: 'cadetblue' }}>직원수</Typography>
        <Typography style={{ fontSize: 18, fontWeight: 800 }}>{data.num} 명</Typography>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography style={{ fontSize: 18, fontWeight: 800, marginBottom: 4, color: 'cadetblue' }}>급여일</Typography>
        <Typography style={{ fontSize: 18, fontWeight: 800 }}>{data.pd} 일</Typography>
      </div>
      <hr />
      <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 22 }}>
        <FontAwesomeIcon icon={faAlignJustify} style={{ fontSize: 22, marginRight: 0, marginBottom: 0, color: 'lightslategray' }} />
        <FontAwesomeIcon icon={faCalculator} style={{ fontSize: 22, marginRight: 16, marginBottom: 0, color: 'lightslategray' }} />
      </div>
    </Paper>
  );
};

CompanyStatusPane.propTypes = {
  className: PropTypes.string
};

export default CompanyStatusPane;
