import React, { useState, useEffect } from 'react';
import { sha256 } from 'js-sha256';

import { requestPostAPI } from 'common/services/AntsRequester';

import { makeStyles } from '@material-ui/core/styles';
import AntsomeLogo from 'assets/images/antsome_3ds.png';
import AuthBg from 'assets/images/flower-5587380_1920.png';

import { Button, TextField, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import { changePassword } from 'common/services/AuthService';
import useInform from 'common/hooks/useInform';

const useStyles = makeStyles(theme => ({
  loginPane: {
    padding: theme.spacing(2),
    margin: 'auto'
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: '400px'
  },
  logoPane: {
    align: 'center',
    margin: '30px 0px'
  },
  msgPane: {
    align: 'center',
    color: 'red',
    fontWeight: 'bold',
    marginBottom: '10px'
  },
  btn: {
    marginTop: 20,
    marginBottom: 10,
    backgroundColor: '#758601'
  },
  logintext: {
    color: theme.palette.common.white,
    fontWeight: 'bold'
  },
  background: {
    backgroundColor: theme.palette.neutral,
    backgroundImage: `url(${AuthBg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    textAlign: 'center',
    height: '100%',
    display: 'flex',
  },
  typography: {
    lineHeight: '40px',
    fontSize: '13px',
    fontWeight: 'bold',
    color: 'grey'
  }
}));

export function InitPassword(props) {
  const { history, location } = props;
  const classes = useStyles();
  const { showInform } = useInform();

  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [passwordFirst, setPasswordFirst] = useState('');
  const [passwordSecond, setPasswordSecond] = useState('');

  const [isShowMsg, setIsShowMsg] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  
  useEffect(() => {
    const authInform = JSON.parse(sessionStorage.getItem('temp_user'));
    setUserId((authInform) ? authInform.userId : '');
  }, []);

  const handleClickLoginBtn = e => {
    e.preventDefault();

    // check new password is same;
    if(passwordFirst !== passwordSecond) {
      setErrorMsg("신규 비밀번호가 일치하지 않습니다.");
      setIsShowMsg(true);
      return;
    }

    requestPostAPI('api/auth/changePassword', {
      userId: userId,
      password: sha256(password),
      newPassword: sha256(passwordFirst)
    }, 'TEMPLOGIN').then(
      (response) => {
        if (response && response.data) {
          const reData = response.data
          if(reData.status && reData.status.result === 'error') {
            setErrorMsg(reData.status.message);
            setIsShowMsg(true);
            return;
          } else {
            if(changePassword('success')) {
              showInform('비밀번호수정', '비밀번호가 수정되었습니다.', 'A', () => {
                const { from } = location.state || { from: { pathname: '/' } };
                history.push(from);
                // window.location.reload();
              });
            }
          }
        }
      }
    ).catch(error => {
      console.log('1 error : ', error);
    });
  };

  return (
    <div className={classes.background}>
      <div className={classes.loginPane}>
        <Paper className={classes.paper}>
          <div align="center" className={classes.logoPane}>
            <img
              alt="Logo"
              src={AntsomeLogo}
              className={classes.logoStyle}
            />
          </div>
          { isShowMsg &&
            <div className={classes.msgPane}>{errorMsg}</div>
          }
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <AccountCircleRoundedIcon style={{ color: "#758601" }} fontSize="large" />
                </Grid>
                <Grid item xs={10}>
                  <TextField value={userId} fullWidth disabled={false}
                    variant="outlined" placeholder="ID" size="small"
                  />
                </Grid>
                <Grid item xs={2}>
                  <LockRoundedIcon style={{ color: "#758601" }} fontSize="large" />
                </Grid>
                <Grid item xs={10}>
                  <TextField value={password} type="password" fullWidth
                    variant="outlined" size="small" placeholder="이전 비밀번호"
                    onChange={e => {
                      setPassword(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <LockRoundedIcon style={{ color: "#ee9b01" }} fontSize="large" />
                </Grid>
                <Grid item xs={10}>
                  <TextField value={passwordFirst} type="password" fullWidth
                    variant="outlined" size="small" placeholder="신규 비밀번호"
                    onChange={e => {
                      setPasswordFirst(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <LockRoundedIcon style={{ color: "#ee9b01" }} fontSize="large" />
                </Grid>
                <Grid item xs={10}>
                  <TextField value={passwordSecond} type="password" fullWidth
                    variant="outlined" size="small" placeholder="신규 비밀번호 확인"
                    onChange={e => {
                      setPasswordSecond(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={4} ></Grid>
                <Grid item xs={4} >
                  <Button
                    variant="contained"
                    size="large"
                    type="submit"
                    className={classes.btn}
                    onClick={handleClickLoginBtn}>
                    <Typography className={classes.logintext}>
                      저장
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={4} ></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
};
