import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';

import AntsomeLogo from 'assets/images/antsome_3ds.png';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
    height: '60px',
    background: 'linear-gradient(45deg, #FFFFFF 50%, #000000 90%)'
  },
  logoStyle: {
    paddingTop: '15px',
    paddingLeft: '15px',
    height: '65px'
  }
}));

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
    >
      <Toolbar style={{minHeight: '60px'}}>
        <RouterLink to="/">
          <img
            alt="Logo"
            src={AntsomeLogo}
            className={classes.logoStyle}
          />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
