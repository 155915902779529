import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
// import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

// import { DatePicker } from '@material-ui/pickers';
import { AntPieChart, AntPieListChart } from './components';
import { AntBarChart, AntStackedBarChart, AntLineChart } from './components';
import { IconTitleDataBackPane, IconTitleDataPane } from './components';

import AssignmentIcon from '@material-ui/icons/Assignment';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const AdminDashboard = () => {
  const classes = useStyles();
  // const handleDateChange = (param) => { return param; };

  const [totalProjectCount] = useState({
    title: '전체',
    count: '2314',
    icon: <AssignmentIcon />,
    titleSize: 16,
    countSize: 32,
  });

  const [readyProjectCount] = useState({
    title: '대기중',
    count: '13',
    icon: <AssignmentIcon />,
    titleSize: 16,
    countSize: 32,
  });

  const [runningProjectCount] = useState({
    title: '진행중',
    count: '324',
    icon: <AssignmentIcon />,
    titleSize: 16,
    countSize: 32,
  });

  const [completeProjectCount] = useState({
    title: '완료',
    count: '1244',
    icon: <AssignmentIcon />,
    titleSize: 16,
    countSize: 32,
  });

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <IconTitleDataPane data={totalProjectCount} height={80} />
        </Grid>
        <Grid item xs={3}>
          <IconTitleDataBackPane data={runningProjectCount} height={80} />
        </Grid>
        <Grid item xs={3}>
          <IconTitleDataPane data={readyProjectCount} height={80} />
        </Grid>
        <Grid item xs={3}>
          <IconTitleDataBackPane data={completeProjectCount} height={80} />
        </Grid>
        <Grid item xs={12} sm={12} lg={8}>
          <AntLineChart title='Antsome Line Chart' chartSize={300} chartData={[
            ['x', 'item1', 'item2'],
            [0, 0, 0],
            [1, 10, 5],
            [2, 23, 15],
            [3, 17, 9],
            [4, 18, 10],
            [5, 9, 5],
            [6, 11, 3],
            [7, 27, 19],
          ]} />
        </Grid>
        <Grid item xs={6} lg={4}>
          <AntPieChart title='Antsome Pie Chart' chartSize={300} chartData={[
            ['name', 'value'],
            ['Java', 11],
            ['Python', 32],
            ['C#', 14],
            ['Node.js', 8],
            ['Etc', 27],
          ]} />
        </Grid>
        <Grid item xs={6} lg={4}>
          <AntBarChart title='Antsome Bar Chart' chartSize={300} chartData={[
            ['Year', 'Sales', 'Expenses', 'Profit'],
            ['2014', 1000, 400, 200],
            ['2015', 1170, 460, 250],
            ['2016', 660, 1120, 300],
            ['2017', 1030, 540, 350],
          ]} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <AntStackedBarChart title='Antsome StackedBar Chart' chartSize={300} chartData={[
            ['City', '2010 Population', '2000 Population'],
            ['New York City, NY', 8175000, 8008000],
            ['Los Angeles, CA', 3792000, 3694000],
            ['Chicago, IL', 2695000, 2896000],
            ['Houston, TX', 2099000, 1953000],
            ['Philadelphia, PA', 1526000, 1517000],
          ]} />
        </Grid>
        <Grid item xs={6} lg={4}>
          <AntPieListChart title='Antsome PieList Chart' chartSize={184} chartData={[
            ['name', 'value', 'temp'],
            ['standard', 223, '#fe5d26'],
            ['included', 82, '#f2c078'],
            ['subscription', 34, '#faedca'],
            ['advance', 22, '#c1dbb3'],
            ['billing', 53, '#7ebc89'],
          ]} />
        </Grid>
        {/* <Grid item xs={12} sm={6} lg={4}>
          <Paper style={{ textAlign: 'center', paddingTop: 10 }}>
            <DatePicker
              autoOk
              inputVariant="outlined"
              inputProps={{
                style: {
                  padding: 8,
                  textAlign: 'center',
                },
              }}
              format="yyyy년 MM월 dd일"
              variant="inline"
              value={new Date()}
              onChange={handleDateChange}
            />
          </Paper>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default AdminDashboard;
