import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    minWidth: 780,
  },
  card: {
    boxShadow: 'none',
    border: '1px solid #d0d0d0',
    borderRadius: 0,
  },
  actions: {
    justifyContent: 'flex-end',
  },
  selectedRow: {
    cursor: 'pointer',
    backgroundColor: theme.palette.antTable.selectedRowBack,
  },
  normalRow: {
    cursor: 'pointer',
  }
}));

const cellInfo = [
  ['시작일', 'startDt', 'center', 80],
  ['종료일', 'endDt', 'center', 80],
  ['완료일', 'finishDt', 'center', 80],
  ['비고', 'memo', 'left'],
];

const DispPersonList = (props) => {
  const { className, tableData, ...rest } = props;
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
    <Card className={classes.card}>
      <CardContent style={{padding: 10, minHeight: 210}}>
           <Table>
            <TableHead>
              <TableRow>
                {/* 테이블헤더 그리는 loop */}
                {cellInfo.map((column, index) => {
                  return (
                    <TableCell key={index}  style={ column[3] ? { textAlign: 'center', fontSzie: 10, maxWidth: `${column[3]}px` , width: `${column[3]}px` } : { textAlign: 'center', fontSzie: 10 }}>
                      {column[0]}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            {tableData && (
              <TableBody>
                {/* 테이블 행그리는 loop */}
                {tableData.map((row, index) => (
                  <TableRow
                    hover
                    key={index}
                    style={{cursor: 'pointer'}}
                    className={classes.normalRow}
                  >
                    {/* 각 행에 cell을 채울 loop */}
                    {cellInfo.map((cell, index) => {
                        return (
                          <TableCell key={index} align={cell[2]}>
                            {row[cell[1]]}
                          </TableCell>
                        );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          {tableData === null || tableData.length < 1 && (
            <div style={{border: '1px solid #daefd1', textAlign: 'center', height:100, paddingTop: 40}}>데이터가 없습니다.</div>
          )}
      </CardContent>
    </Card>
    </div>
  );
};

export default DispPersonList;
