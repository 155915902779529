import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Paper, Input } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid #c0c0c0',
    borderRadius: 0,
    alignItems: 'center',
    display: 'flex',
    height: 30,
    paddingRight: 4,
    paddingLeft: 4,
  },
  icon: {
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  }
}));

const SearchInput = props => {
  const { className, id, onChange, style, ...rest } = props;
  const classes = useStyles();

  return (
    <Paper {...rest} className={clsx(classes.root, className)} style={style}>
      <Input
        id={id}
        {...rest}
        className={classes.input}
        disableUnderline
        onChange={onChange}
      />
      <SearchIcon className={classes.icon} />
    </Paper>
  );
};

SearchInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object
};

export default SearchInput;
