import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    minWidth: 780,
  },
  card: {
    boxShadow: 'none',
    border: '1px solid #d0d0d0',
    borderRadius: 0,
  },
  actions: {
    justifyContent: 'flex-end',
  },
  selectedRow: {
    cursor: 'pointer',
    backgroundColor: theme.palette.antTable.selectedRowBack,
  },
  normalRow: {
    cursor: 'pointer',
  }
}));

const cellInfo = [
  ['-', '{[check]}', 'center'],
  ['직원번호', 'empNo', 'center'],
  ['이름', 'empNm', 'center'],
  ['직급', 'gradeStr', 'center'],
  ['부서', 'deptStr', 'center'],
  ['이메일', 'email', 'left'],
  ['기본금', 'base', 'center'],
  ['상여', 'bonus', 'center'],
  ['수정일', 'modDt', 'center'],
  ['직원코드', 'empId', 'center'],
];

const PayTaxList = (props) => {
  const { className, tableData, setTableData, totalCnt, onChangePage, onClickListRow, selectedRow, curPage, rowCount, selectedClient, ...rest } = props;
  const classes = useStyles();
  const [allChecked, setAllChecked] = useState(false);

  const handlePageChange = (event, page) => {
    onChangePage(page);
  };

  const changeRowChecked = (isAll, paramIndex, checked) => {
    const newListData = tableData.data.map((e, index) => {
      if (isAll || index === paramIndex) {
        e['checked'] = checked;
      }
      return e;
    });
    setTableData({ data: newListData });
    // check is all
    const falseIndex = newListData.findIndex(e => e['checked'] === undefined || e['checked'] === false);
    setAllChecked((falseIndex < 0));
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Card className={classes.card}>
        <CardContent style={{ padding: 10 }}>
          <div style={{ fontSize: 12, fontWeight: 500 }}>
            {'전체 ' + totalCnt + ' 건'}
          </div>
          <Table>
            <TableHead>
              <TableRow>
                {/* 테이블헤더 그리는 loop */}
                {cellInfo.map((column, index) => {
                  return (
                    <TableCell key={index} style={{ textAlign: 'center', fontSize: 10 }}>
                      {(column[1] === '{[check]}') ?
                        <input type='checkbox' checked={allChecked}
                          onChange={(e) => { changeRowChecked(true, 0, e.currentTarget.checked); }} /> :
                        column[0]}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            {tableData && tableData.data && (
              <TableBody>
                {/* 테이블 행그리는 loop */}
                {tableData.data.map((row, rowIndex) => (
                  <TableRow
                    hover
                    key={rowIndex}
                    style={{ cursor: 'pointer' }}
                    className={(selectedRow && selectedRow['empNo'] === row['empNo']) ? classes.selectedRow : classes.normalRow}
                    onClick={() => {
                      onClickListRow(row);
                    }}
                  >
                    {/* 각 행에 cell을 채울 loop */}
                    {cellInfo.map((cell, index) => {
                      return (
                        <TableCell key={index} align={cell[2]}>
                          {(cell[1] === 'base' || cell[1] === 'bonus') ?
                            Number(row[cell[1]]).toLocaleString() : ((cell[1] === '{[check]}') ?
                              <input type='checkbox' checked={(row['checked'] === undefined) ? false : row['checked']}
                                onChange={(e) => { changeRowChecked(false, rowIndex, e.currentTarget.checked); }} /> :
                              row[cell[1]])}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          {selectedClient === '-' && (
            <div style={{ border: '1px solid #daefd1', textAlign: 'center', height: 100, paddingTop: 40 }}>'고객사'를 선택하세요.</div>
          )}
          {selectedClient !== '-' && totalCnt < 1 && (
            <div style={{ border: '1px solid #daefd1', textAlign: 'center', height: 100, paddingTop: 40 }}>데이터가 없습니다.</div>
          )}
        </CardContent>
        <CardActions className={classes.actions}>
          <Pagination
            count={Math.ceil(totalCnt / rowCount)}
            page={totalCnt < rowCount ? 1 : curPage}
            onChange={handlePageChange}
            variant="outlined"
          />
        </CardActions>
      </Card>
    </div>
  );
};

export default PayTaxList;
