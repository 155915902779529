import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';

import CropSquare from '@material-ui/icons/CropSquare';

const useStyles = makeStyles(theme => ({
  menuHeader: {
    borderBottom: "2px solid #797979",
    marginTop: "10px",
    marginBottom: "10px"
  }
}));

const MenuInPopup = (props) => {
  const { menuName, onClose } = props;
  const classes = useStyles();

  return (
    <Grid className={classes.menuHeader}
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
          <CropSquare style={{ color: '#797979', paddingTop: '7px', marginBottom: '-3px', marginRight: '3px' }} />
          {menuName}
        </Typography>
      </Grid>
      <Grid item>
        <Box><IconButton aria-label="close" style={{ padding: 0 }}
          onClick={onClose}
        >
          <CancelIcon />
        </IconButton></Box>
      </Grid>
    </Grid>
  );
};

MenuInPopup.propTypes = {
  menuName: PropTypes.string
};

export default MenuInPopup;
