import React from 'react';

import { Grid, TextField } from '@material-ui/core';

const AdminInputForm = (props) => {
  const { onFormChange, inputData, isNew } = props;
  // const isEdit = inputData.isEdit; // (inputData && inputData.adminList && inputData.adminList.length > 0 && inputData.adminId !== '') ? true : false;
  // console.log('inputData --> ', inputData);
  // console.log('isNew --> ', isNew);
  return (
    <div style={{ width: '100%', paddingTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 30, }}>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <TextField label="아이디" fullWidth
            disabled={!isNew}
            value={inputData.adminId}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({...inputData, 'adminId': e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField label="비밀번호" fullWidth
            value={inputData.password}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({...inputData, 'password': e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField label="이름" fullWidth
            // value={inputData.adminNm ? inputData.adminNm : ''}
            value={inputData.adminNm}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({...inputData, 'adminNm': e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField label="전화번호" fullWidth
            value={inputData.telNo}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({...inputData, 'telNo': e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField label="이메일" fullWidth
            value={inputData.email}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({...inputData, 'email': e.target.value });
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminInputForm;
