import React, { useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import { Box, Button } from '@material-ui/core';
import ToggleIcon from '@material-ui/icons/Menu';

import { getMenus } from 'common/menu/MenuData';
import MenuTree from './MenuTree';
import ThinMenuTree from './ThinMenuTree';
import UserAuthContext from 'common/context/UserAuthContext';

import PaysomeLogo from 'assets/images/paysome_logo.png';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'table-cell',
    verticalAlign: 'top',
    background: '#303641',
    // background: '#252a42',
    // background: 'linear-gradient(0deg, #2e2712 89%, #efefef 95%)',
    width: '204px',
    position: 'relative',
    zIndex: '100',
    boxSizing: 'border-box',
  },
  smallRoot: {
    width: '65px',
  },
  topPane: {
    // padding: '16px 25px 44px 25px',
    padding: '16px 16px 44px 16px',
    paddingTop: '34px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  thinTopPane: {
    padding: '50px 16px 16px 16px',
    marginBottom: 27
  },
  logoStyle: {
    width: 132,
    alignSelf: 'center',
    marginTop: -6,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  toggleBtn: {
    alignSelf: 'flex-end',
    minWidth: 6,
    padding: 2,
    marginBottom: 20,
    borderRadius: '4px',
    borderColor: '#696969',
  },
  toggleIcon: {
    color: '#8b8b8b',
  },
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;
  const classes = useStyles();

  const [collased, setCollased] = useState(false);
  const { userAuthInfo } = useContext(UserAuthContext);

  const pages = getMenus(userAuthInfo.role);

  const toggleMenu = () => {
    setCollased(!collased);
  }

  return (
    <div {...rest} className={collased ? clsx(classes.root, classes.smallRoot) : clsx(classes.root)}>
      <div className={collased ? clsx(classes.topPane, classes.thinTopPane) : clsx(classes.topPane)}>
        {!collased &&
          <Box>
            <RouterLink to="/">
              <img className={classes.logoStyle} alt="Paysome" src={PaysomeLogo} />
            </RouterLink>
          </Box>
        }
        <Button variant='outlined' className={classes.toggleBtn} onClick={toggleMenu} >
          <ToggleIcon className={classes.toggleIcon} />
        </Button>
      </div>
      {collased &&
        <ThinMenuTree pages={pages} />
      }
      {!collased &&
        <MenuTree pages={pages} />
      }
    </div>
  );
};

export default Sidebar;
