import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { Grid } from '@material-ui/core';
import ContentPaneTitleBar from 'components/container/ContentPaneTitleBar';
import { LabelCard, GroupLabel } from 'components/card';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    minWidth: 780,
  },
  main: {
    boxShadow: 'none',
    border: '1px solid #d0d0d0',
    borderRadius: 0,
  },
  content: {
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    borderRadius: 0,
    padding: 10,
  },
}));

const priceStyle = {
  textAlign: 'right',
  width: '100%',
  paddingRight: '20px',
}

const PayTaxDetail = (props) => {
  const { className, empPayTaxData, onClickModifyPayTax, onClickModifySheetPayTax, ...rest } = props;
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {(empPayTaxData !== undefined) &&
        <div className={classes.main}>
          <ContentPaneTitleBar title='고객사 직원 상세정보'
            style={{ backgroundColor: '#777777' }}
            buttons={[{
              name: '수정', action: 'edit',
              func: onClickModifyPayTax,
            }, {
              name: '수정(Sheet)', action: 'edit',
              func: onClickModifySheetPayTax,
            }]}
          />
          <div className={classes.content} >
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="직원 번호" labelWidth={120} value={empPayTaxData.empNo} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="직원 이름" labelWidth={120} value={empPayTaxData.empNm} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="직급" labelWidth={120} value={empPayTaxData.gradeStr} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="전화번호" labelWidth={120} value={empPayTaxData.phone} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="부서" labelWidth={120} value={empPayTaxData.deptStr} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="이메일" labelWidth={120} value={empPayTaxData.email} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="주민등록번호" labelWidth={120} value={empPayTaxData.residentNo} />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="수정일(작업자)" labelWidth={120} value={(empPayTaxData.modUserId && empPayTaxData.modUserId !== '') ? empPayTaxData.modDt + ' (' + empPayTaxData.modUserId + ')' : '-'} />
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12} style={{ paddingBottom: 0 }}><GroupLabel label="기 본 급 여 및 제 수 당" labelWidth={140} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="기본급" labelWidth={120} value={Number(empPayTaxData.base).toLocaleString()} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="상여" labelWidth={120} value={Number(empPayTaxData.bonus).toLocaleString()} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="식대보조금" labelWidth={120} value={Number(empPayTaxData.mealSubs).toLocaleString()} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="자가운전보조금" labelWidth={120} value={Number(empPayTaxData.driveSubs).toLocaleString()} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="연장근로수당" labelWidth={120} value={Number(empPayTaxData.overworkBnf).toLocaleString()} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="야간근로수당" labelWidth={120} value={Number(empPayTaxData.nightBnf).toLocaleString()} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="직책수당" labelWidth={120} value={Number(empPayTaxData.gradeBnf).toLocaleString()} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="파견수당" labelWidth={120} value={Number(empPayTaxData.dispatchBnf).toLocaleString()} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12} style={{ paddingBottom: 0 }}><GroupLabel label="공 제 및 차 인 지 급 액" labelWidth={140} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="국민연금" labelWidth={120} value={Number(empPayTaxData.nationPention).toLocaleString()} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="건강보험" labelWidth={120} value={Number(empPayTaxData.healthInsr).toLocaleString()} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="산재보험" labelWidth={120} value={Number(empPayTaxData.accidInsr).toLocaleString()} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="고용보험" labelWidth={120} value={Number(empPayTaxData.empInsr).toLocaleString()} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="장기요양" labelWidth={120} value={Number(empPayTaxData.longRecu).toLocaleString()} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="소득세" labelWidth={120} value={Number(empPayTaxData.incomeTax).toLocaleString()} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="주민세" labelWidth={120} value={Number(empPayTaxData.residentTax).toLocaleString()} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="건강보험 연말정산" labelWidth={120} value={Number(empPayTaxData.yecHealthInsr).toLocaleString()} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="장기요양보험 연말정산" labelWidth={120} value={Number(empPayTaxData.yecLongRecu).toLocaleString()} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="고용보험 연말정산" labelWidth={120} value={Number(empPayTaxData.yecEmpInsr).toLocaleString()} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12} sm={6} md={4}><LabelCard label="환급이자" labelWidth={120} value={Number(empPayTaxData.refundIntr).toLocaleString()} valueStyle={priceStyle} /></Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </div>
        </div>
      }
    </div>
  );
};

export default PayTaxDetail;
