import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { sha256 } from 'js-sha256';

import { MenuHeader } from 'layouts/main/blocks/menu';
import CompanyToolbar from './CompanyToolbar';
import CompanyList from './CompanyList';
import CompanyDetail from './CompanyDetail';
import CompanyInputForm from './CompanyInputForm';
import CompanyUserInputForm from './CompanyUserInputForm';

import { AntDialog } from 'components/dialog';
import { requestPostWithJsonAPI } from 'common/services/AntsRequester';
import { createParamObject } from 'common/utils/DataUtils';

import useAlert from 'common/hooks/useAlert';
import useInform from 'common/hooks/useInform';

// Style Class
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#efefef',
  },
}));
// Row Count
const rowCount = 15;
const companyVo = {
  companyCd: '',
  companyNm: '',
  companyAddr: '',
  adminId: '',
  adminNm: '',
  password: '',
  ceoNm: '',
  businessNo: '',
  telNo: '',
  email: '',
};

const CompanyMain = props => {
  const classes = useStyles();
  const { showAlert } = useAlert();
  const { showInform } = useInform();

  const [tableData, setTableData] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [totalCnt, setTotalCnt] = useState(0);

  // option
  const [keyword, setKeyword] = useState('');
  // option datas

  // company regist dialog
  const [companyRegistOpen, setCompanyRegistOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);

  // detail
  const [companyDetailData, setCompanyDetailData] = useState(undefined);
  const handleClickListRow = (data) => {
    setCompanyDetailData(data);
  };
  // regist/modify input
  const [companyInputData, setCompanyInputData] = useState(companyVo);
  // company-user regist dialog
  const [companyUserRegistOpen, setCompanyUserRegistOpen] = useState(false);

  // select list
  const handleClickSearch = (pageNo) => {
    setCompanyDetailData(undefined);
    requestPostWithJsonAPI('getCompanyList', {
      page: pageNo,
      keyword: keyword,
      page_row_num: rowCount
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          setCurPage(pageNo);
          setTableData({ data: response.data.listData });
          setTotalCnt(Number(response.data.listTotal));
        }
      }
    });
  };

  useEffect(() => {
    handleClickSearch(1);
  }, []);

  // 회사 생성 - API 호출
  const handleClickCreateCompany = () => {
    // console.log('companyInputData :::: ', companyInputData);
    requestPostWithJsonAPI('setCompanyCreate', createParamObject(companyInputData)).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setCompanyRegistOpen(false);
          showAlert('companydatacreate', `'${companyInputData.companyNm}' 회사정보가 생성되었습니다.`, 'success', 3000);
        }
      }
    });
  }

  // 회사 삭제 - API 호출
  const handleClickDeleteCompany = (param) => {
    requestPostWithJsonAPI('setCompanyDelete', {
      company_cd: companyDetailData.companyCd,
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          showAlert('companydatadelete', `'${companyDetailData.companyNm}' 회사정보가 삭제되었습니다.`, 'success', 3000);
        }
      }
    });
  }

  // 회사 수정 - API 호출
  const handleClickEditCompany = (param) => {
    // console.log('edit companyInputData ::: ', companyInputData);
    requestPostWithJsonAPI('setCompanyEdit', createParamObject(companyInputData)).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setCompanyRegistOpen(false);
          showAlert('companydataedit', `'${companyInputData.companyNm}' 회사정보가 수정되었습니다.`, 'success', 3000);
        }
      }
    });
  }

  // 세무사 관리자 정보 생성/수정 다이얼로그 표시
  const handleClickRegistCompanyAdminUser = () => {
    // 세무사 관리자 정보 생성/수정 다이얼로그 표시
    const newAdminList = (companyDetailData.adminList && companyDetailData.adminList.length > 0) ?
      companyDetailData.adminList : [{ adminId: '', password: '', telNo: '', email: '' }];

    setCompanyInputData({
      ...companyDetailData,
      companyCd: companyDetailData.companyCd,
      adminList: newAdminList,
      isEdit: (newAdminList[0].adminId !== '') ? true : false,
    });

    // 세무사 관리자 정보 생성/수정 다이얼로그 표시
    setCompanyUserRegistOpen(true);
  }

  // 회사 관리자 생성 API 호출
  const handleClickCreateCompanyAdmin = () => {
    requestPostWithJsonAPI('setCompanyAdminCreate', createParamObject({
      company_cd: companyInputData.companyCd,
      admin_id: companyInputData.adminList[0].adminId,
      password: sha256(companyInputData.adminList[0].password),
      admin_nm: companyInputData.adminList[0].adminNm,
      tel_no: companyInputData.adminList[0].telNo,
      email: companyInputData.adminList[0].email,
    })).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setCompanyUserRegistOpen(false);
          showAlert('companyuserdatacreate', `'${companyInputData.adminList[0].adminNm}' 관리자가 생성되었습니다.`, 'success', 3000);
        } else {
          showAlert('companyuserdatacreate-error', `${response.data.status.message}`, 'error', 3000);
        }
      }
    });
  }

  // 회사 관리자 수정 API 호출
  const handleClickEditCompanyAdmin = (param) => {
    requestPostWithJsonAPI('setAdminUserEdit', createParamObject({
      admin_id: companyInputData.adminList[0].adminId,
      password: sha256(companyInputData.adminList[0].password),
      admin_nm: companyInputData.adminList[0].adminNm,
      tel_no: companyInputData.adminList[0].telNo,
      email: companyInputData.adminList[0].email,
    })).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setCompanyUserRegistOpen(false);
          showAlert('companyuserdataedit', `'${companyInputData.adminList[0].adminNm}' 관리자가 수정되었습니다.`, 'success', 3000);
        } else {
          showAlert('companyuserdataedit-error', `${response.data.status.message}`, 'error', 3000);
        }
      }
    });
  }

  // 회사 관리자 삭제 API 호출
  const handleClickDeleteCompanyAdmin = (param) => {
    showInform('회사 관리자정보 삭제', `'${companyInputData.adminList[0].adminId}' 관리자 정보를 삭제하시겠습니까?`, 'CONFIRM', result => {
      if (result === 'Y') {
        requestPostWithJsonAPI('setAdminUserDelete', createParamObject({
          admin_id: companyInputData.adminList[0].adminId,
        })).then((response) => {
          if (response && response.status && response.status === 200) {
            if (response.data && response.data.status && response.data.status.result === 'success') {
              handleClickSearch(1);
              setCompanyUserRegistOpen(false);
              showAlert('companyuserdatadelete', `'${companyInputData.adminList[0].adminNm}' 관리자가 삭제되었습니다.`, 'success', 3000);
            } else {
              showAlert('companyuserdatadelete-error', `${response.data.status.message}`, 'error', 3000);
            }
          }
        });
      } else {
        return;
      }
    });
  }

  // 툴바에서 파라미터 변경 이벤트 처리
  const handleChangeToolbarOption = (data) => {
    if (data.name === 'keyword') {
      setKeyword(data.value);
    } else if (data.name === 'keywordEnter') {
      handleClickSearch(1);
    }
  }
  const handleClickToolbarButton = (e) => {
    if (e.currentTarget.id === 'company-toolbar-search') {
      handleClickSearch(1);
    } else if (e.currentTarget.id === 'company-toolbar-regist') {
      setIsNew(true);
      setCompanyInputData(companyVo);
      setCompanyRegistOpen(true);
    }
  }

  // 수정/등록에서의 입력 컴포넌트 이벤트 처리
  const handleFormChange = (data) => {
    setCompanyInputData(data);
  }

  const hasAdmin = (companyDetailData && companyDetailData.adminList && companyDetailData.adminList.length > 0) ? true : false;

  return (
    <div className={classes.root}>
      <MenuHeader />

      <CompanyToolbar
        onChangeParam={handleChangeToolbarOption}
        onClickButton={handleClickToolbarButton} />

      <CompanyList
        tableData={tableData}
        totalCnt={totalCnt}
        curPage={curPage}
        rowCount={rowCount}
        onChangePage={handleClickSearch}
        onClickListRow={handleClickListRow}
        selectedRow={companyDetailData} />

      <CompanyDetail companyData={companyDetailData}
        onClickModifyCompany={() => {
          setIsNew(false);
          setCompanyInputData({
            ...companyDetailData,
            password: ''
          });
          setCompanyRegistOpen(true);
        }}
        onClickRegistCompanyAdminUser={handleClickRegistCompanyAdminUser}
        onClickDeleteCompany={handleClickDeleteCompany} />

      <AntDialog open={companyRegistOpen}
        title={isNew ? '회사정보 등록' : '회사정보 수정'}
        comment={isNew ? '새로운 회사 정보를 등록합니다.' : '회사 정보를 수정합니다.'}
        onClose={() => { setCompanyRegistOpen(false); }}
        draggable={true}
        actions={[
          { key: 1, label: '취소', callback: () => { setCompanyRegistOpen(false); } },
          { key: 2, label: '저장', callback: isNew ? handleClickCreateCompany : handleClickEditCompany }
        ]}
        content={<CompanyInputForm onFormChange={handleFormChange}
          inputData={companyInputData} isNew={isNew}
        />}
      />

      <AntDialog open={companyUserRegistOpen}
        title={hasAdmin ? '회사 관리자 정보 수정' : '회사 관리자 등록'}
        comment={hasAdmin ? '회사 관리자 정보를 수정하는 화면' : '새로운 회사 관리자 정보를 등록하기 위한 정보 입력및 저장'}
        onClose={() => { setCompanyUserRegistOpen(false); } }
        draggable={true}
        actions={[
          { key: (hasAdmin) ? -1 : 0, label: '삭제', callback: handleClickDeleteCompanyAdmin },
          { key: 1, label: '취소', callback: () => { setCompanyUserRegistOpen(false); } },
          { key: 2, label: '저장', callback: hasAdmin ? handleClickEditCompanyAdmin : handleClickCreateCompanyAdmin }
        ]}
        content={<CompanyUserInputForm onFormChange={handleFormChange}
          inputData={companyInputData}
        />}
      />
    </div>
  );
};

export default CompanyMain;
