import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { MenuHeader } from 'layouts/main/blocks/menu';
import { DispToolbar, DispList, DispDetail, DispTimeline } from '..';
import { DispInputForm } from '..';

import { AntDialog } from 'components/dialog';
import { requestPostWithJsonAPI } from 'common/services/AntsRequester';
import { createParamObject } from 'common/utils/DataUtils';
import UserAuthContext from 'common/context/UserAuthContext';

import useAlert from 'common/hooks/useAlert';
import { setEmpGradesWithFunc, setCompanyBankWithFunc } from 'views/employee/EmpUtils';

// Style Class
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#efefef',
  },
}));
// Row Count
const rowCount = 5;
const dispVo = {
  companyCd: '',
  dispId: '',
  dispNo: '',
  dispNm: '',
  gradeCd: '',
  email: '',
  addrStr: '',
  phone: '',
  residentNo: '',
  goyongNo: '',
  sanjaeNo: '',
  bankCd: 'B020',
  accountNo: '',
};

const DispMain = props => {
  const classes = useStyles();
  const { showAlert } = useAlert();
  const { userAuthInfo } = useContext(UserAuthContext);

  const [tableData, setTableData] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [totalCnt, setTotalCnt] = useState(0);

  // option
  const [keyword, setKeyword] = useState('');
  const [selectedGradeCd, setSelectedGradeCd] = useState('-');
  // option datas
  const [grades, setGrades] = useState([]);
  const [banks, setBanks] = useState([]);
  // regist dialog
  const [dispRegistOpen, setDispRegistOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  // detail
  const [dispDetailData, setDispDetailData] = useState(undefined);
  const handleClickListRow = (data) => {
    setDispDetailData(data);
  };
  // regist/modify input
  const [dispInputData, setDispInputData] = useState({
  });

  // select list
  const handleClickSearch = (pageNo, grade, dept) => {
    setDispDetailData(undefined);
    requestPostWithJsonAPI('getDispatchList', {
      page: pageNo,
      keyword: keyword,
      grade_cd: (grade) ? grade : selectedGradeCd,
      page_row_num: rowCount,
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          setCurPage(pageNo);
          setTableData({ data: response.data.listData });
          setTotalCnt(Number(response.data.listTotal));
        }
      }
    });
  };

  useEffect(() => {
    setEmpGradesWithFunc(setGrades, {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
    });
    setCompanyBankWithFunc(setBanks, {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
    });

    handleClickSearch(1);
  }, [userAuthInfo]);

  // 파견정보 생성
  const handleClickCreateDisp = () => {
    Object.assign(dispInputData, {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
    });
    // console.log('dispInputData :::: ', dispInputData);
    requestPostWithJsonAPI('setDispatchCreate', createParamObject(dispInputData)).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setDispRegistOpen(false);
          showAlert('dispdatacreate', `'${dispInputData.dispNm}' 직원가 생성되었습니다.`, 'success', 3000);
        } else {
          // 에러 처리 - 필요시
          // if (response.data.status.resultDept === 'duplicate') {
          //   showAlert('codeduplicate', `코드값이 중복되었습니다.`, 'error', 5000);
          // }
        }
      }
    });
  }

  // 파견정보 삭제 - API 호출
  const handleClickDeleteDisp = (param) => {
    requestPostWithJsonAPI('setDispatchDelete', {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
      disp_id: dispDetailData.dispId,
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          showAlert('dispdelete', `'${dispDetailData.dispNm}' 직원정보가 삭제되었습니다.`, 'success', 3000);
        } else {
          showAlert('dispdeleteerror', '직원정보 삭제 작업이 실패 하였습니다.', 'error', 5000);
        }
      }
    });
  }

  // 파견정보 수정 - API 호출
  const handleClickEditDisp = (param) => {
    Object.assign(dispInputData, {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : ''
    });
    // console.log('edit dispInputData ::: ', dispInputData);
    requestPostWithJsonAPI('setDispatchEdit', createParamObject(dispInputData)).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setDispRegistOpen(false);
          showAlert('dispdataedit', `'${dispInputData.dispNm}' 직원정보가 수정되었습니다.`, 'success', 3000);
        } else {
          showAlert('dispediterror', '직원정보 변경 작업이 실패 하였습니다.', 'error', 5000);
        }
      }
    });
  }

  // 툴바에서 파라미터 변경 이벤트 처리
  const handleChangeToolbarOption = (data) => {
    if (data.name === 'keyword') {
      setKeyword(data.value);
      if (data.key === 'Enter') {
        handleClickSearch(1);
      }
    } else if (data.name === 'selectEmpGrade') {
      setSelectedGradeCd(data.value);
      handleClickSearch(1, data.value, undefined);
    }
  }
  const handleClickToolbarButton = (e) => {
    alert(e.currentTarget.id);
    if (e.currentTarget.id === 'disp-toolbar-search') {
      handleClickSearch(1);
    } else if (e.currentTarget.id === 'disp-toolbar-regist') {
      setIsNew(true);
      setDispInputData(dispVo);
      setDispRegistOpen(true);
    }
  }

  // 수정/등록에서의 입력 컴포넌트 이벤트 처리
  const handleFormChange = (data) => {
    setDispInputData(data);
  }

  return (
    <div className={classes.root}>
      <MenuHeader />

      <DispTimeline outsData={tableData} />

      <DispToolbar grades={grades}
        onChangeParam={handleChangeToolbarOption}
        onClickButton={handleClickToolbarButton} />

      <DispList
        tableData={tableData}
        totalCnt={totalCnt}
        curPage={curPage}
        rowCount={rowCount}
        onChangePage={handleClickSearch}
        onClickListRow={handleClickListRow}
        selectedRow={dispDetailData} />

      <DispDetail dispData={dispDetailData}
        onClickModifyDisp={() => {
          setIsNew(false);
          const cloneDispDetailData = JSON.parse(JSON.stringify(dispDetailData));
          if(cloneDispDetailData.retireYn === 'N') {
            Object.assign(cloneDispDetailData, {
              retireDt: (new Date().toISOString().split('T')[0]).toString(),
            });
          }
          setDispInputData(cloneDispDetailData);
          setDispRegistOpen(true);
        }}
        onClickDeleteDisp={handleClickDeleteDisp} />

      <AntDialog open={dispRegistOpen}
        title={isNew ? '외주인력 등록' : '외주인력 정보 수정'}
        comment={isNew ? '새로운 외주인력 정보를 등록합니다.' : '외주인력 정보를 수정합니다.'}
        onClose={() => { setDispRegistOpen(false); }}
        draggable={true}
        actions={[
          { key: 1, label: '취소', callback: () => { setDispRegistOpen(false); } },
          { key: 2, label: '저장', callback: isNew ? handleClickCreateDisp : handleClickEditDisp }
        ]}
        content={<DispInputForm onFormChange={handleFormChange}
          grades={grades} banks={banks} inputData={dispInputData} isNew={isNew}
        />}
      />
    </div>
  );
};

export default DispMain;
