import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import ContentPaneTitleBar from 'components/container/ContentPaneTitleBar';
import { LabelCard } from 'components/card';

import AntRectButton from 'components/button/AntRectButton';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';


const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    minWidth: 780,
  },
  main: {
    boxShadow: 'none',
    border: '1px solid #d0d0d0',
    borderRadius: 0,
  },
  content: {
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    borderRadius: 0,
    padding: 10,
  },
  cusBtn: {
    backgroundColor: '#3c63a8',
    color: '#ffffff',
    padding: 2,
    fontWeight: 600
  }
}));


const AgentDetail = (props) => {
  const { className, agentData, onClickEditScObj, onClickDeleteScObj, onClickCreateScObj, onClickMngGVar, onClickModifyAgent, onClickDeleteAgent, ...rest } = props;
  const classes = useStyles();

  const editContent = (row) => {
    onClickEditScObj(row);
  }

  // console.log('agentData ->> ', agentData);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {(agentData !== undefined) &&
        <div className={classes.main}>
          <ContentPaneTitleBar title='에이전트 상세정보'
            buttons={[
              // {
              //   name: '수정', action: 'edit',
              //   func: onClickModifyAgent,
              // },
              {
                name: '공용변수관리', action: 'gvarmng',
                func: onClickMngGVar,
              },
            ]}
          />
          <div className={classes.content} >
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="에이전트 번호" labelWidth={120} value={agentData.agentNo} />
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="에이전트 버전" labelWidth={120} value={agentData.agentVer} />
              </Grid>
            </Grid>
            <Grid container spacing={6} style={{ marginTop: 15 }}>
              <Grid item xs={12} style={{ paddingBottom: 0 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <LabelCard label="크롤링 시나리오 정보" labelWidth={120} noUnderbar={true} isRef={true} />
                  <button className={classes.cusBtn} onClick={() => onClickCreateScObj()} >신규 시나리오 등록</button>
                </div>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ textAlign: 'center', fontSzie: 10, width: '40px', maxWidth: '40px' }}>순서</TableCell>
                      <TableCell style={{ textAlign: 'center', fontSzie: 10, width: '80px', maxWidth: '80px' }}>아이디</TableCell>
                      <TableCell style={{ textAlign: 'center', fontSzie: 10, width: '200px', maxWidth: '200px' }}>이름</TableCell>
                      <TableCell style={{ textAlign: 'center', fontSzie: 10, width: '50px', maxWidth: '50px' }}>기능버전</TableCell>
                      <TableCell style={{ textAlign: 'center', fontSzie: 10 }}>설명</TableCell>
                      <TableCell style={{ textAlign: 'center', fontSzie: 10, width: '100px', maxWidth: '100px' }}>작업</TableCell>
                    </TableRow>
                  </TableHead>
                  {(agentData.scriptList && agentData.scriptList.length > 0) && (
                    <TableBody>
                      {/* 테이블 행그리는 loop */}
                      {agentData.scriptList.map((row, index) => (
                        <TableRow
                          hover
                          key={index}
                          className={classes.normalRow}
                        >
                          <TableCell align={"center"}>{row.scriptOrd}</TableCell>
                          <TableCell align={"center"}>{row.scriptNo}</TableCell>
                          <TableCell align={"center"}>{row.scriptNm}</TableCell>
                          <TableCell align={"center"}>{row.scriptVer}</TableCell>
                          <TableCell align={"left"}>{row.scriptCmt}</TableCell>
                          <TableCell align={"center"}>
                            <Tooltip title="수정">
                              <IconButton style={{ padding: 1 }} onClick={() => editContent(row)} alt="수정">
                                <CreateIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="삭제">
                              <IconButton style={{ padding: 1, marginLeft: 20 }} onClick={() => onClickDeleteScObj(row)}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </Grid>
            </Grid>
          </div>
        </div>
      }
    </div>
  );
};

export default AgentDetail;
