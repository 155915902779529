import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { MenuHeader } from 'layouts/main/blocks/menu';
import { PayrollToolbar, PayrollList, PayrollDetail } from '.';
import { PayrollInputForm, PayrollSheetInputForm } from '.';
import { YeongumInputForm, GoyongInputForm, GungangInputForm, SanjaeInputForm } from '.';

import { AntDialog } from 'components/dialog';
import { requestPostWithJsonAPI, requestPostDownloadAPI } from 'common/services/AntsRequester';
import { createParamObject } from 'common/utils/DataUtils';
import UserAuthContext from 'common/context/UserAuthContext';

import useAlert from 'common/hooks/useAlert';
import useInform from 'common/hooks/useInform';

import { setDeptsWithFunc } from 'views/employee/EmpUtils';

// Style Class
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#efefef',
  },
}));
// Row Count
const rowCount = 15;

const PayrollMain = props => {
  const classes = useStyles();
  const { showAlert } = useAlert();
  const { showInform } = useInform();
  const { userAuthInfo } = useContext(UserAuthContext);

  const [tableData, setTableData] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [totalCnt, setTotalCnt] = useState(0);

  // option
  const [keyword, setKeyword] = useState('');
  const [year, setYear] = useState('2022');
  const [month, setMonth] = useState('1');
  const [empDept, setEmpDept] = useState('-');
  // option datas
  const [depts, setDepts] = useState([]);
  // modify dialog
  const [empPayrollModifyOpen, setPayrollModifyOpen] = useState(false);
  // modify sheet dialog
  const [empPayrollModifySheetOpen, setPayrollModifySheetOpen] = useState(false);
  // detail
  const [empPayrollDetailData, setPayrollDetailData] = useState(undefined);
  const handleClickListRow = (data) => {
    setPayrollDetailData(data);
  };
  // regist/modify input
  const [empPayrollInputData, setPayrollInputData] = useState({
    empId: '',
  });

  // 국민연금 등록 다이얼로그
  const [empYeongumModifyOpen, setYeongumModifyOpen] = useState(false);
  // 고용보험 등록 다이얼로그
  const [empGoyongModifyOpen, setGoyongModifyOpen] = useState(false);
  // 건강보험 등록 다이얼로그
  const [empGungangModifyOpen, setGungangModifyOpen] = useState(false);
  // 산재보험 등록 다이얼로그
  const [empSanjaeModifyOpen, setSanjaeModifyOpen] = useState(false);

  // select list
  const handleClickSearch = (pageNo, yearParam, monthParam, deptParam) => {
    setPayrollDetailData(undefined);
    requestPostWithJsonAPI('getPayrollList', {
      page: pageNo,
      keyword: keyword,
      page_row_num: rowCount,
      year: (yearParam) ? yearParam : year,
      month: (monthParam) ? monthParam : month,
      dept_cd: (deptParam) ? deptParam : empDept,
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          setCurPage(pageNo);
          setTableData({ data: response.data.listData });
          setTotalCnt(Number(response.data.listTotal));
        }
      }
    });
  };

  useEffect(() => {
    setDeptsWithFunc(setDepts, {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
    });

    handleClickSearch(1);
  }, [userAuthInfo]);

  // 직원 수정 다이얼로그 표시
  function handleClickModifyPayroll() {
    setPayrollInputData(empPayrollDetailData);
    setPayrollModifyOpen(true);
  }

  // 직원 Sheet 수정 다이얼로그 표시
  const handleClickModifySheetPayroll = () => {
    setPayrollInputData(empPayrollDetailData);
    setPayrollModifySheetOpen(true);
  }

  // 이전달 급여 정보 복사 -------------------------------------------------------------------------------------
  const copyBeforePayrollWithMulti = (checkedData) => {
    requestPostWithJsonAPI('setBeforePayrollDataList', {
      emp_id_list: (checkedData.length < 1) ? [] : checkedData.map(e => e.empId),
      pay_year: year,
      pay_month: month,
      dept_cd: (empDept && empDept !== '-') ? empDept : '',
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          showAlert('empdataedit', `직원(들)의 급여정보가 이전달 정보로 변경되었습니다.`, 'success', 3000);
        }
      }
    });
  }
  // (1명) 이전달 급여 정보 복사 - 상세조회
  const handleClickCopyBeforePayroll = () => {
    const msg = [`'${empPayrollDetailData.empNm}' 직원의 ${year}년 ${('0' + month).slice(-2)}월 급여정보를`, `이전달에서 복사하여 저장하시겠습니까?`];
    showInform('급여정보 복사', msg, 'CONFIRM', result => {
      if (result === 'Y') {
        copyBeforePayrollWithMulti([{ 'empId': empPayrollDetailData.empId }]);
      } else {
        return;
      }
    });
  }

  // 급여정보 엑셀 다운로드 --------------------------------------------------------------------------------------
  const downloadPayrollExcelWithMulti = (checkedData) => {
    requestPostDownloadAPI('getPayrollSheetDownload', {
      emp_id_list: JSON.stringify((checkedData.length < 1) ? [] : checkedData.map(e => e.empId)),
      pay_year: year,
      pay_month: month,
      dept_cd: (empDept && empDept !== '-') ? empDept : '',
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        var FileSaver = require('file-saver');
        FileSaver.saveAs(response.data, `급여정보_Sheet_${year}${('0' + month).slice(-2)}`);
      }
    });
  }

  // 은행자료 다운로드 --------------------------------------------------------------------------------------
  const downloadBankReport = (bankCd) => {
    requestPostDownloadAPI('getBankReportDownload', {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
      bank_cd: bankCd,
      pay_year: year,
      pay_month: month,
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        var FileSaver = require('file-saver');
        FileSaver.saveAs(response.data, `bankdata_급여이체_${year}${('0' + month).slice(-2)}.txt`);
      }
    });
  }

  // (1명) 급여 명세 PDF 다운로드 - 상세조회
  const handleClickPayrollPdfDownload = () => {
    console.log('empPayrollDetailData ::: ', empPayrollDetailData);
    if (empPayrollDetailData.base && empPayrollDetailData.base !== '') {
      const msg = [`'${empPayrollDetailData.empNm}' 직원의 ${year}년 ${('0' + month).slice(-2)}월 급여명세서를`, `PDF형식으로 다운로드 하시겠습니까?`];
      showInform('급여명세서 다운로드', msg, 'CONFIRM', result => {
        if (result === 'Y') {
          requestPostDownloadAPI('getPayrollPdfDownload', {
            emp_id: empPayrollDetailData.empId,
            pay_year: year,
            pay_month: month,
            dept_cd: (empDept && empDept !== '-') ? empDept : '',
            company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
          }).then((response) => {
            if (response && response.status && response.status === 200) {
              var FileSaver = require('file-saver');
              FileSaver.saveAs(response.data, `급여명세서_${year}_${('0' + month).slice(-2)}`);
            }
          });
        } else {
          return;
        }
      });
    } else {
      const msg = [`'${empPayrollDetailData.empNm}' 직원의 ${year}년 ${('0' + month).slice(-2)}월 급여정보가 존재하지 않습니다.`];
      showAlert('unable_emppaypdf', msg, 'warning', 3000);
    }
  }

  // 급여 명세 PDF 이메일 -------------------------------------------------------------------------------------
  const sendEmailPayrollPdfMulti = (checkedData) => {
    requestPostWithJsonAPI('sendEmailPayrollPdfMulti', {
      emp_id_list: JSON.stringify((checkedData.length < 1) ? [] : checkedData.map(e => e.empId)),
      pay_year: year,
      pay_month: month,
      dept_cd: (empDept && empDept !== '-') ? empDept : '',
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          showAlert('emppayemail', `직원(들)의 급여명세서가 이메일로 전송되었습니다.`, 'success', 3000);
        }
      }
    });
  }
  // (1명) 급여 명세 PDF 이메일 - 상세조회
  const handleClickPayrollPdfEmail = () => {
    console.log('empPayrollDetailData ::: ', empPayrollDetailData);
    if (empPayrollDetailData.base && empPayrollDetailData.base !== '') {
      const msg = [`'${empPayrollDetailData.empNm}' 직원에게 `, `${year}년 ${('0' + month).slice(-2)}월 급여명세서를 이메일로 전송하시겠습니까?`];
      showInform('급여명세서 이메일 전송', msg, 'CONFIRM', result => {
        if (result === 'Y') {
          sendEmailPayrollPdfMulti([{ 'empId': empPayrollDetailData.empId }]);
        } else {
          return;
        }
      });
    } else {
      const msg = [`'${empPayrollDetailData.empNm}' 직원의 ${year}년 ${('0' + month).slice(-2)}월 급여정보가 존재하지 않습니다.`];
      showAlert('unable_emppaypdf', msg, 'warning', 3000);
    }
  }

  // 직원 수정
  const handleClickEditPayroll = (param) => {
    empPayrollInputData['pay_year'] = year;
    empPayrollInputData['pay_month'] = month;
    requestPostWithJsonAPI('setPayrollEdit', createParamObject(empPayrollInputData)).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setPayrollModifyOpen(false);
          setPayrollModifySheetOpen(false);
          showAlert('empdataedit', `'${empPayrollInputData.empNm}' 직원정보가 수정되었습니다.`, 'success', 3000);
        }
      }
    });
  }

  // 툴바에서 파라미터 변경 이벤트 처리
  const handleChangeToolbarOption = (data) => {
    if (data.name === 'keyword') {
      setKeyword(data.value);
    } else if (data.name === 'keywordEnter') {
      handleClickSearch(1);
    } else if (data.name === 'selectYear') {
      setYear(data.value);
      setMonth('1');
      handleClickSearch(1, data.value, '1');
    } else if (data.name === 'selectEmpDept') {
      setEmpDept(data.value);
      handleClickSearch(1, undefined, '1', data.value);
    }
  }

  // 국민연금 정보 등록/수정
  const handleClickSaveYeongum = (param) => {
    empPayrollInputData['pay_year'] = year;
    empPayrollInputData['pay_month'] = month;
    requestPostWithJsonAPI('setYeongumEdit', createParamObject(empPayrollInputData)).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setYeongumModifyOpen(false);
          showAlert('yeongumdataedit', `${year}년 ${month}월 직원들의 국민연금정보가 수정되었습니다.`, 'success', 3000);
        }
      }
    });
  }

  // 고용보험 정보 등록/수정
  const handleClickSaveGoyong = (param) => {
    empPayrollInputData['pay_year'] = year;
    empPayrollInputData['pay_month'] = month;
    requestPostWithJsonAPI('setGoyongEdit', createParamObject(empPayrollInputData)).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setGoyongModifyOpen(false);
          showAlert('goyongdataedit', `${year}년 ${month}월 직원들의 고용보험정보가 수정되었습니다.`, 'success', 3000);
        }
      }
    });
  }

  // 건강보험 정보 등록/수정
  const handleClickSaveGungang = (param) => {
    empPayrollInputData['pay_year'] = year;
    empPayrollInputData['pay_month'] = month;
    requestPostWithJsonAPI('setGungangEdit', createParamObject(empPayrollInputData)).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setGungangModifyOpen(false);
          showAlert('gungangdataedit', `${year}년 ${month}월 직원들의 건강보험정보가 수정되었습니다.`, 'success', 3000);
        }
      }
    });
  }

  // 산재보험 정보 등록/수정
  const handleClickSaveSanjae = (param) => {
    empPayrollInputData['pay_year'] = year;
    empPayrollInputData['pay_month'] = month;
    requestPostWithJsonAPI('setSanjaeEdit', createParamObject(empPayrollInputData)).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setSanjaeModifyOpen(false);
          showAlert('sanjaedataedit', `${year}년 ${month}월 직원들의 산재보험정보가 수정되었습니다.`, 'success', 3000);
        }
      }
    });
  }
  const handleClickToolbarButton = (e) => {
    if (e.currentTarget.id === 'pay-toolbar-search') {
      handleClickSearch(1);
    } else if (e.currentTarget.id === 'pay-toolbar-yeonkum') {
      setYeongumModifyOpen(true);
    } else if (e.currentTarget.id === 'pay-toolbar-goyong') {
      setGoyongModifyOpen(true);
    } else if (e.currentTarget.id === 'pay-toolbar-gungang') {
      setGungangModifyOpen(true);
    } else if (e.currentTarget.id === 'pay-toolbar-sanjae') {
      setSanjaeModifyOpen(true);
    } else if (e.currentTarget.id === 'pay-toolbar-wooribank') {
      const msg = [`${year}년 ${('0' + month).slice(-2)}월 급여정보를`, `우리은행 자료형식으로 다운로드 하시겠습니까?`];
      showInform('우리은행자료 다운로드', msg, 'CONFIRM', result => {
        if (result === 'Y') {
          downloadBankReport('B020');
        } else {
          return;
        }
      });
    } else {
      // 이전달 정보 복사 - 일부 또는 전체
      let checkedData = tableData.data.filter(e => e['checked'] !== undefined && e['checked'] === true);
      let targetText = `선택된(${checkedData.length}명) 직원의 `;
      if (checkedData.length === 0) {
        if (empDept !== undefined && empDept !== '-') {
          const selectDept = depts.find(e => e.value === empDept);
          if (selectDept) {
            targetText = `'${selectDept.name}' 전체의`;
          } else {
            targetText = `선택한 부서 전체의`;
          }
        } else {
          targetText = `직원 전체의`;
        }
      }
      if (e.currentTarget.id === 'pay-toolbar-excelall') {
        const msg = [`${targetText} ${year}년 ${('0' + month).slice(-2)}월 급여정보를`, `엑셀형식으로 다운로드 하시겠습니까?`];
        showInform('급여정보 다운로드', msg, 'CONFIRM', result => {
          if (result === 'Y') {
            downloadPayrollExcelWithMulti(checkedData);
          } else {
            return;
          }
        });
      } else if (e.currentTarget.id === 'pay-toolbar-emailall') {
        const msg = [`${targetText} ${year}년 ${('0' + month).slice(-2)}월 급여명세서를`, `이메일로 전송하시겠습니까?`];
        showInform('급여명세서 이메일 전송', msg, 'CONFIRM', result => {
          if (result === 'Y') {
            sendEmailPayrollPdfMulti(checkedData);
          } else {
            return;
          }
        });
      } else if (e.currentTarget.id === 'pay-toolbar-copyall') {
        const msg = [`${targetText} ${year}년 ${('0' + month).slice(-2)}월 급여정보를`, `이전달(${e.currentTarget.getAttribute('bmonth')})에서 복사하여 저장하시겠습니까?`];
        showInform('급여정보 복사', msg, 'CONFIRM', result => {
          if (result === 'Y') {
            copyBeforePayrollWithMulti(checkedData);
          } else {
            return;
          }
        });
      }
    }
  }

  const handleClickToolbarMonthButton = (paramMonth) => {
    setMonth(paramMonth);
    handleClickSearch(1, year, paramMonth);
  }

  // 수정/등록에서의 입력 컴포넌트 이벤트 처리
  const handleChangeInput = (data) => {
    // deep clone
    const temp = {};
    for (let key of Object.keys(empPayrollInputData)) {
      temp[key] = empPayrollInputData[key]
    }
    if (Array.isArray(data)) {
      data.every(e => temp[e.name] = e.value);
    } else {
      if (data.type === 'number') {
        temp[data.name] = data.value.split(',').join('');
      } else {
        temp[data.name] = data.value;
      }
    }
    setPayrollInputData(temp);
  }

  return (
    <div className={classes.root}>
      <MenuHeader />

      <PayrollToolbar //years={[{ name: '2021', value: '2021' }, { name: '2022', value: '2022' }]}
        depts={depts}
        selectedYear={year}
        selectedMonth={month}
        onChangeParam={handleChangeToolbarOption}
        onClickButton={handleClickToolbarButton}
        onClickMonthButton={handleClickToolbarMonthButton} />

      <PayrollList
        tableData={tableData}
        setTableData={setTableData}
        totalCnt={totalCnt}
        curPage={curPage}
        rowCount={rowCount}
        onChangePage={handleClickSearch}
        onClickListRow={handleClickListRow}
        selectedRow={empPayrollDetailData} />

      <PayrollDetail empPayrollData={empPayrollDetailData}
        onClickModifyPayroll={handleClickModifyPayroll}
        onClickModifySheetPayroll={handleClickModifySheetPayroll}
        onClickCopyBeforePayroll={handleClickCopyBeforePayroll}
        onClickPayrollPdfDownload={handleClickPayrollPdfDownload}
        onClickPayrollPdfEmail={handleClickPayrollPdfEmail}
      />

      <AntDialog open={empPayrollModifyOpen} maxWidth='md'
        title='직원 급여 정보 수정'
        comment='직원 급여 정보를 수정하는 화면'
        onClose={() => setPayrollModifyOpen(false)}
        draggable={true}
        actions={[
          { key: 1, label: '취소', callback: () => setPayrollModifyOpen(false) },
          { key: 2, label: '저장', callback: handleClickEditPayroll }
        ]}
        content={<PayrollInputForm onFormChange={handleChangeInput}
          empPayrollData={empPayrollInputData}
        />}
      />

      <AntDialog open={empPayrollModifySheetOpen} maxWidth='lg'
        title='직원 급여 정보 수정 - Sheet'
        comment='직원 급여 정보를 Sheet 형태로 수정하는 화면'
        onClose={() => setPayrollModifySheetOpen(false)}
        draggable={true}
        actions={[
          { key: 1, label: '취소', callback: () => setPayrollModifySheetOpen(false) },
          { key: 2, label: '저장', callback: handleClickEditPayroll }
        ]}
        content={<PayrollSheetInputForm onFormChange={handleChangeInput}
          empPayrollData={empPayrollInputData}
        />}
      />

      <AntDialog open={empYeongumModifyOpen} maxWidth='sm'
        title='국민연금 정보 등록'
        comment='직원들의 국민연금 정보를 수정하는 화면'
        onClose={() => setYeongumModifyOpen(false)}
        draggable={true}
        actions={[
          { key: 1, label: '취소', callback: () => setYeongumModifyOpen(false) },
          { key: 2, label: '저장', callback: handleClickSaveYeongum }
        ]}
        content={<YeongumInputForm
          onFormChange={handleChangeInput}
        />}
      />

      <AntDialog open={empGoyongModifyOpen} maxWidth='sm'
        title='고용보험 정보 등록'
        comment='직원들의 고용보험 정보를 수정하는 화면'
        onClose={() => setGoyongModifyOpen(false)}
        draggable={true}
        actions={[
          { key: 1, label: '취소', callback: () => setGoyongModifyOpen(false) },
          { key: 2, label: '저장', callback: handleClickSaveGoyong }
        ]}
        content={<GoyongInputForm
          onFormChange={handleChangeInput}
        />}
      />

      <AntDialog open={empGungangModifyOpen} maxWidth='sm'
        title='건강보험 정보 등록'
        comment='직원들의 건강보험 정보를 수정하는 화면'
        onClose={() => setGungangModifyOpen(false)}
        draggable={true}
        actions={[
          { key: 1, label: '취소', callback: () => setGungangModifyOpen(false) },
          { key: 2, label: '저장', callback: handleClickSaveGungang }
        ]}
        content={<GungangInputForm
          onFormChange={handleChangeInput}
        />}
      />

      <AntDialog open={empSanjaeModifyOpen} maxWidth='sm'
        title='산재보험 정보 등록'
        comment='직원들의 산재보험 정보를 수정하는 화면'
        onClose={() => setSanjaeModifyOpen(false)}
        draggable={true}
        actions={[
          { key: 1, label: '취소', callback: () => setSanjaeModifyOpen(false) },
          { key: 2, label: '저장', callback: handleClickSaveSanjae }
        ]}
        content={<SanjaeInputForm
          onFormChange={handleChangeInput}
        />}
      />

    </div>
  );
};

export default PayrollMain;
