import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Typography, IconButton } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import CropSquare from '@material-ui/icons/CropSquare';

import MuiDialogTitle from '@material-ui/core/DialogTitle';

const dialogTitleStyles = (theme) => ({
    root: {
        padding: theme.spacing(2),
        flex: '0 0 auto',
        display: 'flex',
        justifyContent: 'flex-start',
        background: '#f5f5f5',
        borderBottom: '1px dashed #f5f5f5',
    },
    title: {
        fontSize: 16,
        alignSelf: 'flex-end',
    },
    titleHeaderDeco: {
        color: '#797979',
        paddingTop: 3,
        marginBottom: 0,
        marginRight: 3,
    },
    closeButton: {
        position: 'absolute',
        right: 4,
        top: -3,
        stroke: '#af7070',
        strokeWidth: 2,
    },
});

const AntDialogTitle = withStyles(dialogTitleStyles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <CropSquare className={classes.titleHeaderDeco} />
            <Typography className={classes.title}>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default AntDialogTitle;
