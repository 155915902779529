import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Paper, } from '@material-ui/core';
import { Dialog, DialogActions, DialogContentText } from '@material-ui/core';

import { AntDialogTitle, AntDialogDragTitle } from 'components/dialog';

import AntRectButton from 'components/button/AntRectButton';

import MuiDialogContent from '@material-ui/core/DialogContent';
import Draggable from 'react-draggable';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    dividers: {
        borderBottom: '1px solid #d0d0d0',
    },
}))(MuiDialogContent);


const AntDialog = (props) => {
    const { draggable, title, comment, content, onClose, fullWidth, maxWidth, actions, btnDisabled=true } = props;

    return (
        <Dialog open={props.open} fullWidth={(fullWidth !== undefined) ? fullWidth : true}
            maxWidth={(maxWidth !== undefined) ? maxWidth : 'sm'}
            PaperComponent={(draggable !== undefined && draggable) ? PaperComponent : undefined}
        >
            {(draggable) ?
                <AntDialogDragTitle onClose={onClose}>{title}</AntDialogDragTitle> :
                <AntDialogTitle onClose={onClose}>{title}</AntDialogTitle>
            }
            <DialogContent dividers>
                <DialogContentText>{comment}</DialogContentText>
                {content}
            </DialogContent >
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <div>
                    {actions &&
                        actions.filter(e => e.key < 0).map(item => {
                            return (
                                <AntRectButton key={item.key} variant="contained" onClick={item.callback} disableElevation style={{ fontWeight: 'bold', height: 30 }}>
                                    {item.label}
                                </AntRectButton>
                            );
                        })}
                </div>
                <div>
                    {actions &&
                        actions.filter(e => e.key > 0).map(item => {
                            return (
                                <AntRectButton key={item.key} disabled={btnDisabled} variant="contained" onClick={item.callback} disableElevation style={{ fontWeight: 'bold', height: 30 }}>
                                    {item.label}
                                </AntRectButton>
                            );
                        })}
                </div>
            </DialogActions>
        </Dialog>
    );
}

export default AntDialog;
