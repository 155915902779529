import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { Grid } from '@material-ui/core';
import ContentPaneTitleBar from 'components/container/ContentPaneTitleBar';
import { LabelCard } from 'components/card';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    minWidth: 780,
  },
  main: {
    boxShadow: 'none',
    border: '1px solid #d0d0d0',
    borderRadius: 0,
  },
  content: {
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    borderRadius: 0,
    padding: 10,
  },
}));


const ClientDetail = (props) => {
  const { className, clientData, onClickModifyClient, onClickRegistClientAdminUser, onClickDeleteClient, ...rest } = props;
  const classes = useStyles();

  // console.log('clientData :::::: ', clientData);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {(clientData !== undefined) &&
        <div className={classes.main}>
          <ContentPaneTitleBar title='회사 상세정보'
            style={{ backgroundColor: '#777777' }}
            buttons={[]}
          />
          <div className={classes.content} >
            <Grid container spacing={6} style={{marginBottom: 15}}>
            <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="회사이름" labelWidth={120} value={clientData.clientNm} />
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="회사코드" labelWidth={120} value={clientData.clientCd} />
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="대표이름" labelWidth={120} value={clientData.ceoNm} />
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="사업자번호" labelWidth={120} value={clientData.businessNo} />
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="연락처" labelWidth={120} value={clientData.telNo} />
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="이메일" labelWidth={120} value={clientData.email} />
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: 0 }}>
                <LabelCard label="주소" labelWidth={120} value={clientData.clientAddr} />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="등록일(작업자)" labelWidth={120} value={(clientData.regUserId && clientData.regUserId !== '') ? clientData.regDt + ' (' + clientData.regUserId + ')' : '-'} />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="수정일(작업자)" labelWidth={120} value={(clientData.modUserId && clientData.modUserId !== '') ? clientData.modDt + ' (' + clientData.modUserId + ')' : '-'} />
              </Grid>
            </Grid>
            {(clientData && clientData.adminList && clientData.adminList.length > 0) &&
              <Grid container spacing={6} style={{marginTop: 15}}>
                <Grid item xs={12} style={{paddingBottom: 0}}>
                <LabelCard label="관리자 정보" labelWidth={120} noUnderbar={true} isRef={true} />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                  <LabelCard label="아이디" labelWidth={120} value={clientData.adminList[0].adminId} />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                  <LabelCard label="이름" labelWidth={120} value={clientData.adminList[0].adminNm} />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                  <LabelCard label="전화번호" labelWidth={120} value={clientData.adminList[0].telNo} />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                  <LabelCard label="이메일" labelWidth={120} value={clientData.adminList[0].email} />
                </Grid>
                <Grid item xs={12}></Grid>
              </Grid>
            }
          </div>
        </div>
      }
    </div>
  );
};

export default ClientDetail;
