import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableRow from './MuiTableRow';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';
import MuiCssBaseline from './MuiCssBaseline';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiPaginationItem from './MuiPaginationItem';

export default {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableRow,
  MuiTableCell,
  MuiTableHead,
  MuiTypography,
  MuiCssBaseline,
  MuiOutlinedInput,
  MuiPaginationItem
};
