import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';

import { Grid, TextField } from '@material-ui/core';

import { InputCombo } from 'components/input';
import { DatePicker } from '@material-ui/pickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles(theme => ({
  radioLabel: {
    "& .MuiFormControlLabel-label": {
      color: "#3c63a8",
      fontSize: '11px',
      lineHeight: '16px',
      fontWeight: 500
    },
  }
}));

const EmpInputForm = (props) => {
  const { onFormChange, grades, depts, banks, inputData, isNew } = props;
  const classes = useStyles();

  // 직급
  const [selectedGradeCode, setSelectedGradeCode] = useState('');
  const handleGradeCodeChange = (e) => {
    setSelectedGradeCode(e.target.value);
    onFormChange({ ...inputData, 'gradeCd': e.target.value });
  };

  // 부서
  const [selectedDeptCode, setSelectedDeptCode] = useState('');
  const handleDeptCodeChange = (e) => {
    setSelectedDeptCode(e.target.value);
    onFormChange({ ...inputData, 'deptCd': e.target.value });
  };

  // 급여통장은행
  const [selectedBankCode, setSelectedBankCode] = useState('');
  const handleBankCodeChange = (e) => {
    setSelectedBankCode(e.target.value);
    onFormChange({ ...inputData, 'bankCd': e.target.value });
  };

  useEffect(() => {
    if (inputData) {
      // 사이트 타입
      setSelectedGradeCode((inputData.gradeCd) ? inputData.gradeCd : '');
      // 부서
      setSelectedDeptCode((inputData.deptCd) ? inputData.deptCd : '');
    } else {
    }
  }, [inputData]);

  useEffect(() => {
    onFormChange({ ...inputData, 'bankCd': 'B020'});
  }, []);

  return (
    <div style={{ width: '100%', paddingTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 30, }}>
      <Grid container spacing={6}>
        <Grid item xs={4}>
          <TextField label="직원 번호" fullWidth
            value={inputData.empNo}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({ ...inputData, 'empNo': e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField label="직원 이름" fullWidth
            value={inputData.empNm}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({ ...inputData, 'empNm': e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField label="직원 코드" fullWidth
            disabled={true}
            value={(isNew) ? '[자동 생성됨]' : inputData.empId}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField label="주민등록번호" fullWidth
            value={inputData.residentNo}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({ ...inputData, 'residentNo': e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={4} style={{ paddingBottom: 0 }}>
          <TextField label="전화 번호" fullWidth
            value={inputData.phone}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({ ...inputData, 'phone': e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={4} style={{ paddingBottom: 0 }}>
          <TextField label="이메일" fullWidth
            value={inputData.email}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({ ...inputData, 'email': e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingBottom: 0 }}>
          <InputCombo
            label="부서"
            items={depts && depts.slice(1)}
            value={selectedDeptCode}
            onChange={handleDeptCodeChange}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingBottom: 0 }}>
          <InputCombo
            label="직급"
            items={grades && grades.slice(1)}
            value={selectedGradeCode}
            onChange={handleGradeCodeChange}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <TextField label="주소" fullWidth
            value={inputData.addrStr}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({ ...inputData, 'addrStr': e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={4} style={{ paddingBottom: 0 }}>
          <DatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="입사일" fullWidth
            autoOk={true}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            value={inputData.enterDt}
            onChange={(e) => {
              onFormChange({ ...inputData, 'enterDt': moment(e).format('yyyy-MM-DD') });
            }}
          />
        </Grid>
        <Grid item xs={4} style={{ paddingBottom: 0, textAlign: 'right' }}>
          <FormControlLabel className={classes.radioLabel}
            control={<Switch color="primary"
              checked={(inputData.retireYn === 'Y') ? true : false}
              onChange={(e) => {
                onFormChange({ ...inputData, 'retireYn': (e.target.checked) ? 'Y' : 'N' });
              }}
            />}
            label="퇴사 여부"
            labelPlacement="top"
          />
        </Grid>
        <Grid item xs={4} style={{ paddingBottom: 0, display: 'flex', flexDirection: 'row' }}>
          <DatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="퇴사일" fullWidth
            disabled={(inputData.retireYn === 'N') ? true : false}
            autoOk={true}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            value={inputData.retireDt}
            onChange={(e) => {
              onFormChange({ ...inputData, 'retireDt': moment(e).format('yyyy-MM-DD') });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField label="국민연금 번호" fullWidth
            value={inputData.yeonkumNo}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({ ...inputData, 'yeonkumNo': e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField label="건강보험증 번호" fullWidth
            value={inputData.gungangNo}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({ ...inputData, 'gungangNo': e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField label="고용보험 원부번호" fullWidth
            value={inputData.goyongNo}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({ ...inputData, 'goyongNo': e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField label="산재보험 번호" fullWidth
            value={inputData.sanjaeNo}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({ ...inputData, 'sanjaeNo': e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingBottom: 0 }}>
          <InputCombo
            label="급여통장은행"
            items={banks && banks.slice(1)}
            value={'B020'}
            onChange={handleBankCodeChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField label="급여통장 계좌번호" fullWidth
            value={inputData.accountNo}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({ ...inputData, 'accountNo': e.target.value });
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default EmpInputForm;
