import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import { Paper, Grid } from '@material-ui/core';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 0,
    alignItems: 'center',
    display: 'flex',
    height: 30,
  },
  label: {
    borderRadius: 0,
    textAlign: 'center',
    backgroundColor: '#D3D3D3',
    whiteSpace: 'nowrap',
    height: 30,
    fontSize: 14,
    lineHeight: 2,
  },
  combo: {
    borderRadius: 0,
    backgroundColor: '#fafafa',
    color: '#1f1f1f',
    whiteSpace: 'nowrap',
    height: 30,
  }
}));

const ToolbarCombo = props => {

  const classes = useStyles();
  const { className, id, labelClass, inputClass, style, onChange, items, label, value, ...rest } = props;

  return (
    <Paper {...rest} className={clsx(classes.root, className)} style={style}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
        <Grid item xs={4}>
          <Paper className={(labelClass) ? labelClass : classes.label}>{label}</Paper>
        </Grid>
        <Grid item xs={8}>
          <Select fullWidth variant="outlined"
            className={(inputClass) ? inputClass : classes.combo} value={(items === undefined || items.length < 1) ? '' : value} onChange={onChange}
          >
            {items && items.map((item) => {
              return <MenuItem name={id} key={item.value} value={item.value}>{item.name}</MenuItem>
            })}
          </Select>
        </Grid>
      </Grid>
    </Paper>
  );
};

ToolbarCombo.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  items: PropTypes.array
};

export default ToolbarCombo;
