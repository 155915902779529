import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const RoutePrivateWithLayout = props => {
  const { layout: Layout, component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={matchProps => (
        sessionStorage.getItem('user') ?
          <Layout>
            <Component {...matchProps} />
          </Layout>
          : <Redirect to='/login' />
      )}
    />
  );
};

RoutePrivateWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RoutePrivateWithLayout;
