import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

import { FrameMeasure } from 'layouts/constants';
import Footer from './blocks/Footer';
import Sidebar from './blocks/Sidebar';
import Topbar from './blocks/Topbar';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minWidth: 1100,
    display: 'table',
    height: '100%',
    tableLayout: 'fixed',
  },
  shiftContent: {
    paddingLeft: FrameMeasure.LEFTSIDE_WIDTH,
  },
  main_content: {
    position: 'relative',
    display: 'table-cell',
    verticalAlign: 'top',
    padding: '20px',
    background: '#efefef',
    width: '100%',
    boxSizing: 'border-box',
  },
  content: {
    height: '100%',
    backgroundColor: '#fafafa',
    margin: 0
  }
}));

const Main = props => {
  const { children } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div name="main-layout" className={classes.root}>
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      {/* <Topbar onSidebarToggle={handleSidebarOpen} /> */}
      <div className={classes.main_content}>
        <Topbar onSidebarToggle={handleSidebarOpen} />
        {children}
        <Footer />
      </div>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
