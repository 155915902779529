
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { MenuHeader } from 'layouts/main/blocks/menu';
import UserAuthContext from 'common/context/UserAuthContext';

import GradeToolbar from './GradeToolbar';
import GradeList from './GradeList';
import GradeDetail from './GradeDetail';
import GradeInputForm from './GradeInputForm';

import { AntDialog } from 'components/dialog';
import { requestPostWithJsonAPI } from 'common/services/AntsRequester';
import { createParamObject } from 'common/utils/DataUtils';

import useAlert from 'common/hooks/useAlert';

// Style Class
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#efefef',
  },
}));
// Row Count
const rowCount = 15;
const gradeVo = {
  companyCd: '',
  gradeCd: '',
  gradeNm: '',
  gradeCmt: '',
  cdOrd: '',
};

const GradeMain = () => {
  const classes = useStyles();
  const { showAlert } = useAlert();
  const { userAuthInfo } = useContext(UserAuthContext);

  const [tableData, setTableData] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [totalCnt, setTotalCnt] = useState(0);

  // option
  const [keyword, setKeyword] = useState('');

  // regist dialog
  const [gradeRegistOpen, setGradeRegistOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);

  // detail
  const [gradeDetailData, setGradeDetailData] = useState(undefined);
  const handleClickListRow = (data) => {
    setGradeDetailData(data);
  };
  // regist/modify input
  const [gradeInputData, setGradeInputData] = useState({
  });

  // select list
  const handleClickSearch = (pageNo) => {
    setGradeDetailData(undefined);
    requestPostWithJsonAPI('getGradeList', {
      page: pageNo,
      keyword: keyword,
      page_row_num: rowCount,
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          setCurPage(pageNo);
          setTableData({ data: response.data.listData });
          setTotalCnt(Number(response.data.listTotal));
        }
      }
    });
  };

  useEffect(() => {
    handleClickSearch(1);
  }, [userAuthInfo]);

  // 직급 생성 - API 호출
  const handleClickCreateGrade = () => {
    Object.assign(gradeInputData, {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
    });
    requestPostWithJsonAPI('setGradeCreate', createParamObject(gradeInputData)).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setGradeRegistOpen(false);
          showAlert('gradecreate', `'${gradeInputData.gradeNm}' 직급정보가 생성되었습니다.`, 'success', 3000);
        } else {
          // 에러 처리 - 필요시
          // if (response.data.status.resultGrade === 'duplicate') {
          //   showAlert('codeduplicate', `코드값이 중복되었습니다.`, 'error', 5000);
          // }
        }
      }
    });
  }

  // 직급 삭제 - API 호출
  const handleClickDeleteGrade = () => {
    requestPostWithJsonAPI('setGradeDelete', {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
      grade_cd: gradeDetailData.gradeCd,
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          showAlert('gradedelete', `'${gradeDetailData.gradeNm}' 직급정보가 삭제되었습니다.`, 'success', 3000);
        } else {
          showAlert('gradedeleteerror', '직급정보 삭제 작업이 실패 하였습니다.', 'error', 5000);
        }
      }
    });
  }

  // 직급 수정 - API 호출
  const handleClickEditGrade = () => {
    requestPostWithJsonAPI('setGradeEdit', {
      company_cd: (userAuthInfo.company) ? userAuthInfo.company.companyCd : '',
      grade_cd: gradeInputData.gradeCd,
      grade_nm: gradeInputData.gradeNm,
      grade_cmt: gradeInputData.gradeCmt,
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          setGradeRegistOpen(false);
          showAlert('codeedit', '직급정보가 변경되었습니다.', 'success', 3000);
        } else {
          showAlert('codeediterror', '직급정보 변경 작업이 실패 하였습니다.', 'error', 5000);
        }
      }
    });
  }

  // 툴바에서 파라미터 변경 이벤트 처리
  const handleChangeToolbarOption = (data) => {
    if (data.name === 'keyword') {
      setKeyword(data.value);
      if (data.key === 'Enter') {
        handleClickSearch(1, undefined, data.value);
      }
    }
  }
  const handleClickToolbarButton = (e) => {
    if (e.currentTarget.id === 'grade-toolbar-search') {
      handleClickSearch(1);
    } else if (e.currentTarget.id === 'grade-toolbar-regist') {
      setIsNew(true);
      setGradeInputData(gradeVo);
      setGradeRegistOpen(true);
    }
  }

  const handleFormChange = (data) => {
    setGradeInputData(data);
  }

  return (
    <div className={classes.root}>
      <MenuHeader />

      <GradeToolbar onChangeParam={handleChangeToolbarOption}
        onClickButton={handleClickToolbarButton} />

      <GradeList
        tableData={tableData}
        totalCnt={totalCnt}
        curPage={curPage}
        rowCount={rowCount}
        onChangePage={handleClickSearch}
        onClickListRow={handleClickListRow}
        selectedRow={gradeDetailData} />

      <GradeDetail gradeData={gradeDetailData}
        onClickModifyGrade={() => {
          setIsNew(false);
          setGradeInputData({
            ...gradeDetailData,
            password: ''
          });
          setGradeRegistOpen(true);
        }}
        onClickDeleteGrade={handleClickDeleteGrade} />

      <AntDialog open={gradeRegistOpen}
        title={isNew ? '직급정보 등록' : '직급정보 수정'}
        comment={isNew ? '새로운 직급 정보를 등록합니다.' : '직급 정보를 수정합니다.'}
        onClose={() => { setGradeRegistOpen(false); }}
        draggable={true}
        actions={[
          { key: 1, label: '취소', callback: () => { setGradeRegistOpen(false); } },
          { key: 2, label: '저장', callback: isNew ? handleClickCreateGrade : handleClickEditGrade }
        ]}
        content={<GradeInputForm onFormChange={handleFormChange}
          inputData={gradeInputData} isNew={isNew}
        />}
      />
    </div>
  );
};

export default GradeMain;
