import React from 'react';

import DashboardIcon from '@material-ui/icons/Dashboard';
import ArrowRightIcon from '@material-ui/icons/RadioButtonUnchecked';
import PersonIcon from '@material-ui/icons/Person';
import SettingsInputCompositeIcon from '@material-ui/icons/SettingsInputComposite';
import DescriptionIcon from '@material-ui/icons/Description';

const menuMap_ADMIN = [
  {
    type: 'menu',
    menuId: 'mnAdminDashboard',
    title: 'Dashboard',
    href: '/admindashboard',
    icon: <DashboardIcon style={{ fontSize: 18 }} />,
  },
  {
    type: 'group',
    menuId: 'mnAdminGroup01',
    title: '관리',
  },
  {
    type: 'menu',
    menuId: 'mnAgencyMng',
    title: '세무사 관리',
    href: '/agencymng',
    icon: <PersonIcon style={{ fontSize: 18 }} />,
  },
  {
    type: 'menu',
    menuId: 'mnCompanyMng',
    title: '고객사 관리',
    href: '/companymng',
    icon: <PersonIcon style={{ fontSize: 18 }} />,
  },
  {
    type: 'group',
    menuId: 'mnAdminGroup02',
    title: '시스템',
  },
  {
    type: 'menu',
    menuId: 'mnAdminMng',
    title: '운영관리자',
    href: '/adminmng',
    icon: <ArrowRightIcon style={{ fontSize: 'small' }} />,
  },
  {
    type: 'menu',
    menuId: 'mnCodeMng',
    title: '공통코드2',
    href: '/codemng',
    icon: <SettingsInputCompositeIcon style={{ fontSize: 18 }} />,
  },
  {
    type: 'menu',
    menuId: 'mnScriptMng',
    title: '에이전트관리',
    href: '/agentmng',
    icon: <DescriptionIcon style={{ fontSize: 18 }} />,
  },
];

const menuMap_AGENCY = [
  {
    type: 'menu',
    menuId: 'mnAgencyDashboard',
    title: 'Dashboard',
    href: '/agencydashboard',
    icon: <DashboardIcon style={{ fontSize: 18 }} />,
  },
  {
    type: 'group',
    menuId: 'mnAgencyGroup01',
    title: '회사',
  },
  { type: 'menu', menuId: 'mnCompanyMng1', title: '고객사관리', href: '/agencyclient', icon: <PersonIcon style={{ fontSize: 18 }} />, },
  { type: 'menu', unvisible: true, menuId: 'mnCompanyMng2', title: '고객사관리2', href: '/agencycompmain', icon: <PersonIcon style={{ fontSize: 18 }} />, },
  { type: 'menu', unvisible: true, menuId: 'mnCompanyMng3', title: '고객사관리3', href: '/agencycompmain', icon: <PersonIcon style={{ fontSize: 18 }} />, },
  { type: 'menu', unvisible: true, menuId: 'mnCompanyMng4', title: '고객사관리4', href: '/agencycompmain', icon: <PersonIcon style={{ fontSize: 18 }} />, },
  { type: 'menu', unvisible: true, menuId: 'mnCompanyMng5', title: '고객사관리5', href: '/agencycompmain', icon: <PersonIcon style={{ fontSize: 18 }} />, },
  { type: 'menu', unvisible: true, menuId: 'mnCompanyMng6', title: '고객사관리6', href: '/agencycompmain', icon: <PersonIcon style={{ fontSize: 18 }} />, },
  { type: 'menu', unvisible: true, menuId: 'mnCompanyMng7', title: '고객사관리7', href: '/agencycompmain', icon: <PersonIcon style={{ fontSize: 18 }} />, },
  { type: 'menu', unvisible: true, menuId: 'mnCompanyMng8', title: '고객사관리8', href: '/agencycompmain', icon: <PersonIcon style={{ fontSize: 18 }} />, },
  { type: 'menu', unvisible: true, menuId: 'mnCompanyMng9', title: '고객사관리9', href: '/agencycompmain', icon: <PersonIcon style={{ fontSize: 18 }} />, },
  {
    type: 'menu',
    menuId: 'mnCompanyPayMng',
    title: '급여세무관리',
    href: '/paytaxmain',
    icon: <PersonIcon style={{ fontSize: 18 }} />,
  },
  {
    type: 'group',
    menuId: 'mnAgencyGroup02',
    title: '설정',
  },
  {
    type: 'menu',
    menuId: 'mnAgencyConfig1',
    title: '설정-1',
    href: '/construct1',
    icon: <ArrowRightIcon style={{ fontSize: 'small' }} />,
  },
  {
    type: 'menu',
    menuId: 'mnAgencyConfig2',
    title: '설정-2',
    href: '/construct2',
    icon: <ArrowRightIcon style={{ fontSize: 'small' }} />,
  },
];

const menuMap_MANAGER = [
  {
    type: 'menu',
    menuId: 'mnManagerDashboard',
    title: 'Dashboard',
    href: '/managerdashboard',
    icon: <DashboardIcon style={{ fontSize: 18 }} />,
  },
  {
    type: 'group',
    menuId: 'mnManagerGroup01',
    title: '직원',
  },
  {
    type: 'menu',
    menuId: 'mnEmployeeMng',
    title: '직원관리',
    href: '/employeemain',
    icon: <PersonIcon style={{ fontSize: 18 }} />,
  },
  {
    type: 'menu',
    menuId: 'mnPayMng',
    title: '급여관리',
    href: '/payrollmain',
    icon: <PersonIcon style={{ fontSize: 18 }} />,
  },
  {
    type: 'group',
    menuId: 'mnOutsourceGroup02',
    title: '외주인력',
  },
  {
    type: 'menu',
    menuId: 'mnOutsourceMng',
    title: '인력관리',
    href: '/outsourcemain',
    icon: <PersonIcon style={{ fontSize: 18 }} />,
  },
  {
    type: 'menu',
    menuId: 'mnDispatchMng',
    title: '파견관리',
    href: '/dispatchmain',
    icon: <PersonIcon style={{ fontSize: 18 }} />,
  },
  {
    type: 'group',
    menuId: 'mnManageGroup02',
    title: '설정',
  },
  {
    type: 'menu',
    menuId: 'mnManageDept',
    title: '부서정보관리',
    href: '/deptconfig',
    icon: <ArrowRightIcon style={{ fontSize: 'small' }} />,
  },
  {
    type: 'menu',
    menuId: 'mnManageGrade',
    title: '직급정보관리',
    href: '/gradeconfig',
    icon: <ArrowRightIcon style={{ fontSize: 'small' }} />,
  },
];

export function getMenus(roleName) {
  switch (roleName) {
    case 'ROLE_ADMIN':
      return menuMap_ADMIN; // menuMap_ADMIN;
      case 'ROLE_AGENCY':
        return menuMap_AGENCY; // menuMap_AGENCY;
      case 'ROLE_MANAGER':
      return menuMap_MANAGER; // menuMap_ADMIN;
    default:
      return [];
  }
}
