import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 0,
    alignItems: 'center',
    display: 'flex',
    height: 19,
  },
  label: {
    border: '0.1px dotted #3c63a8',
    textAlign: 'center',    
    whiteSpace: 'nowrap',
    height: 30,
    fontWeight: 500,
    lineHeight: 2,
    fontSize: 14
  },
  value: {
    backgroundColor: '#ffffff',
    color: '#1f1f1f',
    whiteSpace: 'nowrap',
    height: 10,
    paddingLeft: 10,
    fontSize: 14
  }

}));

const GroupLabel = props => {

  const classes = useStyles();
  const { isRef, label, labelWidth, value, noUnderbar, ...rest } = props;

  return (
    <Paper {...rest} className={(noUnderbar) ? classes.root : clsx(classes.root, classes.underbar)} elevation={0}>
      <div className={classes.label} 
        style={{minWidth: labelWidth }}>{label}</div>
      <div className={classes.value}></div>
    </Paper>
  );
};

export default GroupLabel;
