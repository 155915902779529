import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { FormControl, InputLabel } from '@material-ui/core';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  root: {},
}));

const InputCombo = props => {

  const classes = useStyles();
  const { style, onChange, items, label, value } = props;

  return (
    <FormControl className={classes.formControl} style={style} fullWidth={true}>
      <InputLabel id="ant-input-combo" style={{ color: '#3c63a8', fontWeight: 500 }}
      >{label}</InputLabel>
      <Select
        labelId="ant-input-combo"
        value={value}
        onChange={onChange}
      >
        {items && items.map((item) => {
          return <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
        })}
      </Select>
    </FormControl>
  );
};

InputCombo.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  items: PropTypes.array
};

export default InputCombo;
