import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 0,
    alignItems: 'center',
    display: 'flex',
    height: 19,
  },
  label: {
    border: '1px solid #c7c7c7',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    height: 20,
    fontWeight: 500,
    lineHeight: 2,
    fontSize: 11
  },
  normalLabel: {
    backgroundColor: '#CECECE',
  },
  refLabel: {
    backgroundColor: theme.palette.ain2color.light,
  },
  value: {
    backgroundColor: '#ffffff',
    color: '#1f1f1f',
    whiteSpace: 'nowrap',
    height: 20,
    paddingLeft: 10,
    fontSize: 14
  },
  underbar: {
    borderBottom: '1px solid #c7c7c7',
  }

}));

const LabelCard = props => {

  const classes = useStyles();
  const { isRef, label, labelWidth, value, valueStyle, noUnderbar, ...rest } = props;

  return (
    <Paper {...rest} className={(noUnderbar) ? classes.root : clsx(classes.root, classes.underbar)} elevation={0}>
      <div className={(isRef) ? clsx(classes.label, classes.refLabel) : clsx(classes.label, classes.normalLabel)}
        style={{ minWidth: labelWidth }}>{label}</div>
      {value !== undefined &&
        <div className={(noUnderbar) ? classes.value : clsx(classes.value, classes.underbar)} style={valueStyle !== undefined ? valueStyle : {}}>{value}</div>
      }
    </Paper>
  );
};

export default LabelCard;
