import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

// Style Class
const useStyles = makeStyles(theme => ({
  tHead: {
    textAlign: 'center',
    height: 38,
    padding: 2,
  },
  tCellPrice: {
    textAlign: 'right',
    width: 80,
  },
  tCellText: {
    textAlign: 'center',
    height: 40,
    fontWeight: 500,
  },
  tInputPrice: {
    textAlign: 'right',
    width: 80,
    paddingTop: 5,
    paddingBottom: 5,
    border: 0
  },
}));

const PayTaxSheetInputForm = (props) => {
  const classes = useStyles();
  const { onFormChange, empPayTaxData } = props;

  // console.log('empPayTaxData ::::: ', empPayTaxData);

  const sumPayTax = ((empPayTaxData && empPayTaxData.base) ? Number(empPayTaxData.base) : 0)
    + ((empPayTaxData && empPayTaxData.bonus) ? Number(empPayTaxData.bonus) : 0)
    + ((empPayTaxData && empPayTaxData.mealSubs) ? Number(empPayTaxData.mealSubs) : 0)
    + ((empPayTaxData && empPayTaxData.driveSubs) ? Number(empPayTaxData.driveSubs) : 0)
    + ((empPayTaxData && empPayTaxData.overworkBnf) ? Number(empPayTaxData.overworkBnf) : 0)
    + ((empPayTaxData && empPayTaxData.nightBnf) ? Number(empPayTaxData.nightBnf) : 0)
    + ((empPayTaxData && empPayTaxData.gradeBnf) ? Number(empPayTaxData.gradeBnf) : 0)
    + ((empPayTaxData && empPayTaxData.dispatchBnf) ? Number(empPayTaxData.dispatchBnf) : 0);

  const deduct = ((empPayTaxData && empPayTaxData.nationPention) ? Number(empPayTaxData.nationPention) : 0)
    + ((empPayTaxData && empPayTaxData.healthInsr) ? Number(empPayTaxData.healthInsr) : 0)
    + ((empPayTaxData && empPayTaxData.longRecu) ? Number(empPayTaxData.longRecu) : 0)
    + ((empPayTaxData && empPayTaxData.empInsr) ? Number(empPayTaxData.empInsr) : 0)
    + ((empPayTaxData && empPayTaxData.accidInsr) ? Number(empPayTaxData.accidInsr) : 0)
    + ((empPayTaxData && empPayTaxData.incomeTax) ? Number(empPayTaxData.incomeTax) : 0)
    + ((empPayTaxData && empPayTaxData.residentTax) ? Number(empPayTaxData.residentTax) : 0)
    + ((empPayTaxData && empPayTaxData.yecHealthInsr) ? Number(empPayTaxData.yecHealthInsr) : 0)
    + ((empPayTaxData && empPayTaxData.yecLongRecu) ? Number(empPayTaxData.yecLongRecu) : 0)
    + ((empPayTaxData && empPayTaxData.yecEmpInsr) ? Number(empPayTaxData.yecEmpInsr) : 0)
    + ((empPayTaxData && empPayTaxData.refundIntr) ? Number(empPayTaxData.refundIntr) : 0);
  const calcuPayTax = sumPayTax - deduct;

  return (
    <div style={{ width: '100%', paddingTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 30, }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2} className={classes.tHead}>인적사항</TableCell>
            <TableCell colSpan={5} className={classes.tHead}>기  본  급  여  및  제  수  당</TableCell>
            <TableCell colSpan={7} className={classes.tHead}>공  제   및   차  인  지  급  액</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tHead} style={{ width: 80 }}>직원번호</TableCell>
            <TableCell className={classes.tHead} style={{ width: 80 }}>성명</TableCell>
            <TableCell className={classes.tHead}>기본급</TableCell>
            <TableCell className={classes.tHead}>상여</TableCell>
            <TableCell className={classes.tHead}>식대보조금</TableCell>
            <TableCell className={classes.tHead}>자가운전<br />보조금</TableCell>
            <TableCell className={classes.tHead}></TableCell>
            <TableCell className={classes.tHead}>국민연금</TableCell>
            <TableCell className={classes.tHead}>건강보험</TableCell>
            <TableCell className={classes.tHead}>산재보험</TableCell>
            <TableCell className={classes.tHead}>고용보험</TableCell>
            <TableCell className={classes.tHead}>소득세</TableCell>
            <TableCell className={classes.tHead}>주민세</TableCell>
            <TableCell className={classes.tHead}></TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tHead}>입사일</TableCell>
            <TableCell className={classes.tHead}>직급</TableCell>
            <TableCell className={classes.tHead}>연장근로수당</TableCell>
            <TableCell className={classes.tHead}>야간근로수당</TableCell>
            <TableCell className={classes.tHead}>직책수당</TableCell>
            <TableCell className={classes.tHead}>파견수당</TableCell>
            <TableCell className={classes.tHead}></TableCell>
            <TableCell className={classes.tHead}>장기요양보험</TableCell>
            <TableCell className={classes.tHead}>건강보험<br />연말정산</TableCell>
            <TableCell className={classes.tHead}>장기요양보험<br />연말정산</TableCell>
            <TableCell className={classes.tHead}>고용보험<br />연말정산</TableCell>
            <TableCell className={classes.tHead}>환급이자</TableCell>
            <TableCell className={classes.tHead}></TableCell>
            <TableCell className={classes.tHead} style={{ backgroundColor: '#4D4D4D', color: '#ffffff' }}>공제합계</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tHead}>퇴사일</TableCell>
            <TableCell className={classes.tHead}>부서</TableCell>
            <TableCell className={classes.tHead}></TableCell>
            <TableCell className={classes.tHead}></TableCell>
            <TableCell className={classes.tHead}></TableCell>
            <TableCell className={classes.tHead}></TableCell>
            <TableCell className={classes.tHead} style={{ backgroundColor: '#4D4D4D', color: '#ffffff' }}>지급합계</TableCell>
            <TableCell className={classes.tHead}></TableCell>
            <TableCell className={classes.tHead}></TableCell>
            <TableCell className={classes.tHead}></TableCell>
            <TableCell className={classes.tHead}></TableCell>
            <TableCell className={classes.tHead}></TableCell>
            <TableCell className={classes.tHead}></TableCell>
            <TableCell className={classes.tHead} style={{ backgroundColor: '#4D4D4D', color: '#ffffff' }}>차인지급액</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.tCellText}>{(empPayTaxData) ? empPayTaxData.empNo : ''}</TableCell>
            <TableCell className={classes.tCellText}>{(empPayTaxData) ? empPayTaxData.empNm : ''}</TableCell>
            <TableCell className={classes.tCellPrice}>
              <input type='text' value={(empPayTaxData) ? Number(empPayTaxData.base).toLocaleString() : undefined} className={classes.tInputPrice}
                onChange={(e) => { onFormChange({ 'name': 'base', 'type': 'number', 'value': e.target.value }); }}
                onFocus={(e) => { e.target.select(); }}></input>
            </TableCell>
            <TableCell className={classes.tCellPrice}>
              <input type='text' value={(empPayTaxData) ? Number(empPayTaxData.bonus).toLocaleString() : undefined} className={classes.tInputPrice}
                onChange={(e) => { onFormChange({ 'name': 'bonus', 'type': 'number', 'value': e.target.value }); }}
                onFocus={(e) => { e.target.select(); }}></input>
            </TableCell>
            <TableCell className={classes.tCellPrice}>
              <input type='text' value={(empPayTaxData) ? Number(empPayTaxData.mealSubs).toLocaleString() : undefined} className={classes.tInputPrice}
                onChange={(e) => { onFormChange({ 'name': 'mealSubs', 'type': 'number', 'value': e.target.value }); }}
                onFocus={(e) => { e.target.select(); }}></input>
            </TableCell>
            <TableCell className={classes.tCellPrice}>
              <input type='text' value={(empPayTaxData) ? Number(empPayTaxData.driveSubs).toLocaleString() : undefined} className={classes.tInputPrice}
                onChange={(e) => { onFormChange({ 'name': 'driveSubs', 'type': 'number', 'value': e.target.value }); }}
                onFocus={(e) => { e.target.select(); }}></input>
            </TableCell>
            <TableCell className={classes.tCellPrice}></TableCell>
            <TableCell className={classes.tCellPrice}>
              <input type='text' value={(empPayTaxData) ? Number(empPayTaxData.nationPention).toLocaleString() : undefined} className={classes.tInputPrice}
                onChange={(e) => { onFormChange({ 'name': 'nationPention', 'type': 'number', 'value': e.target.value }); }}
                onFocus={(e) => { e.target.select(); }}></input>
            </TableCell>
            <TableCell className={classes.tCellPrice}>
              <input type='text' value={(empPayTaxData) ? Number(empPayTaxData.healthInsr).toLocaleString() : undefined} className={classes.tInputPrice}
                onChange={(e) => { onFormChange({ 'name': 'healthInsr', 'type': 'number', 'value': e.target.value }); }}
                onFocus={(e) => { e.target.select(); }}></input>
            </TableCell>
            <TableCell className={classes.tCellPrice}>
              <input type='text' value={(empPayTaxData) ? Number(empPayTaxData.accidInsr).toLocaleString() : undefined} className={classes.tInputPrice}
                onChange={(e) => { onFormChange({ 'name': 'accidInsr', 'type': 'number', 'value': e.target.value }); }}
                onFocus={(e) => { e.target.select(); }}></input>
            </TableCell>
            <TableCell className={classes.tCellPrice}>
              <input type='text' value={(empPayTaxData) ? Number(empPayTaxData.empInsr).toLocaleString() : undefined} className={classes.tInputPrice}
                onChange={(e) => { onFormChange({ 'name': 'empInsr', 'type': 'number', 'value': e.target.value }); }}
                onFocus={(e) => { e.target.select(); }}></input>
            </TableCell>
            <TableCell className={classes.tCellPrice}>
              <input type='text' value={(empPayTaxData) ? Number(empPayTaxData.incomeTax).toLocaleString() : undefined} className={classes.tInputPrice}
                onChange={(e) => { onFormChange({ 'name': 'incomeTax', 'type': 'number', 'value': e.target.value }); }}
                onFocus={(e) => { e.target.select(); }}></input>
            </TableCell>
            <TableCell className={classes.tCellPrice}>
              <input type='text' value={(empPayTaxData) ? Number(empPayTaxData.residentTax).toLocaleString() : undefined} className={classes.tInputPrice}
                onChange={(e) => { onFormChange({ 'name': 'residentTax', 'type': 'number', 'value': e.target.value }); }}
                onFocus={(e) => { e.target.select(); }}></input>
            </TableCell>
            <TableCell className={classes.tCellPrice} style={{ textAlign: 'right' }}></TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tCellText}>2020.01.01</TableCell>
            <TableCell className={classes.tCellText}>{(empPayTaxData) ? empPayTaxData.gradeStr : ''}</TableCell>
            <TableCell className={classes.tCellPrice}>
              <input type='text' value={(empPayTaxData) ? Number(empPayTaxData.overworkBnf).toLocaleString() : undefined} className={classes.tInputPrice}
                onChange={(e) => { onFormChange({ 'name': 'overworkBnf', 'type': 'number', 'value': e.target.value }); }}
                onFocus={(e) => { e.target.select(); }}></input>
            </TableCell>
            <TableCell className={classes.tCellPrice}>
              <input type='text' value={(empPayTaxData) ? Number(empPayTaxData.nightBnf).toLocaleString() : undefined} className={classes.tInputPrice}
                onChange={(e) => { onFormChange({ 'name': 'nightBnf', 'type': 'number', 'value': e.target.value }); }}
                onFocus={(e) => { e.target.select(); }}></input>
            </TableCell>
            <TableCell className={classes.tCellPrice}>
              <input type='text' value={(empPayTaxData) ? Number(empPayTaxData.gradeBnf).toLocaleString() : undefined} className={classes.tInputPrice}
                onChange={(e) => { onFormChange({ 'name': 'gradeBnf', 'type': 'number', 'value': e.target.value }); }}
                onFocus={(e) => { e.target.select(); }}></input>
            </TableCell>
            <TableCell className={classes.tCellPrice}>
              <input type='text' value={(empPayTaxData) ? Number(empPayTaxData.dispatchBnf).toLocaleString() : undefined} className={classes.tInputPrice}
                onChange={(e) => { onFormChange({ 'name': 'dispatchBnf', 'type': 'number', 'value': e.target.value }); }}
                onFocus={(e) => { e.target.select(); }}></input>
            </TableCell>
            <TableCell style={{ textAlign: 'right' }}></TableCell>
            <TableCell className={classes.tCellPrice}>
              <input type='text' value={(empPayTaxData) ? Number(empPayTaxData.longRecu).toLocaleString() : undefined} className={classes.tInputPrice}
                onChange={(e) => { onFormChange({ 'name': 'longRecu', 'type': 'number', 'value': e.target.value }); }}
                onFocus={(e) => { e.target.select(); }}></input>
            </TableCell>
            <TableCell className={classes.tCellPrice}>
              <input type='text' value={(empPayTaxData) ? Number(empPayTaxData.yecHealthInsr).toLocaleString() : undefined} className={classes.tInputPrice}
                onChange={(e) => { onFormChange({ 'name': 'yecHealthInsr', 'type': 'number', 'value': e.target.value }); }}
                onFocus={(e) => { e.target.select(); }}></input>
            </TableCell>
            <TableCell className={classes.tCellPrice}>
              <input type='text' value={(empPayTaxData) ? Number(empPayTaxData.yecLongRecu).toLocaleString() : undefined} className={classes.tInputPrice}
                onChange={(e) => { onFormChange({ 'name': 'yecLongRecu', 'type': 'number', 'value': e.target.value }); }}
                onFocus={(e) => { e.target.select(); }}></input>
            </TableCell>
            <TableCell className={classes.tCellPrice}>
              <input type='text' value={(empPayTaxData) ? Number(empPayTaxData.yecEmpInsr).toLocaleString() : undefined} className={classes.tInputPrice}
                onChange={(e) => { onFormChange({ 'name': 'yecEmpInsr', 'type': 'number', 'value': e.target.value }); }}
                onFocus={(e) => { e.target.select(); }}></input>
            </TableCell>
            <TableCell className={classes.tCellPrice}>
              <input type='text' value={(empPayTaxData) ? Number(empPayTaxData.refundIntr).toLocaleString() : undefined} className={classes.tInputPrice}
                onChange={(e) => { onFormChange({ 'name': 'refundIntr', 'type': 'number', 'value': e.target.value }); }}
                onFocus={(e) => { e.target.select(); }}></input>
            </TableCell>
            <TableCell className={classes.tCellPrice}></TableCell>
            <TableCell className={classes.tCellPrice} style={{ textAlign: 'right' }}>{Number(deduct).toLocaleString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tCellText}>2020.13.32</TableCell>
            <TableCell className={classes.tCellText}>{(empPayTaxData) ? empPayTaxData.deptStr : ''}</TableCell>
            <TableCell className={classes.tCellPrice}></TableCell>
            <TableCell className={classes.tCellPrice}></TableCell>
            <TableCell className={classes.tCellPrice}></TableCell>
            <TableCell className={classes.tCellPrice}></TableCell>
            <TableCell style={{ textAlign: 'right' }}>{Number(sumPayTax).toLocaleString()}</TableCell>
            <TableCell className={classes.tCellPrice}></TableCell>
            <TableCell className={classes.tCellPrice}></TableCell>
            <TableCell className={classes.tCellPrice}></TableCell>
            <TableCell className={classes.tCellPrice}></TableCell>
            <TableCell className={classes.tCellPrice}></TableCell>
            <TableCell className={classes.tCellPrice}></TableCell>
            <TableCell style={{ textAlign: 'right' }}>{Number(calcuPayTax).toLocaleString()}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div >
  );
};

export default PayTaxSheetInputForm;
