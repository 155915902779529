import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { Grid, Divider } from '@material-ui/core';
import ContentPaneTitleBar from 'components/container/ContentPaneTitleBar';
import { LabelCard, GroupLabel } from 'components/card';
import { DispPersonList } from '..';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    minWidth: 780,
  },
  main: {
    boxShadow: 'none',
    border: '1px solid #d0d0d0',
    borderRadius: 0,
  },
  content: {
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    borderRadius: 0,
    padding: 10,
  },
}));


const DispDetail = (props) => {
  const { className, dispData, onClickModifyDisp, onClickDeleteDisp, ...rest } = props;
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {(dispData !== undefined) &&
        <div className={classes.main}>
          <ContentPaneTitleBar title='외주인력 상세정보'
            style={{ backgroundColor: '#777777' }}
            buttons={[{
              name: '수정', action: 'edit',
              func: onClickModifyDisp,
            }, {
              name: '삭제', action: 'delete',
              confirm: { title: '외주인력 삭제', content: '정말 삭제하시겠습니까?', func: onClickDeleteDisp },
            },]}
          />
          <div className={classes.content} >
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="외주인력 번호" labelWidth={120} value={dispData.outsNo} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="외주인력 이름" labelWidth={120} value={dispData.outsNm} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="직급" labelWidth={120} value={dispData.gradeStr} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="주민등록번호" labelWidth={120} value={dispData.residentNo} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="전화번호" labelWidth={120} value={dispData.phone} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="이메일" labelWidth={120} value={dispData.email} />
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: 0 }}>
                <LabelCard label="주소" labelWidth={120} value={dispData.addrStr} />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="등록일(작업자)" labelWidth={120} value={(dispData.regUserId && dispData.regUserId !== '') ? dispData.regDt + ' (' + dispData.regUserId + ')' : '-'} />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="수정일(작업자)" labelWidth={120} value={(dispData.modUserId && dispData.modUserId !== '') ? dispData.modDt + ' (' + dispData.modUserId + ')' : '-'} />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 30, paddingBottom: 0 }}>
                <GroupLabel label="파 견 이 력" labelWidth={120} />
              </Grid>
              <Grid item xs={12}>
                <DispPersonList tableData={dispData.dateList} />
              </Grid>
            </Grid>
          </div>
        </div>
      }
    </div>
  );
};

export default DispDetail;
