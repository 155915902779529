import React from 'react';

import { Grid, TextField } from '@material-ui/core';

const DeptInputForm = (props) => {
  const { onFormChange, inputData, isNew } = props;
  // console.log('inputData --> ', inputData);
  // console.log('isNew --> ', isNew);

  return (
    <div style={{ width: '100%', paddingTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 30, }}>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <TextField label="부서이름" fullWidth
            value={inputData.deptNm}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({...inputData, 'deptNm': e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField label="부서코드" fullWidth
            disabled={true}
            value={(isNew) ? '[자동 생성됨]' : inputData.deptCd}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField label="설명" fullWidth
            value={inputData.deptCmt}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({...inputData, 'deptCmt': e.target.value });
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DeptInputForm;
