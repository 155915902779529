import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CopyIcon from '@material-ui/icons/FileCopy';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import EmailIcon from '@material-ui/icons/Email';
import PersonIcon from '@material-ui/icons/Person';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RefreshIcon from '@material-ui/icons/Refresh';

import useInform from 'common/hooks/useInform';
import AntRectButton from 'components/button/AntRectButton';

const useStyles = makeStyles(theme => ({
    titleBar: {
        boxShadow: 'none',
        margin: 0,
        padding: '5px 10px',
        backgroundColor: '#d8d8d8',
        borderBottom: '1px dashed #9e9b9b',
        borderRadius: 0,
        height: 28,
        display: 'flex',
        justifyContent: 'space-between',
    },
    title: {
        fontSize: 12,
        fontWeight: 500,
    },
    actions: {
        fontWeight: 'bold',
        height: 20,
        fontSize: 12,
        marginTop: -1,
        marginLeft: 20,
    }
}));

const ContentPaneTitleBar = (props) => {
    const { title, buttons, style } = props;
    const classes = useStyles();

    const { showInform } = useInform();
    const showConfirm = (title, content, func) => {
        showInform(title, content, 'CONFIRM', result => {
            if (result === 'Y') {
                func();
            } else {
                return;
            }
        });
    };

    const renderIconByAction = (param) => {
        if (param.icon !== undefined) {
            return param.icon;
        } else {
            switch (param.action) {
                case 'edit':
                    return <EditIcon style={{ width: 16, marginRight: 3 }} />;
                case 'copy':
                    return <CopyIcon style={{ width: 16, marginRight: 3 }} />;
                case 'pdf':
                    return <PdfIcon style={{ width: 16, marginRight: 3 }} />;
                case 'email':
                    return <EmailIcon style={{ width: 16, marginRight: 3 }} />;
                case 'delete':
                    return <DeleteForeverIcon style={{ width: 16, marginRight: 3 }} />;
                case 'user':
                    return <PersonIcon style={{ width: 16, marginRight: 3 }} />;
                case 'refresh':
                    return <RefreshIcon style={{ width: 16, marginRight: 3 }} />;
                default:
                    return '';
            }
        }
    }

    return (
        <div className={classes.titleBar}>
            <Typography className={classes.title}>{title}</Typography>
            <div>
                {buttons.map((e, index) => {
                    return <AntRectButton key={index} variant="contained" style={style}
                        onClick={(e.confirm === undefined) ? e.func : () => showConfirm(e.confirm.title, e.confirm.content, e.confirm.func)}
                        disableElevation className={classes.actions}>
                        {renderIconByAction(e)}
                        {e.name}
                    </AntRectButton>
                })}
            </div>
        </div>
    );
};

export default ContentPaneTitleBar;
