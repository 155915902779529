import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import Timeline, { TimelineHeaders, SidebarHeader, DateHeader } from 'react-calendar-timeline'
// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css';
import './timelineStyle.css'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    minWidth: 780,
  },
  main: {
    boxShadow: 'none',
    border: '0px solid #d0d0d0',
    borderRadius: 0,
  },
  content: {
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    borderRadius: 0,
    padding: 10,
  },
  time_side: {
    color: '#ffffff',
    width: '150px',
    border: '1px solid #bbb',
    textAlign: 'center',
    paddingTop: '24px'
  }
}));

function getUniqueListBy(arr, key) {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}

const DispTimeline = (props) => {
  const { className, outsData, onClickModifyDisp, onClickDeleteDisp, ...rest } = props;
  const classes = useStyles();

  const dispGroups = (outsData.data && outsData.data.length > 0) ?
    (getUniqueListBy(outsData.data, 'outsId')).map(e => ({ id: Number(e.outsId.substring(2)), title: e.outsNm })) : [];

  const dispItems = (outsData.data && outsData.data.length > 0) ? outsData.data.map(e => {
    return {
      id: e.outsNo + e.startDt,
      group: Number(e.outsId.substring(2)),
      title: e.memo,
      start_time: moment(e.startDt, "YYYY-MM-DD"),
      end_time: moment(e.endDt, "YYYY-MM-DD")
    };
  }) : [];

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {(dispGroups !== undefined && dispGroups.length > 0) &&
        <div className={classes.main}>
          <Timeline
            groups={dispGroups}
            items={dispItems}
            defaultTimeStart={moment().add(-3, 'month')}
            defaultTimeEnd={moment().add(6, 'month')}
          >
            <TimelineHeaders>
              <SidebarHeader>
                {({ getRootProps }) => {
                  return <div {...getRootProps()} className={classes.time_side}>인력 \ 기간</div>
                }}
              </SidebarHeader>
              <DateHeader unit="year" />
              <DateHeader
                unit="month"
                labelFormat="M 월"
                style={{ height: 50, color: '#282828' }}
              />
            </TimelineHeaders>
          </Timeline>
          <div style={{borderBottom: '1px dotted #8e8e8e', height: 20, marginTop: 20}}></div>
        </div>
      }
    </div>
  );
};

export default DispTimeline;
