import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { Grid } from '@material-ui/core';
import ContentPaneTitleBar from 'components/container/ContentPaneTitleBar';
import { LabelCard } from 'components/card';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    minWidth: 780,
  },
  main: {
    boxShadow: 'none',
    border: '1px solid #d0d0d0',
    borderRadius: 0,
  },
  content: {
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    borderRadius: 0,
    padding: 10,
  },
}));

const AdminDetail = (props) => {
  const { className, adminData, onClickModifyAdmin, onClickDeleteAdmin, ...rest } = props;
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {(adminData !== undefined) &&
        <div className={classes.main}>
          <ContentPaneTitleBar title='관리자 상세정보'
            buttons={[
              {
                name: '수정', action: 'edit',
                func: onClickModifyAdmin,
              },
              {
                name: '삭제', action: 'delete',
                confirm: { title: '관리자 삭제', content: `'${adminData.adminId}'을(를) 정말 삭제하시겠습니까?`, func: onClickDeleteAdmin },
              },
            ]}
          />
          <div className={classes.content} >
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="관리자 이름" labelWidth={120} value={adminData.adminNm} />
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="관리자 아이디" labelWidth={120} value={adminData.adminId} />
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: 0 }}>
                <LabelCard label="시스템 권한" labelWidth={120} value={adminData.role} />
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="이메일" labelWidth={120} value={adminData.email} />
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="전화번호" labelWidth={120} value={adminData.telNo} />
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="등록일(작업자)" labelWidth={120} value={adminData.regDt + ' (' + adminData.regUserId + ')'} />
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="수정일(작업자)" labelWidth={120} value={(adminData.modDt) ? adminData.modDt + ' (' + adminData.modUserId + ')' : '-'} />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </div>
        </div>
      }
    </div>
  );
};

export default AdminDetail;
