import { requestPostWithJsonAPI } from 'common/services/AntsRequester';

export const setClientsWithFunc = async (f, param) => {
    let listData = [{ name: '선택', value: '-' }];
    f(await requestPostWithJsonAPI('getAgencyClientList', param).then((response) => {
        if (response && response.status && response.status === 200) {
            if (response.data && response.data.status && response.data.status.result === 'success') {
                if (response.data.listData !== null) {
                    var data = response.data.listData.map(e => ({ name: e.name, value: e.value }));
                    return (listData.concat(data));
                }
            }
        }
        return listData;
    }));
};
