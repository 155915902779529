import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import { Paper, Chip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 0,
    borderBottom: '0px solid #d2d2d2',
    alignItems: 'center',
    display: 'flex',
    height: 30,
  },
  label: {
    borderRadius: 0,
    border: '1px solid #d2d2d2',
    textAlign: 'center',
    backgroundColor: '#ddead7',
    whiteSpace: 'nowrap',
    height: 30,
    fontSize: 14,
    lineHeight: 2,
  },
  value: {
    borderRadius: 0,
    borderBottom: '0px solid #d2d2d2',
    backgroundColor: '#ffffff',
    color: '#1f1f1f',
    whiteSpace: 'nowrap',
    height: 30,
    paddingLeft: 10,
  }
}));

const LabelChipsCard = props => {

  const classes = useStyles();
  const { className, labelClass, valueClass, style, items, label, labelWidth, values, ...rest } = props;

  return (
    <Paper {...rest} className={clsx(classes.root, className)} style={style} elevation={0}>
      <div className={(labelClass) ? labelClass : classes.label} style={{minWidth: labelWidth}}>{label}</div>
      <div className={(valueClass) ? valueClass : classes.value}>{
        values.map((e) => (
          <Chip key={e.id} label={e.label} style={{marginRight: 4}} />
        ))
        }</div>
    </Paper>
  );
};

export default LabelChipsCard;
