
import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { MenuHeader } from 'layouts/main/blocks/menu';

// Style Class
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#efefef',
  },
}));

const ConstructMain = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MenuHeader />

      <div style={{ textAlign: 'center', height: 400, paddingTop: 140, fontSize: 80 }}>
        <div style={{ fontSize: 80, fontFamily: 'Luminari' }}>
          ... in planning ...
        </div>
        <div style={{ fontSize: 40 }}>
          작업중
        </div>
      </div>
    </div >
  );
};

export default ConstructMain;