import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 0,
    borderBottom: '0px solid #d2d2d2',
    display: 'flex',
  },
  label: {
    borderRadius: 0,
    border: '1px solid #d2d2d2',
    textAlign: 'center',
    backgroundColor: '#ddead7',
    whiteSpace: 'nowrap',
    height: 30,
    fontSize: 14,
    lineHeight: 2,
  },
  value: {
    width: '100%',
    borderRadius: 0,
    border: '1px solid #d2d2d2',
    backgroundColor: '#ffffff',
    color: '#1f1f1f',
    marginLeft: 10,
    padding: 5,
    whiteSpace: 'pre-line',
  },
}));

const LabelTextareaCard = props => {

  const classes = useStyles();
  const { className, labelClass, valueClass, style, valueStyle, items, label, labelWidth, value, ...rest } = props;

  return (
    <Paper {...rest} className={clsx(classes.root, className)} style={style} elevation={0}>
      <div className={(labelClass) ? labelClass : classes.label} style={{ minWidth: labelWidth }}>{label}</div>
      <div className={(valueClass) ? valueClass : classes.value} style={valueStyle}>
          {value}
      </div>
    </Paper>
  );
};

export default LabelTextareaCard;
