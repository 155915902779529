import React, { useState } from 'react';

import { Grid, TextField } from '@material-ui/core';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { GroupLabel } from 'components/card';

const YeongumInputForm = (props) => {
  const { onFormChange, empPayrollData } = props;

  const [ygData, setYgData] = useState('');

  const showFile = async (e) => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => {
      const fileText = (e.target.result)
      // const text = new TextEncoder("UTF-8").encode(e.target.result);
      const lines = fileText.split(/\r?\n/);
      // console.log('lines :: ', lines.length);
      for (let i = 0; i < lines.length; i++) {
        // console.log('line[' + (i + 1) + '] - ' + lines[i] + ' >>>>>>>> ' + (lines[i].split(',').length));
        // lines[i].split(',').length

        const columns = lines[i].split(',');
        if (columns.length >= 7) {

        }
      }
      setYgData(fileText)
      onFormChange({ 'name': 'ygData', 'value': fileText });
    };
    reader.readAsText(e.target.files[0], "UTF-8"); // ??????????????????????????????????????
  }

  // console.log('empPayrollData ::::: ', empPayrollData);
  return (
    <div style={{ width: '100%', paddingTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 30, }}>
      <Grid container spacing={6}>
        {/* <Grid item xs={12} md={6}>
          <TextField label="직원 번호" fullWidth
            disabled={(empPayrollData && empPayrollData.empNo !== '') ? true : false}
            value={(empPayrollData) ? empPayrollData.empNo : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({ 'name': 'empNo', 'value': e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField label="직원 이름" fullWidth
            value={(empPayrollData) ? empPayrollData.empNm : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 0, marginTop: 10 }}><GroupLabel label="기 본 급 여 및 제 수 당" labelWidth={140} /></Grid> */}
        <Grid item xs={12}>
          <div>
            <input type="file" onChange={showFile} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            {ygData && ygData !== '' && <Table>
              <TableBody>
                {ygData.split(/\r?\n/).map((row, index) => {
                  const columns = row.split(',');
                  if (columns.length >= 7) {
                    return (
                      <TableRow
                        hover
                        key={index}
                        style={{ cursor: 'pointer' }}
                      >
                        {columns.map((cell, index) => {
                          return (
                            <TableCell key={index}>{cell}</TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
            }</div>
        </Grid>
      </Grid >
    </div >
  );
};

export default YeongumInputForm;
