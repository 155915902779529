const checked = (value, options) => {
  if (value !== true) {
    return options.message || 'must be checked';
  }
};


/**
 * 파일 체크
 */
export function fileUploadChecked(fileValue, imageId) {

  // alert("fileValue : " + fileValue);
  // alert("imageId : " + imageId);
  // 확장자 체크
  var fileExt = getFileExt(fileValue);
  // alert("fileExt : "  + fileExt);
  
  fileExt = fileExt.toLowerCase();
  if(fileExt !== "png" && fileExt !== "jpg" && fileExt !== "jpeg" && fileExt !== "pdf") {
    alert("이미지 파일만 첨부해주세요. (png, jpg, jpeg, pdf)");
    return false;
  }
  // 크기 체크(10mb 제한)
  var maxSize = 10 * 1024 * 1024;
  var sizeinbytes;
  /* if(window.ActiveXObject){
    var fso = new ActiveXObject("Scripting.FileSystemObject");
    var filepath = document.getElementById(imageId).value;
    var thefile = fso.getFile(filepath);
    sizeinbytes = thefile.size;
  }else{
    sizeinbytes = document.getElementById(imageId).files[0].size;
  } */
  sizeinbytes = document.getElementById(imageId).files[0].size;
  if(sizeinbytes > maxSize) {
    alert("파일 크기는 10MB 이내로 등록 가능합니다.");
    return false;
  }
  
  return true;
};

/**
 * 파일 확장자 확인
 */
export function getFileExt(filePath) {
  var index = -1;
  index = filePath.lastIndexOf('.');
  var ext = "";
  if(index !== -1) {
    ext = filePath.substring(index+1, filePath.len);
  } else {
    ext = "";
  }
  return ext;
}

export default {
  checked
  ,fileUploadChecked
  ,getFileExt
};