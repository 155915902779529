import React from 'react';

import { Grid, TextField } from '@material-ui/core';

const ClientInputForm = (props) => {
  const { onFormChange, inputData, isNew } = props;
  return (
    <div style={{ width: '100%', paddingTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 30, }}>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <TextField label="회사이름" fullWidth
            value={inputData.clientNm}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({...inputData, 'clientNm': e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField label="회사코드" fullWidth
            disabled={true}
            value={(isNew) ? '[자동 생성됨]' : inputData.clientCd}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField label="대표이름" fullWidth
            value={inputData.ceoNm}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({...inputData, 'ceoNm': e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField label="사업자번호" fullWidth
            value={inputData.businessNo}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({...inputData, 'businessNo': e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField label="전화번호" fullWidth
            value={inputData.telNo}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({...inputData, 'telNo': e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField label="이메일" fullWidth
            value={inputData.email}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({...inputData, 'email': e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <TextField label="회사주소" fullWidth
            value={inputData.clientAddr}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({...inputData, 'clientAddr': e.target.value });
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ClientInputForm;
