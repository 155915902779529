import palette from '../palette';

export default {
  root: {
    borderTop: `1px solid ${palette.divider}`,
    borderBottom: `1px solid ${palette.divider}`,
    borderLeft: `1px solid ${palette.divider}`,
    borderRight: `1px solid ${palette.divider}`,
    padding: '4px 2px',
    fontSize: 12,
  },
  head: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 'initial'
  },
  stickyHeader: {
    backgroundColor: '#cecece'
  }
};
