import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    color: '#ffffff',
    padding: theme.spacing(4),
    textAlign: 'end'
  },
  copyright: {
    color: '#0C4274',
    fontWeight: 200,
    fontSize: 14,
  },
  catchphrase: {
    color: '#0C4274',
    fontWeight: 200,
    fontSize: 14,
  }

}));

const Footer = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography className={classes.copyright}>
        &copy;{' '}
        <RouterLink to="/" style={{ color: '#FA6077' }}>
          antsome, co. - paysome
        </RouterLink>
        &nbsp;&nbsp;2022.
      </Typography>
      <Typography className={classes.catchphrase}>
        Ideas sprouting in the coming and going disputes.
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
