import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { Grid, Divider } from '@material-ui/core';
import ContentPaneTitleBar from 'components/container/ContentPaneTitleBar';
import { LabelCard } from 'components/card';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    minWidth: 780,
  },
  main: {
    boxShadow: 'none',
    border: '1px solid #d0d0d0',
    borderRadius: 0,
  },
  content: {
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    borderRadius: 0,
    padding: 10,
  },
}));


const EmpDetail = (props) => {
  const { className, empData, onClickModifyEmp, onClickDeleteEmp, ...rest } = props;
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {(empData !== undefined) &&
        <div className={classes.main}>
          <ContentPaneTitleBar title='직원 상세정보'
            style={{ backgroundColor: '#777777' }}
            buttons={[{
              name: '수정', action: 'edit',
              func: onClickModifyEmp,
            }, {
              name: '삭제', action: 'delete',
              confirm: { title: '직원 삭제', content: '정말 삭제하시겠습니까?', func: onClickDeleteEmp },
            },]}
          />
          <div className={classes.content} >
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="직원 번호" labelWidth={120} value={empData.empNo} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="직원 이름" labelWidth={120} value={empData.empNm} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="직급" labelWidth={120} value={empData.gradeStr} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="부서" labelWidth={120} value={empData.deptStr} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="입사일" labelWidth={120} value={empData.enterDt} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="전화번호" labelWidth={120} value={empData.phone} />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="이메일" labelWidth={120} value={empData.email} />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="주소" labelWidth={120} value={empData.addrStr} />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="주민등록번호" labelWidth={120} value={empData.residentNo} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="퇴사일" labelWidth={120} value={empData.retireDt} />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="등록일(작업자)" labelWidth={120} value={(empData.regUserId && empData.regUserId !== '') ? empData.regDt + ' (' + empData.regUserId + ')' : '-'} />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="수정일(작업자)" labelWidth={120} value={(empData.modUserId && empData.modUserId !== '') ? empData.modDt + ' (' + empData.modUserId + ')' : '-'} />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
            <Divider style={{ marginTop: 20, marginBottom: 20 }}></Divider>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="국민연금 번호" labelWidth={120} value={empData.yeonkumNo} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="건강보험증 번호" labelWidth={120} value={empData.gungangNo} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="고용보험 원부번호" labelWidth={120} value={empData.goyongNo} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="산재보험 번호" labelWidth={120} value={empData.sanjaeNo} />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
            <Divider style={{ marginTop: 20, marginBottom: 20 }}></Divider>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="급여통장은행" labelWidth={120} value={empData.bankNm} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
                <LabelCard label="급여통장 계좌번호" labelWidth={120} value={empData.accountNo} />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </div>
        </div>
      }
    </div>
  );
};

export default EmpDetail;
