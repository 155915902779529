import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Input, Checkbox } from '@material-ui/core';
import { FormControl, InputLabel, ListItemText } from '@material-ui/core';

import Select from '@material-ui/core/Select';
import MuiMenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  menuRoot: {
    height: 28,
  },
}));

const InputMultiCombo = props => {

  const classes = useStyles();
  const { style, onChange, items, label, value } = props;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <FormControl className={classes.formControl} style={style} fullWidth={true}>
      <InputLabel id="ant-input-multicombo" style={{ color: '#3c63a8', fontWeight: 500 }}>{label}</InputLabel>
      <Select labelId="ant-input-multicombo"
        multiple
        value={value}
        onChange={onChange}
        input={<Input />}
        renderValue={(selected) => {
          return selected.map((e) => {
            var selectedItem = items.find(m => m.value === e);
            return (selectedItem) ? selectedItem.name : '';
          }).join(', ');
        }}
        MenuProps={MenuProps}
      >
        {items && items.map((item) => {
          return <MuiMenuItem className={classes.menuRoot} key={item.value} value={item.value}>
            <Checkbox checked={value.indexOf(item.value) > -1} />
            <ListItemText primary={item.name} />
          </MuiMenuItem>
        })}
      </Select>
    </FormControl>
  );
};

InputMultiCombo.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
  items: PropTypes.array
};

export default InputMultiCombo;
