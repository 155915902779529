
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { MenuHeader } from 'layouts/main/blocks/menu';
import { CodeToolbar, CodeList, CodeDetail } from '.';
import { CodeInputForm, CodeGroupMngForm } from '.';

import { AntDialog } from 'components/dialog';
import { requestPostWithJsonAPI } from 'common/services/AntsRequester';

import useAlert from 'common/hooks/useAlert';

// Style Class
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#efefef',
  },
}));
// Row Count
const rowCount = 15;

const CodeMain = () => {
  const classes = useStyles();
  const { showAlert } = useAlert();

  const [tableData, setTableData] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [totalCnt, setTotalCnt] = useState(0);

  // option
  const [keyword, setKeyword] = useState('');
  const [groupOptionData, setGroupOptionData] = useState([]);
  const [selectedGroupCode, setSelectedGroupCode] = useState('-');
  // regist dialog
  const [codeRegistOpen, setCodeRegistOpen] = useState(false);
  const handleRegistDialogClose = () => {
    setCodeRegistOpen(false);
  };
  const handleRegistDialogOpen = () => {
    setCodeRegistOpen(true);
  };
  // modify dialog
  const [codeModifyOpen, setCodeModifyOpen] = useState(false);
  const handleModifyDialogClose = () => {
    setCodeModifyOpen(false);
  };
  const handleModifyDialogOpen = () => {
    setCodeModifyOpen(true);
  };
  // code group mng dialog
  const [codeGroupMngOpen, setCodeGroupMngOpen] = useState(false);
  const handleCodeGroupMngOpen = () => {
    setCodeGroupMngOpen(true);
  };
  const handleCodeGroupMngClose = () => {
    setCodeGroupMngOpen(false);
  };
  // detail
  const [codeDetailData, setCodeDetailData] = useState(undefined);
  const handleClickListRow = (data) => {
    setCodeDetailData(data);
  };
  // regist/modify input
  const [codeInputData, setCodeInputData] = useState({
  });

  useEffect(() => {
    initGroupOptionData();
    handleClickSearch(1);
  }, []);

  const initGroupOptionData = () => {
    requestPostWithJsonAPI('getGroupCodeList', {}).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          var listData = [];
          if (response.data.listData !== null) {
            listData = response.data.listData;
          }
          listData.unshift({ groupCode: '-', name: '전체', code: '-' })
          setGroupOptionData(listData);
        }
      }
    });
  };

  const handleClickSearch = (pageNo, paramGroupCode, paramKeyword) => {
    setCodeDetailData(undefined);
    requestPostWithJsonAPI('getCommonCodePagingList', {
      page: pageNo,
      keyword: (paramKeyword) ? paramKeyword : keyword,
      group_code: (paramGroupCode) ? paramGroupCode : selectedGroupCode,
      page_row_num: rowCount
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          setCurPage(pageNo);
          setTableData({ data: response.data.listData });
          setTotalCnt(Number(response.data.listTotal));
        }
      }
    });
  };

  // 코드 생성
  const handleClickCreateCode = () => {
    requestPostWithJsonAPI('setCommonCodeCreate', {
      group_code: codeInputData.grpCd,
      detail_code: codeInputData.dtlCd,
      detail_name: codeInputData.dtlNm,
      code_order: parseInt(codeInputData.cdOrd, 10),
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          handleRegistDialogClose();
          showAlert('codecreate', `'${codeInputData.dtlCd}' 코드정보가 생성되었습니다.`, 'success', 3000);
        } else {
          if (response.data.status.resultCode === 'duplicate') {
            showAlert('codeduplicate', `코드값이 중복되었습니다.`, 'error', 5000);
          }
        }
      }
    });
  }

  // 코드 삭제
  const handleClickDeleteCode = () => {
    requestPostWithJsonAPI('setCommonCodeDelete', {
      group_code: codeDetailData.grpCd,
      detail_code: codeDetailData.dtlCd,
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          showAlert('codedelete', `'${codeDetailData.dtlCd} - ${codeDetailData.dtlNm}' 코드정보가 삭제되었습니다.`, 'success', 3000);
        } else {
          showAlert('codedeleteerror', '코드삭제 작업이 실패 하였습니다.', 'error', 5000);
        }
      }
    });
  }

  // 코드 수정 다이얼로그 표시
  const handleClickModifyCode = () => {
    setCodeInputData(codeDetailData);
    handleModifyDialogOpen();
  }
  // 코드 수정
  const handleClickEditCode = () => {
    requestPostWithJsonAPI('setCommonCodeEdit', {
      group_code: codeInputData.grpCd,
      detail_code: codeInputData.dtlCd,
      detail_name: codeInputData.dtlNm,
      code_order: parseInt(codeInputData.cdOrd, 10),
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          handleModifyDialogClose();
          showAlert('codeedit', '코드정보가 변경되었습니다.', 'success', 3000);
        } else {
          showAlert('codeediterror', '코드정보 변경 작업이 실패 하였습니다.', 'error', 5000);
        }
      }
    });
  }

  // 툴바에서 파라미터 변경 이벤트 처리
  const handleChangeToolbarOption = (data) => {
    if (data.name === 'selectGroupCode') {
      setSelectedGroupCode(data.value);
      // select list
      handleClickSearch(1, data.value);
    } else if (data.name === 'keyword') {
      setKeyword(data.value);
      if (data.key === 'Enter') {
        handleClickSearch(1, undefined, data.value);
      }
    }
  }
  const handleClickToolbarButton = (e) => {
    if (e.currentTarget.id === 'code-toolbar-search') {
      handleClickSearch(1);
    } else if (e.currentTarget.id === 'code-toolbar-regist') {
      handleRegistDialogOpen();
    } else if (e.currentTarget.id === 'code-toolbar-groupmng') {
      handleCodeGroupMngOpen();
    }
  }

  const handleChangeInput = (data) => {
    // deep clone
    const temp = {};
    for (let key of Object.keys(codeInputData)) {
      temp[key] = codeInputData[key]
    }
    temp[data.name] = data.value;
    setCodeInputData(temp);
  }

  // 코드그룹 생성
  const handleClickCreateGroupCode = (item) => {
    requestPostWithJsonAPI('setGroupCodeCreate', {
      group_code: item.code,
      group_name: item.name,
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          initGroupOptionData({});
          showAlert('groupcodecreate', `'${item.name}' 코드그룹이 생성되었습니다.`, 'success', 3000);
        } else {
          if (response.data.status.message === 'duplicate') {
            showAlert('groupcodeduplicate', `코드값이 중복되었습니다.`, 'error', 5000);
          } else {
            showAlert('groupcodecreaterror', `시스템 오류가 발생하였습니다.`, 'error', 5000);
          }
        }
      }
    });
  }

  // 코드그룹 이름 변경
  const handleClickEditGroupCode = (item) => {
    requestPostWithJsonAPI('setGroupCodeEdit', {
      group_code: item.code,
      group_name: item.name,
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          initGroupOptionData({});
          showAlert('groupcodeedit', `'${item.name}'로 이름이 변경되었습니다.`, 'success', 3000);
        } else {
          showAlert('groupcodeediterror', `이름변경이 실패 하였습니다.`, 'error', 5000);
        }
      }
    });
  }

  // 코드그룹 삭제
  const handleClickDeleteGroupCode = (item) => {
    requestPostWithJsonAPI('setGroupCodeDelete', {
      group_code: item.code,
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        if (response.data && response.data.status && response.data.status.result === 'success') {
          handleClickSearch(1);
          initGroupOptionData({});
          showAlert('groupcodedelete', `'${item.name}' 코드그룹이 삭제되었습니다.`, 'success', 3000);
        } else {
          showAlert('groupcodedeleteerror', `코드그룹 삭제 작업이 실패 하였습니다.`, 'error', 5000);
        }
      }
    });
  }

  return (
    <div className={classes.root}>
      <MenuHeader />

      <CodeToolbar onChangeParam={handleChangeToolbarOption}
        onClickButton={handleClickToolbarButton}
        groupOptionData={groupOptionData} />

      <CodeList
        tableData={tableData}
        totalCnt={totalCnt}
        curPage={curPage}
        rowCount={rowCount}
        onChangePage={handleClickSearch}
        onClickListRow={handleClickListRow}
        selectedRow={codeDetailData} />

      <CodeDetail codeData={codeDetailData}
        onClickModifyCode={handleClickModifyCode}
        onClickDeleteCode={handleClickDeleteCode} />

      <AntDialog open={codeRegistOpen}
        title='코드정보 등록'
        comment='신규 코드를 등록하기 위한 정보 입력및 저장'
        onClose={handleRegistDialogClose}
        draggable={true}
        actions={[
          { key: 1, label: '취소', callback: handleRegistDialogClose },
          { key: 2, label: '저장', callback: handleClickCreateCode }
        ]}
        content={<CodeInputForm onFormChange={handleChangeInput} groupOptionData={groupOptionData} />}
      />

      <AntDialog open={codeModifyOpen}
        title='코드정보 수정'
        comment='코드정보를 수정하기위한 정보 입력및 저장'
        onClose={handleModifyDialogClose}
        draggable={true}
        actions={[
          { key: 1, label: '취소', callback: handleModifyDialogClose },
          { key: 2, label: '저장', callback: handleClickEditCode }
        ]}
        content={<CodeInputForm onFormChange={handleChangeInput} groupOptionData={groupOptionData} codeData={codeInputData} />}
      />

      <AntDialog open={codeGroupMngOpen} fullWidth={false}
        title='코드그룹 정보 수정'
        comment='코드그룹 등록, 수정, 삭제 작업을 합니다.'
        onClose={handleCodeGroupMngClose}
        draggable={true}
        actions={[
          { key: 1, label: '닫기', callback: handleCodeGroupMngClose },
        ]}
        content={<CodeGroupMngForm onFormChange={handleChangeInput}
          groupOptionData={groupOptionData}
          onClickDeleteGroupCode={handleClickDeleteGroupCode}
          onClickEditGroupCode={handleClickEditGroupCode}
          onClickCreateGroupCode={handleClickCreateGroupCode}
        />}
      />
    </div>
  );
};

export default CodeMain;
