import React from 'react';

import { Grid, TextField } from '@material-ui/core';

const AgencyInputForm = (props) => {
  const { onFormChange, inputData } = props;
  return (
    <div style={{ width: '100%', paddingTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 30, }}>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <TextField label="이름" fullWidth
            value={(inputData) ? inputData.agencyNm : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({...inputData, 'agencyNm': e.target.value });
            }}
          />
        </Grid>
        {(inputData && inputData.agencyCd !== '' && inputData.formType === 'edit') &&
          <Grid item xs={6}>
            <TextField label="번호" fullWidth
              disabled={(inputData && inputData.agencyCd !== '' && inputData.formType === 'edit') ? true : false}
              value={(inputData) ? inputData.agencyCd : undefined}
              InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
              onChange={(e) => {
                onFormChange({...inputData, 'agencyCd': e.target.value });
              }}
            />
          </Grid>
        }
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <TextField label="주소" fullWidth
            value={(inputData) ? inputData.agencyAddr : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({...inputData, 'agencyAddr': e.target.value });
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default AgencyInputForm;
