import React from 'react';

import useInform from '../../common/hooks/useInform';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { AntDialogTitle } from 'components/dialog';

import Button from '@material-ui/core/Button';

const makeContent = (content) => {
  if(Array.isArray(content)) {
    const children = content.map((val) => (
      <div key={val}>{val}</div>
    ));
    return <DialogContent>{children}</DialogContent>;
  } else {
    return <DialogContent>{content}</DialogContent>;
  }
}

const InformNotification = props => {
  const { inform, removeInform } = useInform();

  const handleConfirmClose = (result) => {
    removeInform();
    if (inform.cbFunc && typeof (inform.cbFunc) === 'function') {
      inform.cbFunc(result);
    }
  }

  return (
    <Dialog
      open={!!inform}
      fullWidth={true} maxWidth={'xs'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <AntDialogTitle onClose={handleConfirmClose}>{(inform && inform.title) ? inform.title : ''}</AntDialogTitle>
      {(inform && inform.content) ? makeContent(inform.content) : ''}
      {inform && inform.type === 'CONFIRM' &&
        <DialogActions>
          <Button onClick={() => { handleConfirmClose('Y'); }} variant="contained">
            예
          </Button>
          <Button onClick={() => { handleConfirmClose('N'); }} variant="contained">
            아니요
          </Button>
        </DialogActions>
      }
    </Dialog >
  )
};

export default InformNotification;