import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { Grid } from '@material-ui/core';
import ContentPaneTitleBar from 'components/container/ContentPaneTitleBar';
import { LabelCard } from 'components/card';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    minWidth: 780,
  },
  main: {
    boxShadow: 'none',
    border: '1px solid #d0d0d0',
    borderRadius: 0,
  },
  content: {
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    borderRadius: 0,
    padding: 10,
  },
}));

const CodeDetail = (props) => {
  const { className, codeData, onClickModifyCode, onClickDeleteCode, ...rest } = props;
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {(codeData !== undefined) &&
        <div className={classes.main}>
          <ContentPaneTitleBar title='코드 상세정보'
            buttons={[
              {
                name: '수정', action: 'edit',
                func: onClickModifyCode,
              },
              {
                name: '삭제', action: 'delete',
                confirm: { title: '코드 삭제', content: '정말 삭제하시겠습니까?', func: onClickDeleteCode },
              },
            ]}
          />
          <div className={classes.content} >
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="그룹아이디" labelWidth={120} value={codeData.grpCd} />
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="그룹이름" labelWidth={120} value={codeData.grpNm} />
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="코드아이디" labelWidth={120} value={codeData.dtlCd} />
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="코드이름" labelWidth={120} value={codeData.dtlNm} />
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="정렬순서" labelWidth={120} value={codeData.cdOrd} />
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="레벨" labelWidth={120} value={codeData.cdDepth} />
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                <LabelCard label="상위코드" labelWidth={120} value={codeData.parentDtlCd} />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </div>
        </div>
      }
    </div>
  );
};

export default CodeDetail;
