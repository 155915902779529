export default {
  contained: {
    backgroundColor: '#a6a6a7',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 32,
    padding: '1px 3px',
    marginLeft: '5px',
    boxShadow: '0 3px 5px 2px rgba(172, 172, 172, .62)',
    '&:hover': {
      backgroundColor: '#666667',
      boxShadow: '0 3px 5px 2px rgba(172, 172, 172, .62)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: '0 3px 5px 2px rgba(172, 172, 172, .62)',
        backgroundColor: '#666667',
      },
      '&$disabled': {
        backgroundColor: '#d7d8da',
      },
    },
    '&:active': {
      boxShadow: '0 3px 5px 2px rgba(172, 172, 172, .62)',
    },
    '&$disabled': {
      color: '#ffffff',
      boxShadow: '0 3px 5px 2px rgba(172, 172, 172, .62)',
      backgroundColor: '#d7d8da'
    },
  }
};


