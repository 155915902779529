import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { Grid, List, ListItem, ListItemSecondaryAction, ListItemText, IconButton } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import useInform from 'common/hooks/useInform';
import AntRectButton from 'components/button/AntRectButton';
import { LabelInput } from 'components/input';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 280,
    maxWidth: 280,
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    borderRadius: 0,
    padding: 0,
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflowY: 'scroll',
    height: 300,
    minHeight: 300,
    padding: 0,
    borderTop: '1px solid #b7b371',
    borderBottom: '1px solid #b7b371',
  },
  actions: {
    fontWeight: 'bold',
    height: 20,
    fontSize: 12,
  },
  subActions: {
    fontWeight: 'bold',
    height: 20,
    fontSize: 12,
  },
}));

const CodeGroupMngForm = (props) => {
  const { onFormChange, onClickDeleteGroupCode, onClickCreateGroupCode, onClickEditGroupCode, groupOptionData } = props;
  const classes = useStyles();

  const { showInform } = useInform();

  const [displayEditInput, setDisplayEditInput] = useState(false);
  const [displayCreateInput, setDisplayCreateInput] = useState(false);

  const [selectedItem, setSelectedItem] = useState({ code: '', name: '' });

  const handleClickCreateNewData = (param) => {
    setDisplayEditInput(false);
    setDisplayCreateInput(true);
    onFormChange({ name: 'grpCd', value: '' });
    onFormChange({ name: 'grpNm', value: '' });
    setSelectedItem({ code: '', name: '' });
  }

  const handleClickSaveNewData = () => {
    onClickCreateGroupCode(selectedItem);
  }

  const handleClickSaveEditData = () => {
    onClickEditGroupCode(selectedItem);
  }

  const handleClickEdit = (item) => {
    setDisplayEditInput(true);
    setDisplayCreateInput(false);
    onFormChange({ 'name': 'grpCd', 'value': item.code });
    onFormChange({ 'name': 'grpNm', 'value': item.name });
    setSelectedItem({ code: item.code, name: item.name });
  }

  const handleClickCancelEdit = () => {
    setDisplayEditInput(false);
    setDisplayCreateInput(false);
  }

  const handleClickDelete = (item) => {
    showInform('코드그룹 삭제', `'${item.name}' 그룹을 삭제하시겠습니까?`, 'CONFIRM', result => {
      if (result === 'Y') {
        onClickDeleteGroupCode(item);
      } else {
        return;
      }
    });
  }

  return (
    <div className={classes.root} >
      <Grid container >
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <List className={classes.list}>
            {groupOptionData.slice(1).map((item) => {
              return (
                <ListItem key={item.code} dense style={{ marginBottom: 3, border: '1px solid #d2d2d2' }}>
                  <ListItemText id={item.code} primary={`[${item.code}] ${item.name}`} />
                  <ListItemSecondaryAction>
                    <IconButton style={{ padding: 4, marginRight: 4 }} onClick={() => handleClickEdit(item)}>
                      <EditIcon style={{ width: 16 }} />
                    </IconButton>
                    <IconButton style={{ padding: 4, marginRight: -6 }} onClick={() => handleClickDelete(item)}>
                      <DeleteForeverIcon style={{ width: 16 }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20, height: 96, border: '1px solid #d2d2d2' }} >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{
              display: (!displayCreateInput && !displayEditInput) ? 'block' : 'none', width: '100%',
              textAlign: 'center',
              marginTop: 30,
            }}>
              <AntRectButton onClick={handleClickCreateNewData} disableElevation className={classes.actions}>
                <AddCircleOutlineIcon style={{ width: 16, marginRight: 3 }} />
                신규등록
              </AntRectButton>
            </div>

            <div style={{ display: (displayCreateInput || displayEditInput) ? 'block' : 'none', width: '100%'}}>
              <LabelInput
                label="코드"
                labelStyle={{ width: 70 }}
                value={selectedItem.code}
                disabled={(displayEditInput) ? true : false}
                onChange={(e) => {
                  setSelectedItem({code: e.target.value, name: selectedItem.name});
                  onFormChange({ 'name': 'grpCd', 'value': e.target.value });
                }}
              />
              <LabelInput
                label={`이름`}
                labelStyle={{ width: 70 }}
                value={selectedItem.name}
                onChange={(e) => {
                  setSelectedItem({name: e.target.value, code: selectedItem.code});
                  onFormChange({ 'name': 'grpNm', 'value': e.target.value });
                }}
              />
              <div style={{ width: '100%', textAlign: 'center' }}>
                <AntRectButton onClick={(displayCreateInput) ? handleClickSaveNewData : handleClickSaveEditData} disableElevation className={classes.subActions} style={{ marginRight: 4 }}>
                  <SaveAltIcon style={{ width: 16, marginRight: 3 }} />
                  저장
                </AntRectButton>
                <AntRectButton onClick={handleClickCancelEdit} disableElevation className={classes.subActions} style={{ marginLeft: 4 }}>
                  <CancelOutlinedIcon style={{ width: 16, marginRight: 3 }} />
                  취소
                </AntRectButton>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CodeGroupMngForm;
