import React from 'react';

import { Grid, TextField } from '@material-ui/core';

import { GroupLabel } from 'components/card';

const PayrollInputForm = (props) => {
  const { onFormChange, empPayrollData } = props;

  // console.log('empPayrollData ::::: ', empPayrollData);
  return (
    <div style={{ width: '100%', paddingTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 30, }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <TextField label="직원 번호" fullWidth
            disabled={(empPayrollData && empPayrollData.empNo !== '') ? true : false}
            value={(empPayrollData) ? empPayrollData.empNo : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => {
              onFormChange({ 'name': 'empNo', 'value': e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField label="직원 이름" fullWidth
            value={(empPayrollData) ? empPayrollData.empNm : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 0, marginTop: 10 }}><GroupLabel label="기 본 급 여 및 제 수 당" labelWidth={140} /></Grid>
        <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
          <TextField label="기본급" fullWidth value={(empPayrollData) ? Number(empPayrollData.base).toLocaleString() : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => { onFormChange({ 'name': 'base', 'type': 'number', 'value': e.target.value }); }} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
          <TextField label="상여" fullWidth value={(empPayrollData) ? Number(empPayrollData.bonus).toLocaleString() : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => { onFormChange({ 'name': 'bonus', 'type': 'number', 'value': e.target.value }); }} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
          <TextField label="식대보조금" fullWidth value={(empPayrollData) ? Number(empPayrollData.mealSubs).toLocaleString() : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => { onFormChange({ 'name': 'mealSubs', 'type': 'number', 'value': e.target.value }); }} />
        </Grid >
        <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
          <TextField label="자가운전보조금" fullWidth value={(empPayrollData) ? Number(empPayrollData.driveSubs).toLocaleString() : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => { onFormChange({ 'name': 'driveSubs', 'type': 'number', 'value': e.target.value }); }} />
        </Grid >
        <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
          <TextField label="연장근로수당" fullWidth value={(empPayrollData) ? Number(empPayrollData.overworkBnf).toLocaleString() : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => { onFormChange({ 'name': 'overworkBnf', 'type': 'number', 'value': e.target.value }); }} />
        </Grid >
        <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
          <TextField label="야간근로수당" fullWidth value={(empPayrollData) ? Number(empPayrollData.nightBnf).toLocaleString() : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => { onFormChange({ 'name': 'nightBnf', 'type': 'number', 'value': e.target.value }); }} />
        </Grid >
        <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
          <TextField label="직책수당" fullWidth value={(empPayrollData) ? Number(empPayrollData.gradeBnf).toLocaleString() : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => { onFormChange({ 'name': 'gradeBnf', 'type': 'number', 'value': e.target.value }); }} />
        </Grid >
        <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
          <TextField label="파견수당" fullWidth value={(empPayrollData) ? Number(empPayrollData.dispatchBnf).toLocaleString() : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => { onFormChange({ 'name': 'dispatchBnf', 'type': 'number', 'value': e.target.value }); }} />
        </Grid >
        <Grid item xs={12} style={{ paddingBottom: 0, marginTop: 20 }}><GroupLabel label="공 제 및 차 인 지 급 액" labelWidth={140} /></Grid>
        <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
          <TextField label="국민연금" fullWidth value={(empPayrollData) ? Number(empPayrollData.nationPention).toLocaleString() : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => { onFormChange({ 'name': 'nationPention', 'type': 'number', 'value': e.target.value }); }} />
        </Grid >
        <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
          <TextField label="건강보험" fullWidth value={(empPayrollData) ? Number(empPayrollData.healthInsr).toLocaleString() : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => { onFormChange({ 'name': 'healthInsr', 'type': 'number', 'value': e.target.value }); }} />
        </Grid >
        <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
          <TextField label="산재보험" fullWidth value={(empPayrollData) ? Number(empPayrollData.accidInsr).toLocaleString() : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => { onFormChange({ 'name': 'accidInsr', 'type': 'number', 'value': e.target.value }); }} />
        </Grid >
        <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
          <TextField label="고용보험" fullWidth value={(empPayrollData) ? Number(empPayrollData.empInsr).toLocaleString() : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => { onFormChange({ 'name': 'empInsr', 'type': 'number', 'value': e.target.value }); }} />
        </Grid >
        <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
          <TextField label="장기요양" fullWidth value={(empPayrollData) ? Number(empPayrollData.longRecu).toLocaleString() : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => { onFormChange({ 'name': 'longRecu', 'type': 'number', 'value': e.target.value }); }} />
        </Grid >
        <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
          <TextField label="소득세" fullWidth value={(empPayrollData) ? Number(empPayrollData.incomeTax).toLocaleString() : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => { onFormChange({ 'name': 'incomeTax', 'type': 'number', 'value': e.target.value }); }} />
        </Grid >
        <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
          <TextField label="주민세" fullWidth value={(empPayrollData) ? Number(empPayrollData.residentTax).toLocaleString() : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => { onFormChange({ 'name': 'residentTax', 'type': 'number', 'value': e.target.value }); }} />
        </Grid >
        <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
          <TextField label="건강보험 연말정산" fullWidth value={(empPayrollData) ? Number(empPayrollData.yecHealthInsr).toLocaleString() : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => { onFormChange({ 'name': 'yecHealthInsr', 'type': 'number', 'value': e.target.value }); }} />
        </Grid >
        <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
          <TextField label="장기요양보험 연말정산" fullWidth value={(empPayrollData) ? Number(empPayrollData.yecLongRecu).toLocaleString() : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => { onFormChange({ 'name': 'yecLongRecu', 'type': 'number', 'value': e.target.value }); }} />
        </Grid >
        <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
          <TextField label="고용보험 연말정산" fullWidth value={(empPayrollData) ? Number(empPayrollData.yecEmpInsr).toLocaleString() : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => { onFormChange({ 'name': 'yecEmpInsr', 'type': 'number', 'value': e.target.value }); }} />
        </Grid >
        <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
          <TextField label="환급이자" fullWidth value={(empPayrollData) ? Number(empPayrollData.refundIntr).toLocaleString() : undefined}
            InputLabelProps={{ style: { color: '#3c63a8', fontWeight: 500 }, }}
            onChange={(e) => { onFormChange({ 'name': 'refundIntr', 'type': 'number', 'value': e.target.value }); }} />
        </Grid >
      </Grid >
    </div >
  );
};

export default PayrollInputForm;
